import { gql, useMutation } from '@apollo/client';
import type {
  InsertOrganisationImageMutationMutation,
  InsertTeamImageMutationMutation,
  InsertUserImageMutationMutation,
} from '../../__generated__/graphql';

export const GET_USER_IMAGES = gql`
  query GetUserImages($user_id: uuid!) {
    user_images(where: { user_id: { _eq: $user_id } }) {
      id
      order_nr
      is_primary
      image {
        id
        url
      }
    }
  }
`;

export const GET_TEAM_IMAGES = gql`
  query GetTeamImages($team_id: uuid!) {
    team_images(
      where: { team_id: { _eq: $team_id } }
      order_by: { order_nr: asc }
    ) {
      id
      order_nr
      image {
        id
        url
      }
    }
  }
`;

export const GET_ORGANISATION_IMAGES = gql`
  query GetOrganisationImages($organisation_id: uuid!) {
    organisation_images(where: { organisation_id: { _eq: $organisation_id } }) {
      id
      order_nr
      image {
        id
        url
      }
    }
  }
`;

export const INSERT_IMAGE_MUTATIONS = {
  user: {
    mutation: gql`
      mutation insertUserImageMutation($object: user_images_insert_input!) {
        insert_user_images_one(object: $object) {
          id
          image {
            id
            url
          }
        }
      }
    `,
    useMutation: useMutation<InsertUserImageMutationMutation>,
  },
  team: {
    mutation: gql`
      mutation insertTeamImageMutation($object: team_images_insert_input!) {
        insert_team_images_one(object: $object) {
          id
          image {
            id
            url
          }
        }
      }
    `,
    useMutation: useMutation<InsertTeamImageMutationMutation>,
  },
  organisation: {
    mutation: gql`
      mutation insertOrganisationImageMutation(
        $object: organisation_images_insert_input!
      ) {
        insert_organisation_images_one(object: $object) {
          id
          image {
            id
            url
          }
        }
      }
    `,
    useMutation: useMutation<InsertOrganisationImageMutationMutation>,
  },
};
