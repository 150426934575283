// This component is used to display a loading checkbox
// It accepts a promise as a prop and displays a loading spinner until the promise resolves

import { Box, Checkbox, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

interface LoadingCheckBoxProps {
  checked: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent) => Promise<boolean>;
  loading?: boolean;
}

export const LoadingCheckBox = (props: LoadingCheckBoxProps) => {
  const [loading, setLoading] = useState<boolean>(props.loading ?? false);
  const [checked, setChecked] = useState<boolean>(props.checked ?? false);

  useEffect(() => {
    setChecked(props.checked ?? false);
  }, [props.checked]);

  const handleClick = async (event: React.MouseEvent) => {
    setLoading(true);
    const result = await props.onClick(event);
    setChecked(result);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <CircularProgress size={24} sx={{ m: '9px' }} disableShrink />
      ) : (
        <Checkbox
          checked={checked}
          onClick={handleClick}
          disabled={props.disabled}
        />
      )}
    </Box>
  );
};
