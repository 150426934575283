import * as React from 'react';

type CMATemplateViewerDialog = {
  setTemplateOpen: (value: boolean | ((value: boolean) => boolean)) => void;
  templateOpen: boolean;
};

export const CMATemplateViewerContext =
  React.createContext<CMATemplateViewerDialog>({
    templateOpen: false,
    setTemplateOpen: () => {},
  });

export const CMATemplateViewerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [templateOpen, setTemplateOpen] = React.useState<boolean>(false);
  const value = React.useMemo(
    () => ({
      templateOpen,
      setTemplateOpen,
    }),
    [templateOpen],
  );

  return (
    <CMATemplateViewerContext.Provider value={value}>
      {children}
    </CMATemplateViewerContext.Provider>
  );
};

export const useCMATemplateViewerDialog = (): CMATemplateViewerDialog =>
  React.useContext(CMATemplateViewerContext);
