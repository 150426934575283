import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, styled, Typography } from '@mui/material';
import { Carousel } from '@realadvisor/carousel';
import { useConstant } from '@realadvisor/hooks';
import { memo, useCallback, useLayoutEffect, useState } from 'react';

type CarouselImage = {
  id: string;
  url: string;
  title?: string;
};

const CarouselPositionTrack = memo<{
  index: number;
  onChange: (index: number) => void;
}>(({ index, onChange }) => {
  useLayoutEffect(() => {
    onChange(index);
  }, [index, onChange]);

  return null;
});

const WrappedCarousel = ({
  images,
  viewIndex,
  onViewIndexChange,
}: {
  images: CarouselImage[];
  viewIndex: number;
  onViewIndexChange: (viewIndex: number) => void;
}) => {
  const initialViewIndex = useConstant(() => viewIndex);
  const CarouselPosition = useCallback(
    (props: { value: number }) => {
      return (
        <CarouselPositionTrack
          index={props.value - 1}
          onChange={onViewIndexChange}
        />
      );
    },
    [onViewIndexChange],
  );

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        backgroundColor: '#000',
      }}
    >
      <Carousel
        media={{
          gap: 0,
          itemsOnScreen: 1,
          aspectRatio: window.innerHeight / window.innerWidth,
        }}
        autoFocus={true}
        initialOffset={initialViewIndex}
        Position={CarouselPosition}
      >
        {images.map(item => {
          return (
            <img
              key={item.id}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              src={item.url}
              alt={item.title ?? ''}
            />
          );
        })}
      </Carousel>
    </Box>
  );
};

interface FullScreenCarouselProps {
  viewIndex: number;
  onCloseFullScreen: () => void;
  open?: boolean;
  images: CarouselImage[];
}

const StyledBox = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  padding: '12px 16px',
  position: 'absolute',
  zIndex: 2,
});

export const FullScreenCarousel: React.FC<FullScreenCarouselProps> = ({
  images,
  open = false,
  onCloseFullScreen,
  viewIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(viewIndex);

  return (
    <Dialog fullScreen={true} open={open} onClose={onCloseFullScreen}>
      <IconButton
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 2,
          color: 'white.main',
        }}
        onClick={onCloseFullScreen}
      >
        <CloseIcon />
      </IconButton>

      <WrappedCarousel
        images={images}
        viewIndex={viewIndex}
        onViewIndexChange={setCurrentIndex}
      />

      {images[currentIndex].title != null &&
        images[currentIndex].title !== '' && (
          <StyledBox
            sx={{
              bottom: 0,
              left: 0,
            }}
          >
            <Typography variant="subtitle2" sx={{ color: 'white.main' }}>
              {images[currentIndex].title}
            </Typography>
          </StyledBox>
        )}

      <StyledBox
        sx={{
          bottom: 0,
          right: 0,
        }}
      >
        <Typography variant="subtitle2" sx={{ color: 'white.main' }}>
          {currentIndex + 1}/{images.length}
        </Typography>
      </StyledBox>
    </Dialog>
  );
};
