import { gql } from '@apollo/client';
import { HomeWorkOutlined } from '@mui/icons-material';
import { Image } from '@realadvisor/image';
import { useTheme } from '../../../src/hooks/theme';
import { formatAddress } from '../../utils/formatting';
import type { ListingCell_ListingFragment } from '../../__generated__/graphql';

export const LISTING_CELL_LISTING_FRAGMENT = gql`
  fragment ListingCell_listing on lots {
    id
    title
    property {
      route
      street_number
      postcode
      locality
      country_code
      single_property_images: property_images(
        order_by: { order_nr: asc }
        limit: 1
      ) {
        order_nr
        image {
          id
          url
        }
      }
    }
  }
`;

interface ListingCellProps {
  listing?: ListingCell_ListingFragment;
}

export const ListingCell = ({ listing }: ListingCellProps) => {
  const { text, colors } = useTheme();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            height: 50,
            width: 50,
            backgroundColor: colors.grey200,
            overflow: 'hidden',
          }}
        >
          {listing?.property?.single_property_images &&
          listing?.property?.single_property_images.length > 0 ? (
            <Image
              src={
                listing?.property?.single_property_images[0]?.image.url ?? ''
              }
              options={{ w: 256, f: 'jpg' }}
            />
          ) : (
            <HomeWorkOutlined
              style={{ fontSize: 32, color: colors.grey400, margin: 8 }}
            />
          )}
        </div>
      </div>
      <div style={{ minWidth: 0 }}>
        <div>{listing?.title}</div>
        {listing != null &&
          formatAddress(listing?.property, null).map((line, index) => (
            <div
              key={index}
              style={{
                ...text.caption,
                ...text.truncate,
                color: colors.mediumText,
              }}
            >
              {line}
            </div>
          ))}
      </div>
    </div>
  );
};
