import { useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  type GridColDef,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocale } from '../../../src/hooks/locale';
import { customPalette } from '../../../src/styles';
import { gql } from '../../__generated__';
import type { GetUserReviewsQuery } from '../../__generated__/graphql';
import { RaForm } from '../../components/form/RaForm';
import { TimeAgo } from '../../components/TimeAgo';
import { useAppData } from '../../providers/AppDataProvider';
import { roundRating, toHumanReadableDate } from '../../utils/formatting';
import RatingDisplay from '../../components/RatingDisplay';

type Review = NonNullable<
  GetUserReviewsQuery['users_by_pk']
>['reviews'][number];

const USER_REVIEWS = gql(`
  query GetUserReviews($userId: uuid!) {
    users_by_pk(id: $userId) {
      id
      reviews(
        where: { completed: { _eq: true } }
        order_by: { completed_at: desc }
      ) {
        id
        rating
        completed_at
        is_seller
        is_buyer
        review_text
        first_name
        last_name
        transaction_date
        formatted_address
      }
      reviews_aggregate(where: { completed: { _eq: true } }) {
        aggregate {
          avg {
            rating
          }
          count
        }
      }
    }
  }
`);

const DELETE_REVIEW = gql(`
  mutation DeleteUserReview($id: uuid!) {
    delete_reviews_by_pk(id: $id) {
      id
    }
  }
`);

const UPDATE_REVIEW = gql(`
  mutation UpdateUserReview($id: uuid!, $review_text: String!) {
    update_reviews_by_pk(
      pk_columns: { id: $id }
      _set: { review_text: $review_text }
    ) {
      id
      review_text
    }
  }
`);

export const UserReviews = () => {
  const { t, locale } = useLocale();
  const { me } = useAppData();
  const { userId } = useParams();
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const { data, error, loading } = useQuery(USER_REVIEWS, {
    variables: { userId: userId ?? '' },
    skip: userId == null,
  });

  const reviews = data?.users_by_pk?.reviews ?? [];

  const [deleteReview] = useMutation(DELETE_REVIEW);
  const [updateReview] = useMutation(UPDATE_REVIEW);

  if (error) {
    return <div>Error loading reviews: {error.message}</div>;
  }

  const columns: GridColDef<Review>[] = [
    {
      field: 'completed_at',
      headerName: t('Date'),
      width: 100,
      renderCell: params => <TimeAgo dateString={params.row.completed_at} />,
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      field: 'name',
      headerName: t('Reviewer'),
      width: 150,
      sortable: false,
      renderCell: ({ row: { first_name, last_name } }) => (
        <Box display="flex" alignItems="center" alignContent="center" gap={1}>
          <Avatar>{first_name?.charAt(0)?.toUpperCase()}</Avatar>
          <div>
            <span css={{ textTransform: 'capitalize' }}>{first_name}</span>{' '}
            <span css={{ textTransform: 'capitalize' }}>{last_name}</span>
          </div>
        </Box>
      ),
    },
    {
      field: 'formatted_address',
      headerName: t('Address'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'is_seller',
      headerName: t('relationship'),
      width: 120,
      renderCell: ({ row }) =>
        row.is_seller === true
          ? t('seller')
          : row.is_buyer === true
          ? t('buyer')
          : t('other'),
    },
    {
      field: 'transaction_date',
      headerName: t('Transaction date'),
      width: 150,
      sortable: false,
      renderCell: ({ row }) =>
        toHumanReadableDate(locale, row.transaction_date, {
          day: undefined,
        }),
    },
    {
      field: 'rating',
      headerName: t('Rating'),
      width: 150,
      renderCell: params => (
        <Box sx={{ gap: 1, alignItems: 'center', display: 'flex' }}>
          {params.row.rating?.toFixed(1)}
          <Rating value={params.row.rating} readOnly size="small" />
        </Box>
      ),
    },
    {
      field: 'review_text',
      headerName: t('Reviews'),
      flex: 2,
      minWidth: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row.review_text} placement="bottom" arrow>
          <Box
            sx={theme => ({
              cursor: 'pointer',
              ...theme.mixins.truncate(),
            })}
          >
            {row.review_text}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          disabled={me?.is_admin !== true}
          key={`delete-review-${params.row.id}`}
          onClick={() => {
            if (
              window.confirm(
                t('Are you sure you want to delete this review?'),
              ) === false
            ) {
              return;
            }
            deleteReview({
              variables: {
                id: params.row.id,
              },
              refetchQueries: [USER_REVIEWS],
            });
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        padding={2}
        zIndex={2}
        alignContent="center"
        alignItems="center"
        alignSelf="center"
      >
        <Grid item>
          <Typography variant="h5">
            {t('Average Rating')}:&nbsp;
            {roundRating(
              data?.users_by_pk?.reviews_aggregate?.aggregate?.avg?.rating,
            ).toFixed(1)}
          </Typography>
        </Grid>
        <Grid item sx={{ lineHeight: 0 }}>
          <RatingDisplay
            showAverage={false}
            rating={
              data?.users_by_pk?.reviews_aggregate?.aggregate?.avg?.rating
            }
            precision={0.1}
            readOnly
            size="large"
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            &nbsp;(
            {data?.users_by_pk?.reviews_aggregate?.aggregate?.count}
            &nbsp;{t('reviews')})
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DataGridPro
            autoHeight
            rows={reviews}
            columns={columns}
            loading={loading}
            pagination={true}
            onCellClick={params => {
              if (params.field === 'review_text' && me?.is_admin === true) {
                setSelectedReview(params.row);
              }
            }}
            paginationMode="client"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 4,
              '& .MuiDataGrid-main': {
                borderRadius: 4,
              },
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        open={selectedReview != null}
        onClose={() => setSelectedReview(null)}
        sx={theme => ({
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
          '& .MuiDialog-paper': {
            backgroundColor: customPalette.superLightBlue,
            [theme.breakpoints.down('sm')]: {
              margin: 0,
              width: '100%',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          '& .MuiDialog-container': {
            [theme.breakpoints.down('sm')]: {
              alignItems: 'end',
            },
          },
        })}
      >
        <Stack
          direction="row"
          component={Paper}
          elevation={3}
          borderRadius={0}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'sticky',
            top: 0,
            paddingX: 2,
            paddingY: 1,
            zIndex: 2,
          }}
        >
          <DialogTitle sx={{ p: 0 }}>{t('Edit review')}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setSelectedReview(null)}
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <RaForm
          formDefinition={({ t }) => [
            {
              type: 'text',
              name: 'review_text',
              label: t('Review'),
              rows: 10,
              multiline: true,
              required: true,
              gridProps: { md: 12 },
            },
          ]}
          defaultValues={selectedReview ?? undefined}
          onCancel={isFormDirty =>
            isFormDirty === false && setSelectedReview(null)
          }
          onSubmit={data => {
            if (data.review_text == null || selectedReview == null) {
              return Promise.resolve();
            }

            return updateReview({
              variables: {
                review_text: data.review_text,
                id: selectedReview.id,
              },
              onCompleted: () => setSelectedReview(null),
            });
          }}
        />
      </Dialog>
    </>
  );
};
