import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useSchema } from '../useSchema';
import { addWhereClause, deleteWhereClause } from '../Filters';

interface EnumProps {
  name: string;
  node: any;
  path: string[];
  where: any;
  setWhere: (where: any) => void;
}

export const Enum = ({ name, node, path, where, setWhere }: EnumProps) => {
  const { getTypeFromPath, getTypeByName } = useSchema();
  const type = getTypeFromPath(path);

  if (!type) {
    throw new Error('No type found for ' + path.join('.'));
  }

  const fullType = getTypeByName(type.name);

  if (fullType?.kind !== 'ENUM') {
    throw new Error('Type is not an enum');
  }

  const enumValues = fullType.enumValues.map(ev => ev.name);

  return (
    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
      {name}
      <Autocomplete
        value={node}
        multiple={Array.isArray(node)}
        options={enumValues ?? []}
        disableCloseOnSelect
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={
              node?.length === 0 ? `+ Add filter (${name})` : undefined
            }
            sx={{
              background: 'white',
              minWidth: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
                paddingRight: '40px !important',
              },
            }}
          />
        )}
        size="small"
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
          }
          setWhere(newWhere);
        }}
      />
      <IconButton
        sx={{
          padding: '3px',
        }}
        size="small"
        color="error"
        onClick={() => {
          const newWhere = deleteWhereClause(where, path);
          setWhere(newWhere);
        }}
      >
        <Clear sx={{ fontSize: 20 }} />
      </IconButton>
    </div>
  );
};
