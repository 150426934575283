import type { PropsWithChildren } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

export const ConditionalLinkWrapper: React.FC<
  {
    link?: LinkProps;
  } & PropsWithChildren
> = ({ link, children }) => {
  if (!children) {
    return null;
  }

  return link ? (
    <Link {...link} css={{ display: 'block' }}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
