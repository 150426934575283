import { DateField, DateTimeField } from '@mui/x-date-pickers-pro';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useSchema } from '../useSchema';
import { addWhereClause, deleteWhereClause } from '../Filters';

interface ScalarProps {
  name: string;
  node: any;
  path: string[];
  where: any;
  setWhere: (where: any) => void;
}

export const Scalar = ({ name, node, path, where, setWhere }: ScalarProps) => {
  const { getTypeFromPath } = useSchema();
  const type = getTypeFromPath(path);

  if (!type) {
    console.info('No type found for', path);
    return <div>No type found for {path.join('.')}</div>;
  }

  let inputType;
  let defaultValue;

  switch (type?.name) {
    case 'String':
      inputType = 'text';
      defaultValue = node.toString();
      break;
    case 'Int':
    case 'Float':
    case 'float8':
      inputType = 'number';
      defaultValue = parseFloat(node);
      break;
    case 'date':
      inputType = 'date';
      if (typeof node === 'string' && node.includes('T')) {
        defaultValue = new Date(node).toISOString().split('T')[0];
      } else {
        defaultValue = new Date().toISOString().split('T')[0];
      }
      break;
    case 'timestamptz': {
      inputType = 'datetime-local';
      try {
        // Create a new Date object from the node value
        const date = new Date(node);
        // Adjust the date to account for the local timezone offset, including DST
        const adjustedDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000,
        );
        // Convert to a string format suitable for datetime-local input
        defaultValue = adjustedDate.toISOString().slice(0, 16); // slice to remove seconds and the 'Z'
      } catch (e) {
        console.error('Error parsing date', e);
        defaultValue = new Date().toISOString().slice(0, 16);
      }
      break;
    }
    case 'jsonb':
      inputType = 'text';
      defaultValue = JSON.stringify(node);
      break;
    case 'Boolean':
      inputType = 'checkbox';
      defaultValue = node;
      break;
    default:
      defaultValue = undefined;
      inputType = 'text';
  }

  if (inputType === 'datetime-local' && Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
          setWhere(newWhere);
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`+ Add filter (${name})`}
            sx={{
              background: 'white',
              width: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'datetime-local') {
    return (
      <DateTimeField
        value={new Date(defaultValue)}
        onChange={(date, { validationError }) => {
          if (!validationError) {
            let newWhere = deleteWhereClause(where, path);
            if (date) {
              newWhere = addWhereClause(where, path, date);
              setWhere(newWhere);
            }
          }
        }}
        variant="outlined"
        size="small"
        clearable
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'date' && Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
          setWhere(newWhere);
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`+ Add filter (${name})`}
            sx={{
              background: 'white',
              width: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'date') {
    return (
      <DateField
        value={new Date(defaultValue)}
        onChange={(date, { validationError }) => {
          if (!validationError) {
            let newWhere = deleteWhereClause(where, path);
            if (date) {
              newWhere = addWhereClause(where, path, date);
              setWhere(newWhere);
            }
          }
        }}
        variant="outlined"
        size="small"
        clearable
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'text' && Array.isArray(node)) {
    return (
      <Autocomplete
        value={node}
        multiple
        freeSolo
        size="small"
        options={[]}
        onChange={(e, value) => {
          let newWhere = deleteWhereClause(where, path);
          if (value) {
            newWhere = addWhereClause(newWhere, path, value);
            setWhere(newWhere);
          }
          setWhere(newWhere);
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={node?.length === 0 ? `+ Add filter (${name})` : ''}
            sx={{
              background: 'white',
              minWidth: 350,
              '& .MuiInputBase-root': {
                padding: '0 !important',
              },
            }}
          />
        )}
      />
    );
  }

  if (inputType === 'text') {
    return (
      <TextField
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        onChange={e => {
          const newWhere = addWhereClause(where, path, e.target.value);
          setWhere(newWhere);
        }}
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'number') {
    return (
      <TextField
        type="number"
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        onChange={e => {
          const newWhere = addWhereClause(
            where,
            path,
            parseFloat(e.target.value),
          );
          setWhere(newWhere);
        }}
        sx={{
          background: 'white',
          width: 200,
          '& .MuiInputBase-input': {
            padding: '3px 10px !important',
          },
        }}
      />
    );
  }

  if (inputType === 'checkbox') {
    return (
      <input
        type="checkbox"
        defaultChecked={defaultValue}
        onChange={() => {
          const newWhere = addWhereClause(where, path, !node);
          setWhere(newWhere);
        }}
      />
    );
  }

  return (
    <div>
      <label>
        <i>{name}</i>
      </label>
      <TextField
        type={inputType}
        onChange={e => {
          const newWhere = addWhereClause(where, path, e.target.value);
          setWhere(newWhere);
        }}
        defaultValue={defaultValue}
      />
      <IconButton
        sx={{
          padding: '3px',
        }}
        size="small"
        color="error"
        onClick={() => {
          const newWhere = deleteWhereClause(where, path);
          setWhere(newWhere);
        }}
      >
        <Clear sx={{ fontSize: 20 }} />
      </IconButton>
    </div>
  );
};
