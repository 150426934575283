// @flow

import * as React from 'react';
import type { OperationType } from 'relay-runtime';
import type { GraphQLTaggedNode } from 'react-relay';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import type { LoadMoreFn, RefetchFnDynamic } from 'react-relay';
import { useRelayEnvironment, fetchQuery } from 'react-relay';
import {
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../utils/animation-timeout';

type Pagination<TQuery, TKey, TFragmentData> = {|
  data: TFragmentData,
  loadNext: LoadMoreFn<TQuery>,
  loadPrevious: LoadMoreFn<TQuery>,
  hasNext: boolean,
  hasPrevious: boolean,
  isLoadingNext: boolean,
  isLoadingPrevious: boolean,
  refetch: RefetchFnDynamic<TQuery, TKey>,
|};

type PaginationReturnType<TQuery: OperationType, TKey, TFragmentData> = {|
  data: TFragmentData,
  refetch: RefetchFnDynamic<TQuery, TKey>,
  loadUntil: (size: number) => void,
|};

// NOTE: This $Call ensures that the type of the returned data is either:
//   - nullable if the provided ref type is nullable
//   - non-nullable if the provided ref type is non-nullable
// prettier-ignore
type FragmentData<TKey> = $Call<
  & (<TFragmentData>( { +$data?: TFragmentData, ... }) =>  TFragmentData)
  & (<TFragmentData>(?{ +$data?: TFragmentData, ... }) => ?TFragmentData),
  TKey,
>;

export const useWindowPagination = <
  TQuery: OperationType,
  TKey: ?{ +$data?: mixed, ... },
>(
  pagination: Pagination<TQuery, TKey, FragmentData<TKey>>,
  getCount: (FragmentData<TKey>) => number,
  refetchableQueryNode: GraphQLTaggedNode,
): PaginationReturnType<TQuery, TKey, FragmentData<TKey>> => {
  const environment = useRelayEnvironment();
  const { data, refetch, loadNext, hasNext, isLoadingNext } = pagination;
  const animationId = React.useRef(null);
  const count = getCount(data);
  const lastLoadUntilDispose = React.useRef(null);
  const lastRefetchDispose = React.useRef(null);

  const loadUntil = (size: number, batchSize = 20, loadBeforeEndSize = 10) => {
    const pageSize = Math.max(size - (count - 1), 1);
    cancelAnimationTimeout(animationId.current);
    if (isLoadingNext) {
      animationId.current = requestAnimationTimeout(100, () => loadUntil(size));
    } else if (count < size + loadBeforeEndSize && hasNext) {
      const { dispose } = loadNext(pageSize + batchSize);
      lastLoadUntilDispose.current = dispose;
    }
  };

  const unsuspendableRefetch: RefetchFnDynamic<TQuery, TKey> = variables => {
    // dispose current requests when refetch happen
    // to keep consistent data in store
    lastLoadUntilDispose.current?.();
    lastRefetchDispose.current?.();
    const { unsubscribe } = fetchQuery<TQuery>(
      environment,
      refetchableQueryNode,
      variables,
    ).subscribe({
      complete: () => refetch(variables, { fetchPolicy: 'store-only' }),
    });
    lastRefetchDispose.current = unsubscribe;
    return { dispose: unsubscribe };
  };

  return {
    data,
    refetch: unsuspendableRefetch,
    loadUntil,
  };
};

export const useInputQuery = <TQuery: OperationType>(
  gqlQuery: GraphQLTaggedNode,
): [
  /* data */ null | TQuery['response'],
  /* refetch */ (TQuery['variables']) => void,
  /* loading */ boolean,
] => {
  const environment = useRelayEnvironment();
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [data, setData] = React.useState(null);
  // set 250 debounce
  const subscription = React.useRef(null);
  const timeoutId = React.useRef(null);
  React.useEffect(() => {
    cancelAnimationTimeout(timeoutId.current);
    if (subscription.current != null) {
      subscription.current.unsubscribe();
    }
  }, [subscription, timeoutId]);
  const refetch = variables => {
    cancelAnimationTimeout(timeoutId.current);
    timeoutId.current = requestAnimationTimeout(250, () => {
      if (subscription.current == null) {
        setLoadingCount(count => count + 1);
        subscription.current = fetchQuery<TQuery>(
          environment,
          gqlQuery,
          variables,
        ).subscribe({
          next: data => {
            setData(data);
            setLoadingCount(count => count - 1);
            subscription.current = null;
            // retain data
            const request = getRequest(gqlQuery);
            const operation = createOperationDescriptor(request, variables);
            environment.retain(operation);
          },
          error: () => {
            setData(null);
            setLoadingCount(count => count - 1);
            subscription.current = null;
          },
        });
      } else {
        refetch(variables);
      }
    });
  };
  return [data, refetch, loadingCount !== 0];
};
