import { useMutation, useQuery } from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocale } from '../../../src/hooks/locale';
import type {
  GetTeamSettingsQuery,
  GetTeamSettingsQueryVariables,
  UpdateTeamPhoneNumberMutation,
  UpdateTeamPhoneNumberMutationVariables,
  UpdateTeamSettingsMutation,
  UpdateTeamSettingsMutationVariables,
} from '../../__generated__/graphql';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import {
  GET_TEAM_SETTINGS,
  UPDATE_TEAM_PHONE_NUMBER,
  UPDATE_TEAM_SETTINGS,
} from './teamQueries';

const socialMediaFields = [
  { name: 'facebook_url', label: 'Facebook' },
  { name: 'instagram_url', label: 'Instagram' },
  { name: 'youtube_url', label: 'Youtube' },
  { name: 'linkedin_url', label: 'LinkedIn' },
  { name: 'twitter_url', label: 'Twitter' },
];

const TeamFormDefinition: FormDefinitionType = ({ t }) => [
  {
    name: 'address-title',
    label: t('Address'),
    type: 'category-title',
  },
  {
    name: 'address',
    label: t('Address'),
    type: 'address',
    gridProps: { xs: 12 },
    includeGoogleFields: true,
  },
  {
    name: 'details',
    label: 'Details',
    type: 'category-title',
  },
  {
    name: 'name',
    label: t('Name'),
    type: 'text',
    required: true,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'legal_name',
    label: t('Legal name'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'description',
    label: t('Description'),
    type: 'text',
    required: false,
    multiline: true,
    gridProps: { xs: 12 },
  },
  {
    name: 'phone_number',
    label: t('Phone number'),
    type: 'phone_number',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'website_url',
    label: t('Website URL'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'agency_slug',
    label: t('Slug (agency pages)'),
    type: 'text',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'organisation_id',
    label: t('Parent organisation'),
    type: 'organisation',
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    checkboxes: [
      {
        name: 'show_in_agency_pages',
        label: t('Show in agency pages'),
        type: 'checkbox',
      },
      {
        name: 'do_follow_link',
        label: t('Dofollow link'),
        type: 'checkbox',
      },
    ],
    required: false,
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'social_links',
    label: t('Social links'),
    type: 'category-title',
  },
  ...socialMediaFields.map(({ name, label }) => ({
    name,
    label,
    type: 'text' as const,
    gridProps: { xs: 12, md: 6 },
  })),
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    disabled: () => true,
  },
];

export const TeamSettings = () => {
  const { t } = useLocale();
  const { teamId } = useParams();

  const { data, loading, error } = useQuery<
    GetTeamSettingsQuery,
    GetTeamSettingsQueryVariables
  >(GET_TEAM_SETTINGS, {
    variables: { id: teamId ?? '' },
    skip: teamId == null,
  });

  const teamData = data?.teams_by_pk;
  const defaultValues = useMemo(() => {
    const { phone_numbers, ...team } = teamData || {};

    return {
      ...team,
      phone_number: phone_numbers?.[0]?.number,
    };
  }, [teamData]);

  const [updateTeam] = useMutation<
    UpdateTeamSettingsMutation,
    UpdateTeamSettingsMutationVariables
  >(UPDATE_TEAM_SETTINGS);

  const [updatePhoneNumber] = useMutation<
    UpdateTeamPhoneNumberMutation,
    UpdateTeamPhoneNumberMutationVariables
  >(UPDATE_TEAM_PHONE_NUMBER);

  if (loading) {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      />
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!teamData) {
    return <Alert severity="error">{t('Team not found')}</Alert>;
  }

  const onSubmit = async (formData: any) => {
    const { phone_number, ...rest } = formData;
    if (phone_number) {
      await updatePhoneNumber({
        variables: {
          id: teamData.id,
          phone_number:
            parsePhoneNumberFromString(phone_number)?.format('E.164') ?? '',
        },
      });
    }

    return updateTeam({
      variables: {
        id: teamData.id,
        team: {
          ...rest,
          id: undefined,
        },
      },
    });
  };

  return (
    <RaForm
      formDefinition={TeamFormDefinition}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    />
  );
};
