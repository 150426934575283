import {
  roundToNearestMinutes,
  isBefore,
  addMinutes,
  formatISO,
  format,
} from 'date-fns';

// TODO add to date-fns
export const ceilToNearestMinutes = (
  date: Date | number,
  minutes: number,
): Date => {
  const newMinutes = roundToNearestMinutes(date, { nearestTo: minutes });
  if (isBefore(newMinutes, date)) {
    return addMinutes(newMinutes, minutes);
  }
  return newMinutes;
};

// consider passed local date as UTC without timezone
export const toISODateString = (date: null | Date): null | string => {
  if (date == null) {
    return null;
  }

  return formatISO(date, { representation: 'date' });
};

export const getDateString = (date: null | Date | string): null | string => {
  if (date == null) {
    return null;
  }

  return format(new Date(date), 'yyyy-MM-dd');
};
