import * as React from 'react';
import {
  Box,
  Drawer,
  Chip,
  Typography,
  Stack,
  Checkbox,
  IconButton,
  InputBase,
  FormGroup,
  Button,
  Toolbar,
  Divider,
  FormControlLabel,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import {
  Pin as NumberIcon,
  Abc as StringIcon,
  CalendarMonth as DateIcon,
  Search as SearchIcon,
  AddCircleOutline as AddIcon,
  ToggleOff as BooleanIcon,
  Person,
  FormatListBulleted as ListIcon,
  ExpandLess,
  ExpandMore,
  Public as EarthIcon,
  CollectionsBookmark as DictionaryIcon,
  Group as TeamIcon,
} from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import { useTheme } from '../../src/hooks/theme';
import {
  type ConditionClause,
  type ConditionOperator,
  isConditionClause,
} from '../utils/parseWhereClause';
import { isSQLIntervalString } from '../utils/parseSQLInterval';
import { FilterEnumForm, type EnumOperator } from './FilterEnumForm';
import { FilterBooleanForm } from './FilterBooleanForm';
import { FilterStringForm, type StringOperator } from './FilterStringForm';
import { FilterNumberForm, type NumberOperator } from './FilterNumberForm';
import { FilterDateForm, type DateOperator } from './FilterDateForm';
import {
  FilterPlacesForm,
  DisplaySelectedPlaces,
  type PlaceOperator,
} from './FilterPlacesForm';
import {
  FilterUserForm,
  DisplaySelectedUsers,
  type UserOperator,
} from './FilterUserForm';
import {
  FilterTeamForm,
  DisplaySelectedTeams,
  type TeamOperator,
} from './FilterTeamForm';
import {
  DICTIONARIES,
  DictionaryFilterForm,
  DisplaySelectedDictionaryItems,
  type DictionaryOperator,
  type DictionaryName,
} from './DictionaryFilterForm';

export type FieldType = {
  name: string;
  type: string;
  enumValues?: Array<string>;
};

type FilterValueType = string | string[] | number | boolean | null;

export type TableType = {
  name: string;
  label: string;
  relationshipPath: string | null;
  fields: Array<FieldType>;
};

interface FilterChipProps {
  label?: string;
  tables: TableType[];
  tableName: string | null | undefined;
  initialCondition?: ConditionClause;
  initialTable?: string | null;
  defaultOpen?: boolean;
  onChange?: (value: ConditionClause, object: string | null) => void;
  onDelete?: () => void;
}

const FieldIcon = ({ type }: { type: string }) => {
  if (DICTIONARIES.includes(type)) {
    return <DictionaryIcon fontSize="small" />;
  }
  switch (type) {
    case 'String':
    case 'citext':
      return <StringIcon fontSize="small" />;
    case 'Boolean':
      return <BooleanIcon fontSize="small" />;
    case 'Int':
    case 'Float':
    case 'float8':
      return <NumberIcon fontSize="small" />;
    case 'timestamptz':
    case 'date':
      return <DateIcon fontSize="small" />;
    case 'users':
      return <Person fontSize="small" />;
    case 'teams':
      return <TeamIcon fontSize="small" />;
    case 'ENUM':
      return <ListIcon fontSize="small" />;
    case 'places':
      return <EarthIcon fontSize="small" />;
    default:
      return <StringIcon fontSize="small" />;
  }
};

const formatValueLabel = (
  value: string | number | string[] | boolean | null | undefined,
  operator: string | null | undefined,
) => {
  switch (operator) {
    case '_gte':
      return '>= ' + value;
    case '_lte':
      return '=< ' + value;
    case '_gt':
      return '> ' + value;
    case '_lt':
      return '< ' + value;
    default:
      return value;
  }
};

const formatLabel = (
  name: string,
  type: string,
  value: string | number | string[] | boolean | null | undefined,
  operator: string | null | undefined,
): any => {
  let valueLabel;
  const nameLabel = name.replace('.id', '');
  if (DICTIONARIES.includes(type)) {
    if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
      valueLabel = (
        <DisplaySelectedDictionaryItems
          dictionaryName={type as DictionaryName}
          ids={value}
        />
      );
    } else {
      valueLabel = value;
    }
  } else {
    switch (type) {
      case 'Int':
      case 'Float':
      case 'float8':
        valueLabel = formatValueLabel(value, operator);
        break;
      case 'timestamptz':
      case 'date':
        if (typeof value === 'string') {
          if (isSQLIntervalString(value)) {
            if (value.includes('now() -')) {
              valueLabel = formatValueLabel(
                value.replace('now() -', '') + ' ago',
                operator,
              );
            } else {
              valueLabel = formatValueLabel(
                value.replace('now() +', '') + ' from now',
                operator,
              );
            }
          } else if (isValid(new Date(value))) {
            valueLabel = formatValueLabel(
              format(new Date(value), 'dd/MM/yyyy'),
              operator,
            );
          }
        }
        break;
      case 'String':
      case 'citext':
        if (operator === '_ilike' || operator === '_nilike') {
          valueLabel = ((value as String) ?? '').replace(/%/g, '');
        } else {
          valueLabel = value;
        }
        break;
      case 'Boolean':
        valueLabel = value ? 'Yes' : 'No';
        break;
      case 'users':
        if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
          valueLabel = <DisplaySelectedUsers userIds={value} />;
        } else {
          valueLabel = value;
        }
        break;
      case 'teams':
        if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
          valueLabel = <DisplaySelectedTeams teamIds={value} />;
        } else {
          valueLabel = value;
        }
        break;
      case 'places':
        if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
          valueLabel = <DisplaySelectedPlaces placeIds={value} />;
        } else {
          valueLabel = value;
        }
        break;
      case 'ENUM':
        if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
          valueLabel =
            value.length < 2 ? value[0] : `${value[0]} +${value.length - 1}`;
        } else if (typeof value === 'boolean') {
          valueLabel = value.toString();
        }
        break;
      default:
        valueLabel = value;
    }
  }
  return (
    <Stack direction="row" alignItems="center" spacing="4px">
      <FieldIcon type={type} />
      <Typography variant="body2">{nameLabel}:</Typography>
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {valueLabel}
      </Typography>
    </Stack>
  );
};

const renderFieldBasedOnType = (
  field: FieldType,
  condition: {
    column: string | null;
    operator: ConditionOperator | null;
    value: FilterValueType;
    relationshipPath: string | null;
  },
  setNewCondition: React.Dispatch<
    React.SetStateAction<{
      column: string | null;
      operator: ConditionOperator | null;
      value: FilterValueType;
      relationshipPath: string | null;
    } | null>
  >,
) => {
  if (DICTIONARIES.includes(field.type)) {
    return (
      <DictionaryFilterForm
        dictionaryName={field.type as DictionaryName}
        value={
          // check if array of strings
          Array.isArray(condition?.value) &&
          condition?.value.every(item => typeof item === 'string')
            ? condition?.value
            : []
        }
        operator={
          condition?.operator
            ? (condition?.operator as DictionaryOperator)
            : null
        }
        onChange={value =>
          setNewCondition({
            ...condition,
            ...value,
            relationshipPath: condition.relationshipPath,
          })
        }
      />
    );
  }
  switch (field.type) {
    case 'String':
    case 'citext':
      return (
        <FilterStringForm
          value={typeof condition?.value === 'string' ? condition?.value : ''}
          operator={
            condition?.operator &&
            [
              '_eq',
              '_neq',
              '_ilike',
              '_iregex',
              '_nilike',
              '_niregex',
            ].includes(condition?.operator)
              ? (condition?.operator as StringOperator)
              : '_ilike'
          }
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'Boolean':
      return (
        <FilterBooleanForm
          value={
            typeof condition?.value === 'boolean' ? condition?.value : null
          }
          operator={'_eq'}
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'ENUM':
      return (
        <FilterEnumForm
          value={
            condition?.operator === '_is_null'
              ? condition?.value === true
              : Array.isArray(condition?.value)
              ? condition?.value
              : []
          }
          operator={
            condition?.operator &&
            ['_in', '_nin', '_is_null'].includes(condition?.operator)
              ? (condition?.operator as EnumOperator)
              : null
          }
          enumValues={field?.enumValues ?? []}
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'Float':
    case 'Int':
    case 'float8':
      return (
        <FilterNumberForm
          value={
            typeof condition?.value === 'number' ? condition?.value : undefined
          }
          operator={
            condition?.operator &&
            ['_eq', '_neq', '_gt', '_lt'].includes(condition?.operator)
              ? (condition?.operator as NumberOperator)
              : null
          }
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'users':
      return (
        <FilterUserForm
          value={
            // check if array of strings
            Array.isArray(condition?.value) &&
            condition?.value.every(item => typeof item === 'string')
              ? condition?.value
              : []
          }
          operator={
            condition?.operator && ['_in', '_nin'].includes(condition?.operator)
              ? (condition?.operator as UserOperator)
              : null
          }
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );

    case 'teams':
      return (
        <FilterTeamForm
          value={
            // check if array of strings
            Array.isArray(condition?.value) &&
            condition?.value.every(item => typeof item === 'string')
              ? condition?.value
              : []
          }
          operator={
            condition?.operator && ['_in', '_nin'].includes(condition?.operator)
              ? (condition?.operator as TeamOperator)
              : null
          }
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'timestamptz':
    case 'date':
      return (
        <FilterDateForm
          value={typeof condition?.value === 'string' ? condition?.value : ''}
          operator={
            condition?.operator &&
            ['_eq', '_neq', '_gt', '_lt', '_gte', '_lte'].includes(
              condition?.operator,
            )
              ? (condition?.operator as DateOperator)
              : null
          }
          columnType={field.type as 'timestamptz' | 'date'}
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    case 'places':
      return (
        <FilterPlacesForm
          value={
            // check if array of strings
            Array.isArray(condition?.value) &&
            condition?.value.every(item => typeof item === 'string')
              ? condition?.value
              : []
          }
          operator={
            condition?.operator && ['_in'].includes(condition?.operator)
              ? (condition?.operator as PlaceOperator)
              : null
          }
          onChange={value => {
            setNewCondition({
              ...condition,
              ...value,
              relationshipPath: condition.relationshipPath,
            });
          }}
        />
      );
    default:
      return null;
  }
};

const filterFields = (value: string, fields: FieldType[]) =>
  fields.filter(field =>
    field.name?.toLowerCase().includes(value.toLowerCase()),
  );

const filterTables = (value: string, tables: TableType[]) =>
  tables
    .filter(table => table.fields != null)
    .map(table => ({
      ...table,
      fields: filterFields(value, table.fields),
    }));

const FilterChipTable = ({
  table,
  condition,
  setNewCondition,
  initialCondition,
  searchValue,
  defaultOpen,
}: {
  table: {
    name: string;
    label: string;
    relationshipPath: string | null;
    fields: Array<FieldType>;
  };
  condition: {
    column: string | null;
    operator: ConditionOperator | null;
    value: FilterValueType;
    relationshipPath: string | null;
  } | null;
  setNewCondition: React.Dispatch<
    React.SetStateAction<{
      column: string | null;
      operator: ConditionOperator | null;
      value: FilterValueType;
      relationshipPath: string | null;
    } | null>
  >;
  initialCondition: ConditionClause | undefined;
  defaultOpen?: boolean;
  searchValue?: string;
}) => {
  // table open is searchValue is not empty or if initialCondition is not null
  const [tableOpen, setTableOpen] = React.useState(
    defaultOpen || initialCondition?.column != null,
  );

  // set to open if searchValue has changed and is not empty
  React.useEffect(() => {
    if (initialCondition?.column == null && defaultOpen !== true) {
      setTableOpen((searchValue ?? '') > '');
    }
  }, [defaultOpen, searchValue, initialCondition?.column]);

  return (
    <div key={table.name}>
      {table.fields.length > 0 && (
        <>
          <ListItemButton
            dense
            onClick={() => {
              setTableOpen(!tableOpen);
            }}
          >
            <ListItemText
              primary={table.label}
              primaryTypographyProps={{ variant: 'subtitle2' }}
            />
            {tableOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {tableOpen && (
            <Box p={2}>
              {table.fields.map(field => {
                const isFieldSelected =
                  condition?.column === field.name &&
                  table.relationshipPath === condition.relationshipPath;
                const displayForm =
                  (isFieldSelected || initialCondition != null) && condition;

                const label = (
                  <Stack direction="row" spacing={1} maxWidth="250px">
                    <FieldIcon type={field.type} />
                    <Typography variant="body2">
                      {field.name.split('.').filter(c => c !== 'id')}
                    </Typography>
                  </Stack>
                );

                return (
                  <Box key={field.name} maxWidth={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {!initialCondition?.column ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                '&.MuiCheckbox-root': {
                                  padding: '4px 10px',
                                },
                              }}
                              disableRipple
                              checked={isFieldSelected}
                              onChange={event => {
                                event.target.checked
                                  ? setNewCondition({
                                      column: field.name,
                                      operator: null,
                                      value: null,
                                      relationshipPath: table.relationshipPath,
                                    })
                                  : setNewCondition(null);
                              }}
                            />
                          }
                          label={label}
                        />
                      ) : (
                        label
                      )}
                    </Stack>
                    <Box maxWidth="300px">
                      {displayForm &&
                        renderFieldBasedOnType(
                          field,
                          condition,
                          setNewCondition,
                        )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export const FilterChip = (props: FilterChipProps) => {
  const {
    label,
    initialCondition,
    onChange,
    onDelete,
    tableName,
    defaultOpen,
  } = props;
  const { colors } = useTheme();
  let tables = props.tables;

  const hasCondition = initialCondition && isConditionClause(initialCondition);

  if (hasCondition) {
    tables = tables
      .filter(t =>
        tableName != null
          ? t.relationshipPath?.includes(tableName)
          : t.relationshipPath == null,
      )
      .map(t => ({
        ...t,
        fields: t.fields.filter(f => f.name === initialCondition?.column),
      }));
  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  // reset searchValue if drawer is closed with no condition
  React.useEffect(() => {
    if (!drawerOpen && initialCondition?.column == null && searchValue !== '') {
      setSearchValue('');
    }
  }, [drawerOpen, initialCondition, searchValue]);

  const searchFields = filterTables(searchValue, tables);

  const selectedField = searchFields
    .flatMap(table => table.fields)
    .find(field => field.name === initialCondition?.column);

  const condition = {
    column: initialCondition?.column ?? null,
    operator: initialCondition?.operator ?? null,
    value: initialCondition?.value ?? null,
    relationshipPath: null,
  };

  const formattedLabel = hasCondition
    ? formatLabel(
        condition?.column ?? '',
        selectedField?.type ?? '',
        condition?.value ?? '',
        condition?.operator ?? '',
      )
    : label;

  const [newCondition, setNewCondition] = React.useState<{
    column: string | null;
    operator: ConditionOperator | null;
    value: FilterValueType;
    relationshipPath: string | null;
  } | null>(condition);

  return (
    <>
      <Chip
        sx={{
          '& .MuiChip-label':
            hasCondition || label != null ? {} : { paddingRight: 0 },
          fontWeight: hasCondition ? 'bold' : 'normal',
        }}
        size="small"
        color={hasCondition ? 'primary' : 'default'}
        variant={'filled'}
        icon={hasCondition ? undefined : <AddIcon />}
        onClick={() => setDrawerOpen(true)}
        onDelete={hasCondition && onDelete ? () => onDelete() : undefined}
        label={formattedLabel}
      />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        sx={{
          '& .MuiBackdrop': { opacity: 0 },
        }}
      >
        <Stack width={350} height={1} role="presentation">
          <Stack direction="column" spacing={2} padding={3}>
            <Typography variant="h6">Filters</Typography>

            {!initialCondition?.column && (
              <Box
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: colors.grey100,
                  borderRadius: 3,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Filters"
                  onChange={event => {
                    const value = event.target.value;
                    setSearchValue(value);
                  }}
                  autoComplete="off"
                  autoFocus
                />
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            )}
          </Stack>
          <Divider />
          <Box sx={{ overflowY: 'auto', overflowX: 'clip' }}>
            <FormGroup>
              {searchFields.map(table => (
                <FilterChipTable
                  key={table.name}
                  table={table}
                  condition={newCondition}
                  setNewCondition={setNewCondition}
                  initialCondition={initialCondition}
                  searchValue={searchValue}
                  defaultOpen={defaultOpen ?? tables.length === 1}
                />
              ))}
            </FormGroup>
          </Box>
          <Divider />
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="text"
              onClick={() => {
                setDrawerOpen(false);
                setNewCondition(
                  initialCondition
                    ? {
                        ...initialCondition,
                        relationshipPath: null,
                      }
                    : null,
                );
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (
                  newCondition?.column &&
                  newCondition?.operator &&
                  newCondition?.value !== undefined &&
                  onChange
                ) {
                  // Get object based on table name and relationship path
                  const basePath = newCondition.relationshipPath?.split('.');
                  // remove all items until table name is found
                  basePath?.splice(0, basePath.indexOf(tableName ?? ''));
                  const actualPath = basePath?.join('.');

                  onChange(
                    {
                      column: newCondition.column,
                      operator: newCondition.operator,
                      value: newCondition.value,
                    },
                    actualPath ?? null,
                  );
                  setDrawerOpen(false);
                }
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Stack>
      </Drawer>
    </>
  );
};
