import { CSVImportModal } from '../../components/importer/CSVImportModal';
import { INSERT_USERS } from './userQueries';

type UsersImportProps = {
  open: boolean;
  onImportSuccess: () => void;
  onImporterClose: () => void;
};

const columns = [
  {
    name: 'Import ID',
    key: 'imported_id',
    required: false,
    description: 'The unique identifier of the user',
    data_type: 'string',
  },
  {
    name: 'First Name',
    key: 'first_name',
    required: true,
    description: 'The first name of the user',
    suggested_mappings: ['First Name', 'first_name', 'Firstname'],
    data_type: 'string',
  },
  {
    name: 'Last Name',
    key: 'last_name',
    required: true,
    description: 'The last name of the user',
    suggested_mappings: ['Last Name', 'last_name', 'Lastname'],
    data_type: 'string',
  },
  {
    name: 'Emails',
    key: 'emails',
    required: true,
    description: 'The email of the user',
    suggested_mappings: ['Email', 'Emails', 'emails[0].email'],
    data_type: 'string[]',
  },
  {
    name: 'Phone Numbers',
    key: 'phone_numbers',
    required: false,
    description: 'The phone number of the user',
    suggested_mappings: [
      'Phone Number',
      'Phone',
      'Landline',
      'phone_numbers[0].number',
    ],
    data_type: 'string[]',
  },
  {
    name: 'Language',
    key: 'language',
    required: false,
    description: 'The language of the user',
    suggested_mappings: ['Language', 'Locale'],
    data_type: 'string',
  },
  {
    name: 'Address Street Name',
    key: 'route',
    required: false,
    description: 'The route of the user',
    suggested_mappings: ['Route', 'Path', 'Line 1', 'Street'],
    data_type: 'string',
  },
  {
    name: 'Address Street Number',
    key: 'street_number',
    required: false,
    description: 'The street number of the user',
    suggested_mappings: ['Street Number', 'Number'],
    data_type: 'string',
  },
  {
    name: 'Address Postal Code',
    key: 'postal_code',
    required: false,
    description: 'The postal code of the user',
    suggested_mappings: ['Postal Code', 'Zip Code'],
    data_type: 'string',
  },
  {
    name: 'Address Locality',
    key: 'locality',
    required: false,
    description: 'The locality of the user',
    suggested_mappings: ['Locality', 'City'],
    data_type: 'string',
  },
  {
    name: 'Address Country Code',
    key: 'country_code',
    required: false,
    description: 'The country of the user',
    suggested_mappings: ['Country Code'],
    data_type: 'string',
  },
] as const;

export const UsersImport = (props: UsersImportProps) => {
  return (
    <CSVImportModal
      objectLabel="Users"
      open={props.open}
      columns={columns}
      onImportSuccess={props.onImportSuccess}
      onImporterClose={props.onImporterClose}
      mutationQuery={INSERT_USERS}
      constructMutationVariables={formattedData => {
        return {
          users: formattedData.map(({ emails, phone_numbers, ...data }) => ({
            ...data,
            emails: emails
              ? { data: emails?.map(email => ({ email })) }
              : undefined,
            phone_numbers: phone_numbers
              ? {
                  data: phone_numbers?.map(number => ({ number })) || [],
                }
              : undefined,
            language: data.language,
          })),
        };
      }}
    />
  );
};

export default UsersImport;
