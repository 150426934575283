import type {
  PropertyFeaturesFragment,
  PropertyPoiFragment,
} from '../../__generated__/graphql';
import { RaForm } from '../form/RaForm';
import { EEditListingForms } from '../listing-overview-form/ListingOverviewEditModal';
import { useListingOverviewEditFormDefinition } from '../listing-overview-form/listingOverviewFormDefinition';
import { type LightFormDefinition } from '../listing-overview-form/models';
import { type ListingCreationStepComponent } from './CreateListingStepper';

export const ListingFeaturesStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const equipmentFormDefinition = useListingOverviewEditFormDefinition({
    listing,
    formType: EEditListingForms.EQUIPMENT,
  });

  const poiFormDefinition = useListingOverviewEditFormDefinition({
    listing,
    formType: EEditListingForms.POI,
  });

  if (equipmentFormDefinition == null || poiFormDefinition == null) {
    return null;
  }

  const formDefinition: LightFormDefinition<
    PropertyFeaturesFragment & PropertyPoiFragment
  > = {
    formDefinition: ({ t, context }) => [
      ...equipmentFormDefinition.formDefinition({ t, context }).map(field => ({
        ...field,
        gridProps: { xs: 12, sm: 6, md: 4 },
      })),
      {
        type: 'category-title',
        label: t('Points of Interest'),
        name: 'poi-category-title',
      },
      ...poiFormDefinition.formDefinition({ t, context }),
    ],
    transformData: data => {
      const availabilityData = equipmentFormDefinition.transformData(data);
      const agentData = poiFormDefinition.transformData(data);

      return {
        lot: {
          ...(availabilityData.lot ?? {}),
          ...(agentData.lot ?? {}),
        },
        property: {
          ...(availabilityData.property ?? {}),
          ...(agentData.property ?? {}),
        },
      };
    },
    defaultValues: {
      ...equipmentFormDefinition.defaultValues,
      ...poiFormDefinition.defaultValues,
    },
    hasContext: false,
    validate: data => {
      const equipmentErrors = equipmentFormDefinition.validate?.(data) ?? [];
      const poiErrors = poiFormDefinition.validate?.(data) ?? [];

      return [...equipmentErrors, ...poiErrors];
    },
  };

  return (
    <RaForm
      formDefinition={formDefinition.formDefinition}
      onSubmit={data =>
        onFormSubmitted({
          action: 'update',
          ...formDefinition.transformData(data),
        })
      }
      defaultValues={formDefinition.defaultValues}
      validate={formDefinition.validate}
      actionButtonsComponent={footerActionsComponent}
      contentScrollable
    />
  );
};
