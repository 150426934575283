import * as React from 'react';
import { Stack, Select, MenuItem, TextField } from '@mui/material';
import type { ConditionOperator } from '../utils/parseWhereClause';

export type NumberOperator = Extract<
  ConditionOperator,
  '_eq' | '_neq' | '_lt' | '_gt'
>;

type FilterNumberFormProps = {
  value?: number;
  operator?: NumberOperator | null;
  onChange: (value: { operator: NumberOperator; value: number }) => void;
};

export const FilterNumberForm: React.FC<FilterNumberFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
}) => {
  const [value, setValue] = React.useState(initialValue ?? null);
  const [operator, setOperator] = React.useState(initialOperator ?? '_eq');

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const newOperator = event.target.value as NumberOperator;
          setOperator(newOperator);
          onChange({
            value: value != null ? value : 0,
            operator: newOperator,
          });
        }}
      >
        {[
          { value: '_gt', label: 'greater than' },
          {
            value: '_lt',
            label: 'lower than',
          },
          { value: '_eq', label: 'is exactly' },
          { value: '_neq', label: 'is not exactly' },
        ].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        size="small"
        fullWidth
        value={value != null ? value : ''}
        hiddenLabel
        onChange={(event: { target: { value: string } }) => {
          const newValue = parseFloat(event.target.value);
          setValue(newValue);
          onChange({
            value: newValue,
            operator,
          });
        }}
      />
    </Stack>
  );
};
