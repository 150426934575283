const decodeId = (id: string | null): string => {
  if (id == null) {
    throw new Error('no ID');
  }
  try {
    return atob(id);
  } catch (e) {
    throw new Error('no ID');
  }
};

export const fromHasuraGlobalId = (id: string | null): string => {
  const decodedArray = JSON.parse(decodeId(id));
  return decodedArray[decodedArray.length - 1];
};

export const toHasuraGlobalId = (type: string, id: string) => {
  const decoded = `[1, "public", "${type}", "${id}"]`;
  return btoa(decoded);
};

export const fromGlobalId = (id: string | null): string => {
  const match = decodeId(id).match(/.*:(?<id>.*)/);
  if (match?.groups) {
    return match.groups.id;
  }
  throw new Error('no ID');
};

export const toGlobalId = (type: string, id: string): string => {
  return btoa([type, id].join(':'));
};

export const fromArrayToStringHasura = (arr: string[]): string => {
  return JSON.stringify(arr).replace('[', '{').replace(']', '}');
};

export const getUUID = (id: string): string =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    id,
  )
    ? id
    : fromGlobalId(id);
