import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Autocomplete,
  CircularProgress,
  debounce,
  type InputProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  type SxProps,
  type Theme,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { PersonOutlineOutlined } from '@mui/icons-material';
import { useLocale } from '../../../src/hooks/locale';
import type {
  GetDefaultTeamQuery,
  TeamsSelectFragment,
  TeamsSelectQuery,
} from '../../__generated__/graphql';
import { TeamAvatar } from '../TeamAvatar';

const TEAMS_SELECT_FRAGMENT = gql`
  fragment TeamsSelect on teams {
    id
    name
    logo {
      id
      url
    }
    team_images {
      image {
        url
      }
    }
  }
`;

const SEARCH_TEAMS = gql`
  ${TEAMS_SELECT_FRAGMENT}
  query TeamsSelect($search: String!, $excludeTeamsIds: [uuid!]!) {
    teams(
      where: { name: { _ilike: $search }, id: { _nin: $excludeTeamsIds } }
      limit: 20
    ) {
      id
      ...TeamsSelect
    }
  }
`;

const GET_DEFAULT_TEAM = gql`
  ${TEAMS_SELECT_FRAGMENT}
  query GetDefaultTeam($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      ...TeamsSelect
    }
  }
`;

interface TeamsSelectProps {
  teamId?: string;
  label?: string;
  onChange?: (teamId: string | null) => void;
  size?: 'small' | 'medium';
  excludeTeamsIds?: string[];
  sx?: SxProps<Theme>;
  InputProps?: InputProps;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  openOnFocus?: boolean;
  fullWidth?: boolean;
  blurOnSelect?: boolean;
  clearOnBlur?: boolean;
}

export const TeamsSelect = ({
  onChange,
  InputProps,
  teamId,
  excludeTeamsIds = [],
  autoFocus = false,
  size = 'medium',
  sx,
  disabled = false,
  loading = false,
  label,
  openOnFocus = false,
  fullWidth = false,
  blurOnSelect = false,
  clearOnBlur = false,
}: TeamsSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<TeamsSelectFragment[]>([]);
  const [selectedValue, setSelectedValue] =
    useState<TeamsSelectFragment | null>(null);

  const { data: defaultTeam } = useQuery<GetDefaultTeamQuery>(
    GET_DEFAULT_TEAM,
    {
      skip: !teamId,
      variables: { id: teamId },
    },
  );

  useEffect(() => {
    if (defaultTeam?.teams_by_pk) {
      setSelectedValue(defaultTeam.teams_by_pk);
    }
  }, [defaultTeam]);

  const [searchTeams, { loading: loadingTeams, error }] =
    useLazyQuery<TeamsSelectQuery>(SEARCH_TEAMS, {
      variables: {
        excludeTeamsIds,
      },
    });

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchTeams({
          variables: {
            search: `%${value ?? ''}%`,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.teams ?? []);
        });
      }, 275),
    [searchTeams],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    } else {
      setOpen(false);
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      sx={sx}
      disabled={disabled}
      autoComplete={false}
      autoCorrect="off"
      open={open}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      fullWidth={fullWidth}
      openOnFocus={openOnFocus}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
      value={selectedValue}
      getOptionLabel={option => option.name || ''}
      options={options}
      loading={loadingTeams || loading}
      loadingText={t('Loading...')}
      noOptionsText={error ? t('Error') : t('No results')}
      onChange={(_event, value) => {
        setSelectedValue(value);
        onChange?.(value?.id ?? null);
      }}
      onBlur={() => {
        if (clearOnBlur) {
          setSelectedValue(null);
        }
      }}
      filterOptions={x => x}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <TeamAvatar
                logoUrl={
                  option.logo?.url ?? option.team_images?.[0]?.image?.url
                }
                size={40}
              />
            </ListItemAvatar>
            <ListItemText primary={option.name} />
          </ListItem>
        );
      }}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          autoFocus={autoFocus}
          onFocus={() => {
            openOnFocus && debouncedSearch('');
          }}
          placeholder={InputProps?.placeholder ?? t('Search teams')}
          onChange={handleSearch}
          variant="outlined"
          sx={{ background: 'white' }}
          size={size}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: InputProps?.startAdornment ?? (
              <PersonOutlineOutlined />
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} disableShrink />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
