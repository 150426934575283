import { FormControl, FormGroup, FormLabel } from '@mui/material';
import type { Control, FieldValues } from 'react-hook-form';
import { RaCheckbox } from './RaCheckbox';
import type { CheckboxFieldDefinition } from './RaForm';

interface RaCheckboxGroupProps<TFormData extends FieldValues = FieldValues> {
  control: Control<any>;
  label: string;
  checkboxes: CheckboxFieldDefinition<TFormData>[];
  formValues?: any;
}

export const RaCheckboxGroup = <TFormData extends FieldValues = FieldValues>({
  control,
  label,
  checkboxes,
  formValues = {},
}: RaCheckboxGroupProps<TFormData>) => {
  return (
    <FormControl fullWidth>
      <FormLabel component="legend" style={{ fontWeight: 500 }}>
        {label}
      </FormLabel>
      <FormGroup>
        {checkboxes.map(checkbox => {
          if (checkbox.render && !checkbox.render(formValues)) {
            return null;
          }
          return (
            <RaCheckbox
              key={checkbox.name}
              control={control}
              name={checkbox.name}
              label={checkbox.label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
