import * as React from 'react';
import { Remarkable } from 'remarkable';
import { useTheme } from '../hooks/theme';

type Props = {
  children: string;
  style?: React.CSSProperties;
};

export const Markdown = ({ children, style }: Props) => {
  const { colors } = useTheme();
  const html = React.useMemo(() => {
    return new Remarkable({
      breaks: true,
      html: true,
      linkTarget: '_blank',
    }).render(children);
  }, [children]);
  return (
    <div
      css={{
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        '> *:first-of-type': {
          marginTop: 0,
        },
        '*:last-child': {
          marginBottom: 0,
        },
        a: {
          color: colors.blue500,
          textDecoration: 'none',
        },
        ...style,
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
