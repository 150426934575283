import { CircularProgress } from '@mui/material';
import { Flex } from 'react-system';
import { useTheme } from '../hooks/theme';
import { TopbarTitle } from '../shared/topbar';

export const SuspenseFallback = ({ topBarTitle }: { topBarTitle?: string }) => {
  const { colors } = useTheme();

  return (
    <>
      {topBarTitle && <TopbarTitle>{topBarTitle}</TopbarTitle>}
      <Flex
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        css={{ color: colors.gray700 }}
      >
        <CircularProgress disableShrink />
      </Flex>
    </>
  );
};
