import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { DeleteForever, StarBorderRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ActionsMenu } from '../../components/actions-menu/ActionsMenu';
import { ImageGallery } from '../../components/image-gallery/ImageGallery';
import { useLocale } from '../../../src/hooks/locale';
import type {
  DeleteUserImageMutation,
  DeleteUserImageMutationVariables,
  SetUserProfileImageMutation,
} from '../../__generated__/graphql';
import { getUUID } from '../../../shared/global-id';
import { OVERVIEW_GET_TEAM } from '../teams/teamQueries';
import { DELETE_USER_IMAGE, SET_USER_PROFILE_IMAGE } from './userQueries';

export const UserImages = () => {
  const { t } = useLocale();
  const [dialog, setDialog] = useState<string | undefined>(undefined);
  const { userId, teamId } = useParams() as { userId: string; teamId: string };
  const id = getUUID(userId);

  const [deleteImage] = useMutation<DeleteUserImageMutation>(
    DELETE_USER_IMAGE,
    {
      update: (cache, _res, { variables }) => {
        const { id } = variables as DeleteUserImageMutationVariables;
        cache.evict({ id: `user_images:${id}` });
      },
    },
  );

  const [setUserProfileImage] = useMutation<SetUserProfileImageMutation>(
    SET_USER_PROFILE_IMAGE,
    {
      refetchQueries: [
        {
          query: OVERVIEW_GET_TEAM,
          variables: { id: teamId, includeSubscriptions: false },
        },
      ],
    },
  );

  const handleOpen = (id: string) => setDialog(id);
  const handleClose = () => setDialog(undefined);

  const handleDelete = async (id: string) => {
    await deleteImage({ variables: { id } });
    setDialog(undefined);
  };

  return (
    <ImageGallery
      parentId={id}
      parentType="user"
      cardActions={({ id }) => (
        <>
          <ActionsMenu
            label={t('Options')}
            actions={[
              {
                label: t('Make profile picture'),
                icon: <StarBorderRounded />,
                onClick: () =>
                  setUserProfileImage({
                    variables: {
                      user_images_id: id,
                      user_id: userId,
                    },
                  }),
              },
              {
                label: t('Delete'),
                onClick: () => handleOpen(id),
                icon: <DeleteForever />,
              },
            ]}
          />
          <Dialog open={dialog === id} onClose={handleClose}>
            <DialogTitle>{t('Delete image')}</DialogTitle>
            <DialogContent>
              {t('Are you sure you want to delete this image?')}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t('Cancel')}</Button>
              <Button onClick={() => handleDelete(id)}>{t('Delete')}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    />
  );
};
