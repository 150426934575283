import { gql, useQuery } from '@apollo/client';
import { Box, Fade, FormLabel, Grid, Skeleton } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocale } from '../../../src/hooks/locale';
import {
  type CreateListingMutationVariables,
  type GetPropertyTypesQuery,
  type Lots_Insert_Input,
  type Lots_Set_Input,
  type Properties_Insert_Input,
  type Properties_Set_Input,
} from '../../__generated__/graphql';
import { RaAddressInput, type RaAddressType } from '../form/RaAddressInput';
import { RaCheckbox } from '../form/RaCheckbox';
import { RaSelect } from '../form/RaSelect';
import { type ListingCreationStepComponent } from './CreateListingStepper';

export type PropertyAdressStepForm = Pick<
  Lots_Insert_Input,
  'hide_route' | 'use_dummy_address'
> &
  Pick<Properties_Insert_Input, 'property_type_id'> & {
    main_type: string;
    property: RaAddressType;
    dummy: RaAddressType;
  };

export const LISTING_CREATION_ADDRESS_STEP_FRAGMENT = gql`
  fragment ListingCreationAddressStepFragment on lots {
    hide_route
    property {
      property_type_id
      route
      locality
      state
      country
      country_code
      postcode
      lat
      lng
    }
    dummy_route
    dummy_locality
    dummy_state
    dummy_country_code
    dummy_postcode
    dummy_lat
    dummy_lng
  }
`;

const GET_PROPERTY_TYPES = gql`
  query getPropertyTypes {
    property_types {
      id
      name
      label
      main_type
    }
  }
`;

export const PropertyAdressStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const { t, countryCode } = useLocale();
  const { data } = useQuery<GetPropertyTypesQuery>(GET_PROPERTY_TYPES);

  const formMethods = useForm<PropertyAdressStepForm>({
    defaultValues: {
      hide_route: listing?.hide_route ?? false,
      use_dummy_address: listing?.use_dummy_address ?? false,
      property_type_id: listing?.property.property_type_id,
      main_type: listing?.property.__property_type?.main_type ?? '',
      property: listing?.property,
      dummy: {
        route: listing?.dummy_route,
        locality: listing?.dummy_locality,
        state: listing?.dummy_state,
        country_code: listing?.dummy_country_code,
        postcode: listing?.dummy_postcode,
        lat: listing?.dummy_lat,
        lng: listing?.dummy_lng,
        street_number: listing?.dummy_street_number,
      },
    },
  });

  const { control, setValue, handleSubmit, watch } = formMethods;

  const propertyMainTypesOptions = useMemo(
    () =>
      data?.property_types
        .filter(pt => pt.main_type == null)
        .map(pt => ({
          value: pt.name ?? '',
          label: pt.label ?? '',
        }))
        .sort((a, b) => (a.label ?? '').localeCompare(b.label ?? '')),
    [data],
  );

  const getPropertyTypesOptions = useCallback(
    ({ main_type }: Omit<PropertyAdressStepForm, 'step'>) =>
      data == null
        ? []
        : data.property_types
            .filter(pt => pt.main_type === main_type)
            .map(pt => ({
              value: pt.id,
              label: pt.label ?? '',
            }))
            .sort((a, b) => (a.label ?? '').localeCompare(b.label ?? '')),
    [data],
  );

  const formValues = watch();

  const prepareDataFormSubmit = (
    data: PropertyAdressStepForm,
  ):
    | {
        lot?: Lots_Set_Input;
        property?: Properties_Set_Input;
        action: 'update';
      }
    | (CreateListingMutationVariables & { action: 'create' }) => {
    const { dummy, property_type_id, property, ...insertData } = data;
    const listingData = {
      ...insertData,
      ...(dummy != null
        ? {
            dummy_route: data.use_dummy_address ? dummy.route : null,
            dummy_street_number: data.use_dummy_address
              ? dummy.street_number
              : null,
            dummy_locality: data.use_dummy_address ? dummy.locality : null,
            dummy_state: data.use_dummy_address ? dummy.state : null,
            dummy_country_code: data.use_dummy_address
              ? dummy.country_code
              : null,
            dummy_postcode: data.use_dummy_address ? dummy.postcode : null,
            dummy_lng: data.use_dummy_address ? dummy.lng : null,
            dummy_lat: data.use_dummy_address ? dummy.lat : null,
          }
        : {}),
    };

    return listing == null
      ? {
          action: 'create',
          lot: {
            ...listingData,
            property: {
              data: {
                property_type_id,
                ...property,
              },
            },
          },
        }
      : {
          action: 'update',
          lot: listingData,
          property: {
            property_type_id,
            ...property,
          },
        };
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(data =>
          onFormSubmitted(prepareDataFormSubmit(data)),
        )}
        css={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Grid
          container
          spacing={2}
          padding={2}
          alignContent="flex-start"
          sx={{ flex: 1, minHeight: 0, overflow: 'auto' }}
        >
          <Grid item xs={12}>
            <Box mb={1}>
              <FormLabel sx={{ fontWeight: 500 }}>
                {t('Property address')}
              </FormLabel>
            </Box>
            {/* TODO: Handle validation of this field with validator */}
            <RaAddressInput
              id="property_address"
              countryRestriction={countryCode}
              setValue={setValue}
              formValues={formValues.property ?? {}}
              path="property"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RaCheckbox
              name="hide_route"
              control={control}
              label={t('Hide street')}
              useSwitch
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RaCheckbox
              name="use_dummy_address"
              control={control}
              label={t('Use a dummy address')}
              useSwitch
            />
          </Grid>
          <Fade in={formValues.use_dummy_address === true} unmountOnExit>
            <Grid item xs={12}>
              <Box mb={1}>
                <FormLabel sx={{ fontWeight: 500 }}>
                  {t('Dummy address')}
                </FormLabel>
              </Box>
              <RaAddressInput
                id="dummy_address"
                countryRestriction={countryCode}
                setValue={setValue}
                formValues={formValues.dummy ?? {}}
                path="dummy"
              />
            </Grid>
          </Fade>
          <Grid item xs={12} sm={6}>
            {propertyMainTypesOptions == null ? (
              <Skeleton height={40} width="100%" variant="rounded" />
            ) : (
              <RaSelect
                name="main_type"
                label={t('Property main type')}
                control={control}
                options={propertyMainTypesOptions}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <RaSelect
              name="property_type_id"
              label={t('Property type')}
              control={control}
              options={getPropertyTypesOptions(formValues)}
              required
            />
          </Grid>
        </Grid>
        {footerActionsComponent}
      </form>
    </FormProvider>
  );
};
