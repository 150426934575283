import { NumbersOutlined } from '@mui/icons-material';
import { Box, Button, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';

export const NumberFilter = ({
  label,
  addWhereClause,
  deleteWhereClause,
  getValueFromPath,
  where,
  path,
}: QuickFilterProps) => {
  const defaultMinVal = getValueFromPath([...path, '_gte'], where);
  const defaultMaxVal = getValueFromPath([...path, '_lte'], where);

  const [min, setMin] = useState<number | null>(defaultMinVal);
  const [max, setMax] = useState<number | null>(defaultMaxVal);
  const [, setFiltersParams] = useFiltersSearchParams();

  useEffect(() => {
    if (defaultMinVal == null) {
      setMin(null);
    }

    if (defaultMaxVal == null) {
      setMax(null);
    }
  }, [defaultMinVal, defaultMaxVal]);

  const apply = () => {
    let newWhere = deleteWhereClause(where, path, true);
    if (min != null) {
      newWhere = addWhereClause(newWhere, [...path, '_gte'], min);
    }
    if (max != null) {
      newWhere = addWhereClause(newWhere, [...path, '_lte'], max);
    }
    setFiltersParams(newWhere);
  };

  const clear = () => {
    let newWhere = where;
    setMin(null);
    setMax(null);
    newWhere = deleteWhereClause(newWhere, path, true);
    setFiltersParams(newWhere);
  };

  return (
    <>
      <FilterChip
        label={label}
        icon={<NumbersOutlined />}
        onDelete={min || max ? clear : undefined}
        renderFilter={({ handleClose }) => (
          <Paper>
            <form
              onSubmit={e => {
                e.preventDefault();
                apply();
                handleClose();
              }}
            >
              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 1,
                }}
              >
                <TextField
                  autoFocus
                  variant="outlined"
                  placeholder="min"
                  type="number"
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMin(e.target.value ? Number(e.target.value) : null);
                  }}
                  value={min}
                  sx={{ minWidth: 150 }}
                />
                <TextField
                  variant="outlined"
                  placeholder="max"
                  type="number"
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMax(e.target.value ? Number(e.target.value) : null);
                  }}
                  value={max}
                  sx={{ minWidth: 150 }}
                />
                <Button
                  onClick={() => {
                    apply();
                    handleClose();
                  }}
                  size="small"
                  type="submit"
                >
                  Apply
                </Button>
              </Box>
            </form>
          </Paper>
        )}
      />
    </>
  );
};
