import React, { useState, useMemo } from 'react';
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  CircularProgress,
  Checkbox,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import type { ConditionOperator } from '../utils/parseWhereClause';
import type {
  GetUsersQuery,
  GetSelectedUsersQuery,
} from '../__generated__/graphql';

export type UserOperator = Extract<ConditionOperator, '_in' | '_nin'>;

type FilterUserFormProps = {
  value: string[] | 'true' | 'false';
  operator: UserOperator | null;
  onChange: (params: {
    value: FilterUserFormProps['value'];
    operator: UserOperator;
  }) => void;
};

const GET_SELECTED_USERS = gql`
  query GetSelectedUsers($user_ids: [uuid!]) {
    users(where: { id: { _in: $user_ids } }) {
      id
      full_name
      primaryEmail: emails(
        order_by: { primary: desc, verified: desc }
        limit: 1
      ) {
        email
      }
    }
  }
`;

type DisplaySelectedUsersProps = {
  userIds: string[];
};

export const DisplaySelectedUsers: React.FC<DisplaySelectedUsersProps> = ({
  userIds,
}) => {
  const [users, setUsers] = useState<GetSelectedUsersQuery['users']>([]);

  useQuery<GetSelectedUsersQuery>(GET_SELECTED_USERS, {
    variables: { user_ids: userIds },
    onCompleted: data => {
      setUsers(data?.users ?? []);
    },
  });

  if (users?.length === 1) {
    return <>{users[0].full_name}</>;
  } else if (users?.length > 1) {
    return (
      <>
        {users[0].full_name} + {users.length - 1}
      </>
    );
  }
  return null;
};

const GET_USERS = gql`
  query GetUsers($q: String!) {
    search_users_tsv(args: { search_text: $q }, limit: 10) {
      id
      full_name
      primaryEmail: emails(
        order_by: { primary: desc, verified: desc }
        limit: 1
      ) {
        email
      }
    }
  }
`;

export const FilterUserForm: React.FC<FilterUserFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
}) => {
  const [value, setValue] = useState<GetUsersQuery['search_users_tsv']>([]);
  const [operator, setOperator] = useState(initialOperator ?? '_in');
  const [open, setOpen] = useState(false);

  useQuery<GetSelectedUsersQuery>(GET_SELECTED_USERS, {
    variables: { user_ids: initialValue },
    skip: initialValue.length === 0,
    onCompleted: data => {
      setValue(data?.users ?? null);
    },
  });

  const [getUsers, { data, loading }] = useLazyQuery<GetUsersQuery>(GET_USERS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        getUsers({
          variables: {
            q: value,
          },
        });
      }, 500),
    [getUsers],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    }
  };

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const selectedOperator = event.target.value as UserOperator;
          if (['_in', '_nin'].includes(selectedOperator)) {
            setOperator(selectedOperator);
            onChange({
              value: value?.map(user => user.id) ?? null,
              operator: selectedOperator,
            });
          }
        }}
      >
        {[{ value: '_in', label: 'is any of' }].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value ?? []}
        disableCloseOnSelect
        size="small"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option?.full_name ?? ''}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} dense disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                size="small"
                disableRipple
                sx={{
                  '&.MuiCheckbox-root': {
                    padding: '4px',
                  },
                }}
                checked={selected}
              />
            </ListItemIcon>
            <ListItemText
              primary={option.full_name}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondary={option.primaryEmail?.[0]?.email ?? 'no email'}
              secondaryTypographyProps={{
                noWrap: true,
              }}
            />
          </ListItem>
        )}
        options={data?.search_users_tsv ?? []}
        loading={loading}
        filterOptions={x => x}
        autoComplete
        multiple
        includeInputInList
        fullWidth
        noOptionsText="No users"
        clearOnBlur
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange({
            value: newValue?.map(user => user.id) ?? null,
            operator,
          });
        }}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            hiddenLabel
            onChange={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} disableShrink />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};
