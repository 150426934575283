import * as React from 'react';
import { useRifm } from 'rifm';
import { FilledInput } from '@material-ui/core';
import { makeNumberFormat } from '../utils/number-format';
import { useSelectionBound } from '../hooks/selection-bound';
import { useLocale } from '../hooks/locale';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  unit?: string;
  disableUnit?: boolean;
  decimalScale?: number;
};

type Format = (str: string) => string;

const prependFormat = (format: Format, prefix: string) => (str: string) =>
  prefix + format(str);

export const PriceInput = (props: Props) => {
  const { locale, countryCode } = useLocale();
  let unit = '';
  if (!props.disableUnit) {
    if (props.unit != null) {
      unit = `${props.unit} `;
    } else {
      switch (countryCode) {
        case 'CH':
          unit = 'CHF ';
          break;
        case 'GB':
          unit = '£ ';
          break;
        default:
          unit = '€ ';
      }
    }
  }
  const { accept, format, delocalizeNumber, localizeNumber } = makeNumberFormat(
    { scale: props.decimalScale ?? 2, locale },
  );
  const inputRef = React.useRef(null);
  const formatWithUnit = prependFormat(format, unit);
  const rifm = useRifm({
    accept,
    format: formatWithUnit,
    value: localizeNumber(props.value),
    onChange: formatted => props.onChange(delocalizeNumber(formatted)),
  });

  useSelectionBound(inputRef, unit.length, -1);

  return (
    <FilledInput
      inputRef={inputRef}
      value={rifm.value}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};
