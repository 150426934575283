import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Image } from '@realadvisor/image';
import {
  Avatar,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Select,
  SvgIcon,
  type SelectChangeEvent,
  type SxProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AccountCircleOutlined,
  Logout,
  SwapHorizOutlined,
  Undo,
} from '@mui/icons-material';
import { loginAsUser, logoutUser, parseJwt } from '@realadvisor/auth-client';
import { toGlobalId } from '../../shared/global-id';
import type { GetTenantsQuery } from '../__generated__/graphql';
import { Incognito } from '../../icons/incognito';
import { useLocale } from '../../src/hooks/locale';
import { useAppData } from '../providers/AppDataProvider';
import { GET_TENANTS } from '../pages/select-tenant/selectTenantQueries';
import { getTenantSlugStageAndSubdomainFromHostname } from '../../src/utils/tenant';
import { UserSelect } from './user-select/UserSelect';

export const AccountMenu = ({ sx }: { sx?: SxProps }) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const currentTenantName = me?.tenant.name;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modal, setModal] = useState<'switchTenant' | 'signInAs' | false>(
    false,
  );

  const [loggedInAs, setLoggedInAs] = useState<null | string>(null);

  const { data: tenantsData } = useQuery<GetTenantsQuery>(GET_TENANTS);

  const tenants = tenantsData?.tenants;

  const imageUrl = me?.user_images?.[0]?.image?.url;

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      const { loggedInAs } = parseJwt(access_token);
      setLoggedInAs(loggedInAs);
    }
  }, []);

  return (
    <>
      <ButtonBase
        onClick={handleClick}
        sx={{
          ...sx,
          justifyContent: 'flex-start',
          textTransform: 'none',
          textAlign: 'left',
          fontWeight: 600,
          fontSize: 16,
          p: 1,
          color: 'white',
        }}
      >
        <Avatar
          sx={{
            ml: '3px',
            mr: 2,
            border: '1px solid #fff',
            height: 34,
            width: 34,
          }}
        >
          {imageUrl && (
            <Image
              src={imageUrl ?? undefined}
              options={{
                w: 100,
              }}
            />
          )}
        </Avatar>
        {me ? [me?.first_name, me?.last_name].join(' ') : 'Loading...'}
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '22px !important',
            marginRight: '10px !important',
          },
        }}
      >
        <MenuItem
          component={Link}
          to={
            me?.id != null
              ? `/v1/users/${toGlobalId('User', me?.id)}/overview`
              : ''
          }
        >
          <AccountCircleOutlined />
          {t('My account')}
        </MenuItem>
        {(tenants?.length ?? 0) > 1 && (
          <MenuItem onClick={() => setModal('switchTenant')}>
            <SwapHorizOutlined />
            {currentTenantName ?? t('Change tenant')}
          </MenuItem>
        )}
        {me?.can_user_login_as && (
          <MenuItem onClick={() => setModal('signInAs')}>
            <SvgIcon>
              <Incognito />
            </SvgIcon>
            {t('Log in as')}
          </MenuItem>
        )}
        {loggedInAs && (
          <MenuItem
            onClick={() => {
              loginAsUser({ user_id: null });
            }}
          >
            <Undo />
            {t('Back to my account')}
          </MenuItem>
        )}
        <MenuItem onClick={() => logoutUser()}>
          <Logout />
          {t('Log out')}
        </MenuItem>
      </Menu>
      <ChangeTenant
        currentTenantId={me?.tenant.id}
        tenants={tenants}
        open={modal === 'switchTenant'}
        onClose={() => setModal(false)}
      />
      <SignInAs open={modal === 'signInAs'} onClose={() => setModal(false)} />
    </>
  );
};

type ChangeTenantsProps = {
  currentTenantId?: string;
  tenants?: GetTenantsQuery['tenants'];
  open: boolean;
  onClose: () => void;
};

const ChangeTenant = ({
  currentTenantId,
  tenants,
  open,
  onClose,
}: ChangeTenantsProps) => {
  const { t } = useLocale();

  const getSwitchUrl = (tenantId: string) => {
    const url = new URL(location.href);
    // identify tenant slug
    const { slug, stage } = getTenantSlugStageAndSubdomainFromHostname(
      location.hostname,
    );

    if (!slug) {
      return url.href;
    }

    // replace tenant slug by choosen tenant slug
    if (stage) {
      url.hostname = `${stage}--${
        tenants?.find(t => t.id === tenantId)?.slug ?? ''
      }.${url.hostname.split('.').slice(1).join('.')}`;
    } else {
      url.hostname = url.hostname.replace(
        slug,
        tenants?.find(t => t.id === tenantId)?.slug ?? '',
      );
    }

    return url.href;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Custom backdrop color
            backdropFilter: 'blur(12px)', // Apply blur effect
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <SwapHorizOutlined />
        {t('Chose new tenant')}
      </DialogTitle>
      <DialogContent>
        <Select
          value={currentTenantId}
          onChange={(e: SelectChangeEvent) => {
            const tenantId = e.target.value;
            onClose();
            window.open(getSwitchUrl(tenantId), '_blank');
          }}
          fullWidth
          variant="outlined"
          size="small"
        >
          {tenants?.map(tenant => (
            <MenuItem key={tenant.id} value={tenant.id}>
              {tenant.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

type SignInAsProps = {
  open: boolean;
  onClose: () => void;
};

const SignInAs = ({ open, onClose }: SignInAsProps) => {
  const { t } = useLocale();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 400,
          margin: '0 auto',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Custom backdrop color
            backdropFilter: 'blur(12px)', // Apply blur effect
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <SvgIcon>
          <Incognito />
        </SvgIcon>
        {t('Sign in as')}
      </DialogTitle>
      <DialogContent>
        <UserSelect autoFocus onChange={user_id => loginAsUser({ user_id })} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
