import * as React from 'react';
import { Stack, Select, MenuItem, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { useLocale } from '../../src/hooks/locale';
import type { ConditionOperator } from '../utils/parseWhereClause';
import {
  toSQLInterval,
  parseSQLInterval,
  isSQLInterval,
  type Interval,
} from '../utils/parseSQLInterval';

export type DateOperator = Extract<
  ConditionOperator,
  '_eq' | '_neq' | '_gt' | '_gte' | '_lt' | '_lte'
>;

export type DateType =
  | 'exact_date'
  | 'relative_date_past'
  | 'relative_date_future';

const DATETIME_INTERVAL_FIELDS: (keyof Interval)[] = [
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
];

const DATE_INTERVAL_FIELDS: (keyof Interval)[] = [
  'years',
  'months',
  'weeks',
  'days',
];

const DATE_OPERATORS_OPTIONS = [
  { value: '_eq', label: 'is on' },
  { value: '_neq', label: 'is not on' },
  { value: '_gt', label: 'is after' },
  { value: '_gte', label: 'is on or after' },
  { value: '_lt', label: 'is before' },
  { value: '_lte', label: 'is on or before' },
];

type DateOrIntervalOrNull = Interval | Date | null;

const getDateType = (value: DateOrIntervalOrNull) => {
  if (value instanceof Date) {
    return 'exact_date';
  } else if (isSQLInterval(value)) {
    return (value as Interval).type === 'past'
      ? 'relative_date_past'
      : 'relative_date_future';
  }
  return 'exact_date';
};

type DateColumnType = 'date' | 'timestamptz';

const valueToString = (
  value: Date | Interval | null,
  columnType: DateColumnType,
) => {
  if (value instanceof Date) {
    if (columnType === 'timestamptz') {
      return value.toISOString();
    } else {
      return value.toISOString().split('T')[0];
    }
  } else if (isSQLInterval(value)) {
    return toSQLInterval(value as Interval);
  }
  return '';
};

const toInt = (value: string) => {
  const parsed = parseInt(value);
  return isNaN(parsed) ? null : parsed;
};

type FilterDateFormProps = {
  value: string;
  operator: DateOperator | null;
  onChange: (params: { value: string; operator: DateOperator }) => void;
  columnType: DateColumnType;
};

export const FilterDateForm: React.FC<FilterDateFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
  columnType,
}) => {
  const { dateLocale } = useLocale();
  const [value, setValue] = React.useState<Date | Interval | null>(() => {
    if (initialValue) {
      // if value is a valid date string return it
      if (new Date(initialValue).toString() !== 'Invalid Date') {
        return new Date(initialValue);
      } else {
        // otherwise try to parse it as an interval if error return null
        try {
          return parseSQLInterval(initialValue);
        } catch (e) {
          return null;
        }
      }
    } else {
      return null;
    }
  });
  const [operator, setOperator] = React.useState<DateOperator>(
    initialOperator ?? '_eq',
  );
  const [dateType, setDateType] = React.useState<DateType>(getDateType(value));

  const handleIntervalChange = (newValue: string, field: string) => {
    const newIntValue = toInt(newValue);
    if (operator != null) {
      const newInterval = {
        ...value,
        [field]: newIntValue,
        type: dateType.split('_')[2] as 'past' | 'future',
      };
      setValue(newInterval);
      onChange({ value: toSQLInterval(newInterval), operator });
    }
  };

  const INTERVAL_FIELDS =
    columnType === 'date' ? DATE_INTERVAL_FIELDS : DATETIME_INTERVAL_FIELDS;

  const renderIntervalFields = (value: Interval) =>
    INTERVAL_FIELDS.map(field => (
      <TextField
        key={field}
        size="small"
        variant="filled"
        fullWidth
        type="number"
        label={field}
        InputLabelProps={{
          shrink: true,
          sx: {
            maxWidth: '120%',
            transform: 'translate(5px, 1px) scale(0.75)!important',
          },
        }}
        value={value?.[field] ?? ''}
        onChange={event => handleIntervalChange(event.target.value, field)}
      />
    ));

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocale}
    >
      <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
        <Select
          size="small"
          variant="filled"
          fullWidth
          value={operator}
          //@ts-ignore
          hiddenLabel
          onChange={event => {
            const selectedOperator = event.target.value as DateOperator;
            if (
              ['_eq', '_neq', '_gt', '_gte', '_lt', '_lte'].includes(
                selectedOperator,
              )
            ) {
              setOperator(selectedOperator);
              if (value) {
                onChange({
                  value: valueToString(value, columnType),
                  operator: selectedOperator,
                });
              }
            }
          }}
        >
          {DATE_OPERATORS_OPTIONS.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          size="small"
          variant="filled"
          fullWidth
          value={dateType}
          //@ts-ignore
          hiddenLabel
          onChange={event => {
            const selectedDateType = event.target.value as DateType;
            setDateType(selectedDateType);
            if (selectedDateType !== 'exact_date') {
              setValue({
                ...value,
                type: selectedDateType.split('_')[2] as 'past' | 'future',
              });
              onChange({
                value: toSQLInterval({
                  ...value,
                  type: selectedDateType.split('_')[2] as 'past' | 'future',
                }),
                operator,
              });
            } else {
              setValue(null);
              onChange({ value: '', operator });
            }
          }}
        >
          {[
            { value: 'exact_date', label: 'Custom date' },
            { value: 'relative_date_past', label: 'Days ago...' },
            { value: 'relative_date_future', label: 'Days from now...' },
          ].map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {dateType === 'exact_date' && (
          <DatePicker
            value={value instanceof Date ? value : null}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'filled',
                fullWidth: true,
                hiddenLabel: true,
              },
              shortcuts: {
                items: [
                  {
                    label: 'today',
                    getValue: () => {
                      return new Date();
                    },
                  },
                  {
                    label: 'yesterday',
                    getValue: () => {
                      return new Date(
                        new Date().setDate(new Date().getDate() - 1),
                      );
                    },
                  },
                  {
                    label: 'last week',
                    getValue: () => {
                      return new Date(
                        new Date().setDate(new Date().getDate() - 7),
                      );
                    },
                  },
                  {
                    label: 'last month',
                    getValue: () => {
                      return new Date(
                        new Date().setDate(new Date().getDate() - 30),
                      );
                    },
                  },
                ],
              },
            }}
            onChange={(newValue: Date | null) => {
              if (operator != null) {
                setValue(newValue);
                onChange({
                  value: valueToString(newValue, columnType),
                  operator,
                });
              }
            }}
          />
        )}
        {
          // 5 Number text fields months, weeks, days, hours, minutes that build a SQL interval
          ['relative_date_past', 'relative_date_future'].includes(dateType) && (
            <Stack direction="row" spacing={1}>
              {renderIntervalFields(value as Interval)}
            </Stack>
          )
        }
      </Stack>
    </LocalizationProvider>
  );
};
