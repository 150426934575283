import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PersonOutlineOutlined } from '@mui/icons-material';
import {
  debounce,
  type InputProps,
  ListItem,
  ListItemAvatar,
  ListItemText,
  type AutocompleteProps,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLocale } from '../../../src/hooks/locale';
import type {
  SearchUsersQuery,
  UserSelectFragment,
  Users_Bool_Exp,
  Users_Order_By,
} from '../../__generated__/graphql';
import {
  isCreatableItem,
  RaAutoComplete,
  type RaAutoCompleteCreatableItem,
} from '../data-grid/RaAutoComplete';
import { UserAvatar, USER_AVATAR_FRAGMENT } from '../UserAvatar';

export const USER_SELECT_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  fragment UserSelect on users {
    id
    full_name
    ...UserAvatar
    primaryEmail: emails(
      order_by: { primary: desc, verified: desc }
      limit: 1
    ) {
      email
    }
  }
`;

const SEARCH_USERS = gql`
  ${USER_SELECT_FRAGMENT}
  query SearchUsers(
    $q: String!
    $order_by: [users_order_by!]
    $where: users_bool_exp
  ) {
    search_users_tsv(
      args: { search_text: $q }
      limit: 10
      order_by: $order_by
      where: $where
    ) {
      id
      ...UserSelect
    }
  }
`;

const GET_DEFAULT_USER = gql`
  ${USER_SELECT_FRAGMENT}
  query GetDefaultUser($id: uuid!) {
    users_by_pk(id: $id) {
      id
      ...UserSelect
    }
  }
`;

interface UserSelectProps
  extends Omit<
    AutocompleteProps<UserSelectFragment, false, false, false>,
    'renderInput' | 'options' | 'onChange'
  > {
  userId?: string;
  onChange?: (userId: string | null) => void;
  onCreate?: (userData: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  }) => Promise<UserSelectFragment> | UserSelectFragment | void;
  InputProps?: InputProps;
  orderResultsBy?: Users_Order_By[];
  filters?: Users_Bool_Exp;
  creatable?: boolean;
}

const getCreatableItem = (
  inputStr: string,
): {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
} => {
  // regex don't need to be complex since it's not a validation
  // we just need to know if string is email-like, not if it is valid
  const EMAIL_REGEX = /^\S{1,64}@\S{1,64}\.\S{1,64}$/;

  let first_name: string | null = null;
  let last_name: string | null = null;
  let email: null | string = null;

  if (EMAIL_REGEX.test(inputStr)) {
    email = inputStr;
  } else {
    [first_name, last_name] = inputStr.split(' ');
  }

  return {
    first_name,
    last_name,
    email,
  };
};

export const UserSelect = ({
  onChange,
  onCreate,
  InputProps,
  userId,
  autoFocus = false,
  disabled,
  orderResultsBy,
  filters,
  sx,
  creatable = false,
}: UserSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<UserSelectFragment[]>([]);
  const autocompleteRef = React.useRef<HTMLDivElement | null>(null);
  const prevUserId = React.useRef<string | undefined>(userId);
  const [selectedValue, setSelectedValue] = useState<UserSelectFragment | null>(
    null,
  );

  const { data: defaultUser } = useQuery(GET_DEFAULT_USER, {
    skip: !userId,
    variables: { id: userId },
  });

  useEffect(() => {
    if (defaultUser) {
      setSelectedValue(defaultUser.users_by_pk);

      if (autoFocus && autocompleteRef.current != null) {
        autocompleteRef.current.querySelector('input')?.focus();
      }
    }
  }, [defaultUser, autoFocus]);

  if (prevUserId.current !== userId) {
    prevUserId.current = userId;

    if (userId == null) {
      setSelectedValue(null);
    }
  }

  const [searchUsers, { loading, error }] =
    useLazyQuery<SearchUsersQuery>(SEARCH_USERS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchUsers({
          variables: {
            q: value,
            order_by: orderResultsBy,
            where: filters,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.search_users_tsv ?? []);
        });
      }, 275),
    [searchUsers, orderResultsBy, filters],
  );

  return (
    <RaAutoComplete<UserSelectFragment>
      sx={sx}
      InputProps={{
        ...InputProps,
        startAdornment: InputProps?.startAdornment ?? (
          <PersonOutlineOutlined style={{ color: grey[700] }} />
        ),
        placeholder: disabled
          ? t('No user')
          : InputProps?.placeholder ?? t('Search users'),
      }}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option:
          | UserSelectFragment
          | RaAutoCompleteCreatableItem<UserSelectFragment>,
      ) =>
        isCreatableItem(option) ? (
          <ListItem {...props} key="new-user">
            <ListItemAvatar>
              <UserAvatar size={40} />
            </ListItemAvatar>
            <ListItemText
              primary={t('Create {{name}}', { name: option.inputValue })}
            />
          </ListItem>
        ) : (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <UserAvatar user={option} size={40} />
            </ListItemAvatar>
            <ListItemText
              primary={option.full_name}
              secondary={option.primaryEmail[0]?.email}
            />
          </ListItem>
        )
      }
      onChange={async (
        _: any,
        value:
          | UserSelectFragment
          | RaAutoCompleteCreatableItem<UserSelectFragment>
          | null,
      ) => {
        if (value != null && isCreatableItem(value)) {
          setSelectedValue(null);
          const result = onCreate?.(getCreatableItem(value.inputValue));

          if (result != null) {
            const newUser = result instanceof Promise ? await result : result;

            setSelectedValue(newUser);
            onChange?.(newUser.id);
          }
        } else {
          setSelectedValue(value);
          onChange?.(value?.id ?? null);
        }
      }}
      disabled={disabled}
      error={error != null}
      value={selectedValue}
      getOptionLabel={option => option.full_name || ''}
      options={options}
      loading={loading}
      open={open}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      autoFocus={autoFocus}
      debouncedSearch={debouncedSearch}
      setOpen={setOpen}
      setOptions={setOptions}
      ref={autocompleteRef}
      creatable={
        creatable
          ? {
              elementPosition: 'first',
              createItemFn: inputValue => ({ new: true, inputValue }),
            }
          : undefined
      }
    />
  );
};
