import { Box, Button, Paper, Stack } from '@mui/material';
import { useLocale } from '../../../src/hooks/locale';

type ListingStepperFooterActionsProps = {
  canNavigateBack: boolean;
  isLastStep: boolean;
  onBtnBackClicked: () => void;
};

export const ListingStepperFooterActions: React.FC<
  ListingStepperFooterActionsProps
> = ({ canNavigateBack, isLastStep, onBtnBackClicked }) => {
  const { t } = useLocale();

  return (
    <Paper square={true} variant="outlined" sx={{ p: 1 }}>
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          disabled={!canNavigateBack}
          onClick={onBtnBackClicked}
        >
          {t('Back')}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />

        <Button
          type="submit"
          disableElevation
          color="primary"
          variant="contained"
        >
          {isLastStep ? t('Finish') : t('Next')}
        </Button>
      </Stack>
    </Paper>
  );
};
