import { gql } from '@apollo/client';

export const ACTIVITY_FIELDS = gql`
  fragment activityFields on activities {
    id
    lead_id
    user_id
    created_at
    due_at
    done_at
    activity_type
    subject
    note
    success
    email_message {
      from
      to
      cc
      bcc
      unread
      snippet
      subject
      nylas_thread_id
    }
    creator {
      id
      full_name
    }
    assignee {
      id
      full_name
    }
  }
`;
