import type { FetchFunction } from 'relay-runtime';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'react-relay';
import { createNanoEvents } from 'nanoevents';
import type { ErrorsEmitter } from '@realadvisor/error';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import type { ReactNode } from 'react';
import { refreshToken } from '@realadvisor/auth-client';
import { getMainDefinition } from '@apollo/client/utilities';
import pick from 'lodash.pick';
import type { IntrospectionQuery, TypeNode } from 'graphql';
import { extractLanguageFromUrl, fallbackLanguage } from './src/locale';
import * as config from './src/config';

// only do this in dev mode // local dev only
let JSON_SCHEMA: IntrospectionQuery | undefined = undefined;
if (import.meta.env.DEV === true) {
  import('./apollo/__generated__/graphql.schema.json').then(
    module => (JSON_SCHEMA = module.default as unknown as IntrospectionQuery),
  );
}

const getTimeZoneId = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getNestedType = (type: TypeNode): string => {
  if (type.kind === 'NamedType') {
    return type.name.value;
  }
  return getNestedType(type.type);
};

const createEnvironment = ({
  endpoint,
  errorsEmitter,
}: {
  endpoint: string;
  errorsEmitter: ErrorsEmitter;
}) => {
  const fetchQuery: FetchFunction = async (operation, { ...variables }) => {
    // relay removes directives likes @deleteEdge before passing to fetch
    // though $connections variable is left and hasura fails because of it
    // prevent here passing it to hasura and leave only for relay
    delete variables.connections;

    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }
    const request: RequestInit = {
      method: 'POST',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        query: operation.text,
        variables,
        name: operation.name,
      }),
    };

    const response = await fetch(endpoint, request);
    const data = await response.json();
    if (data.errors) {
      if (
        data.errors.some((e: { message: string | string[] }) =>
          e.message.includes('JWT is expired'),
        )
      ) {
        await refreshToken();
        return fetchQuery(operation, variables, {});
      } else {
        errorsEmitter.emit('add', operation.name, data.errors);
      }
    }

    return data;
  };

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
};

export const errorsEmitter: ErrorsEmitter = createNanoEvents();

export const fetchApiQuery = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  const headers = new Headers({
    ...(init?.headers ?? {}),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  const access_token = localStorage.getItem('access_token');
  if (access_token != null) {
    headers.set('Authorization', `Bearer ${access_token}`);
  }
  const request: RequestInit = {
    ...(init ?? {}),
    headers,
    credentials: 'include',
  };

  const response = await fetch(input, request);
  const responseClone = response.clone();
  const data = await responseClone.json();
  if (data.errors) {
    if (
      data.errors.some((e: { message: string | string[] }) =>
        e.message.includes('JWT is expired'),
      )
    ) {
      await refreshToken();
      return fetchApiQuery(input, request);
    } else {
      errorsEmitter.emit('add', input.toString(), data.errors);
    }
  }

  return response;
};

type Props = {
  children: ReactNode;
};

let relayApiEnvironment: null | Environment = null;
export const RelayApiWrapper = (props: Props) => {
  if (relayApiEnvironment == null) {
    const lng = extractLanguageFromUrl() ?? fallbackLanguage;
    const tz = getTimeZoneId();
    const endpoint = `${config.api_origin}/graphql?lng=${lng}&tz=${tz}`;
    relayApiEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }
  return (
    <RelayEnvironmentProvider environment={relayApiEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

let relayHasuraEnvironment: null | Environment = null;
export const RelayHasuraWrapper = (props: Props) => {
  if (relayHasuraEnvironment == null) {
    const tz = getTimeZoneId() ?? '';
    const endpoint = `${config.hasura_origin}/v1beta1/relay?tz=${tz}`;
    relayHasuraEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }
  return (
    <RelayEnvironmentProvider environment={relayHasuraEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

let relayHasuraRawEnvironment: null | Environment = null;
export const RelayHasuraRawWrapper = (props: Props) => {
  if (relayHasuraRawEnvironment == null) {
    const tz = getTimeZoneId() ?? '';
    const endpoint = `${config.hasura_origin}/v1/graphql?tz=${tz}`;
    relayHasuraRawEnvironment = createEnvironment({
      endpoint,
      errorsEmitter,
    });
  }

  return (
    <RelayEnvironmentProvider environment={relayHasuraRawEnvironment}>
      {props.children}
    </RelayEnvironmentProvider>
  );
};

export const ApolloHasuraWrapper = (props: Props) => {
  const endpoint = `${config.hasura_origin}/v1/graphql`;

  const httpLink = createHttpLink({
    uri: endpoint,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const authLink = setContext((_, { headers }) => {
    // allow anonymous requests for tenant slug validation
    if (localStorage.getItem('access_token') == null) {
      return { headers };
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (
            err.extensions.code === 'invalid-jwt' &&
            err.message === 'Could not verify JWT: JWTExpired'
          ) {
            return fromPromise(
              refreshToken()
                .catch(error => {
                  throw error;
                })
                .then(() => true),
            )
              .filter(value => Boolean(value))
              .flatMap(() => {
                return forward(operation);
              });
          } else {
            return;
          }
        }
      }

      if (networkError) {
        throw new Error(`[Network error]: ${networkError}`);
      }
    },
  );

  const removeTypenameLink = removeTypenameFromVariables();

  // this link will prune mutation input variables to only include fields that are defined in the input class
  const pruneMutationInput = setContext((operation, prevContext) => {
    const definition = getMainDefinition(operation.query);
    if (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation'
    ) {
      let schema: IntrospectionQuery['__schema'] | undefined = undefined;
      if (JSON_SCHEMA != null) {
        schema = JSON_SCHEMA.__schema;
      } else {
        const cache = prevContext.cache as InMemoryCache;
        const normalizedCache = cache.extract();
        schema = normalizedCache?.['schema']?.['__schema'] as
          | IntrospectionQuery['__schema']
          | undefined;
      }

      if (!schema) {
        return operation;
      }

      for (const variableDef of definition.variableDefinitions ?? []) {
        try {
          // go through all nested type until we find NamedType
          const typeName = getNestedType(variableDef.type);

          // thanks to TS 5.5, filter can infer proper type
          const inputObject = schema.types
            .filter(t => t.kind === 'INPUT_OBJECT')
            .find(t => t.name === typeName);

          const fields = inputObject?.inputFields.map(field => field.name);
          if (!fields || fields.length === 0) {
            continue;
          }

          const name = variableDef.variable.name.value;
          if (operation.variables?.[name] != null) {
            const variable = operation.variables[name];
            if (Array.isArray(variable)) {
              operation.variables[name] = variable.map(v => {
                if (v != null) {
                  return pick(v, fields);
                } else {
                  return v;
                }
              });
            } else {
              operation.variables[name] = pick(variable, fields);
            }
          }
        } catch {
          // do nothing => this middleware is best effort
        }
      }
    }
    return operation;
  });

  const apolloClient = new ApolloClient({
    link: from([
      removeTypenameLink,
      pruneMutationInput,
      errorLink,
      authLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      resultCacheMaxSize: 100000,
      typePolicies: {
        cma_report_files: { keyFields: ['cma_report_id', 'file_id'] },
        property_files: { keyFields: ['property_id', 'file_id'] },
        Query: {
          fields: {
            lots_by_pk: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'lots',
                  id: args?.id,
                });
              },
            },
            dictionaries_by_pk: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'dictionaries',
                  id: args?.id,
                });
              },
            },
          },
        },
      },
    }),
    connectToDevTools: import.meta.env.NODE_ENV !== 'production',
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
