import * as React from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemText,
  TextField,
  debounce,
  type Theme,
  type SxProps,
  type InputProps,
} from '@mui/material';
import type {
  SearchOrganisationsQuery,
  OrganisationSelectFragment,
  GetDefaultOrganisationQuery,
} from '../../__generated__/graphql';
import { useLocale } from '../../../src/hooks/locale';

const ORGANISATION_SELECT_FRAGMENT = gql`
  fragment OrganisationSelect on organisations {
    id
    name
    website
  }
`;

const SEARCH_ORGANISATIONS = gql`
  ${ORGANISATION_SELECT_FRAGMENT}
  query SearchOrganisations($q: String!) {
    organisations_search(args: { search: $q }, limit: 10) {
      id
      ...OrganisationSelect
    }
  }
`;

const GET_DEFAULT_ORGANISATION = gql`
  ${ORGANISATION_SELECT_FRAGMENT}
  query GetDefaultOrganisation($id: uuid!) {
    organisations_by_pk(id: $id) {
      id
      ...OrganisationSelect
    }
  }
`;

interface OrganisationSelectProps {
  organisationId?: string;
  onChange?: (organisationId: string | null) => void;
  InputProps?: InputProps;
  sx?: SxProps<Theme>;
  autoFocus?: boolean;
}

export const OrganisationSelect = ({
  onChange,
  InputProps,
  organisationId,
  autoFocus = false,
  sx,
}: OrganisationSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<OrganisationSelectFragment[]>([]);
  const [selectedValue, setSelectedValue] =
    useState<OrganisationSelectFragment | null>(null);

  const { data: defaultOrganisation } = useQuery<GetDefaultOrganisationQuery>(
    GET_DEFAULT_ORGANISATION,
    {
      skip: !organisationId,
      variables: { id: organisationId },
    },
  );

  useEffect(() => {
    if (defaultOrganisation?.organisations_by_pk) {
      setSelectedValue(defaultOrganisation.organisations_by_pk);
    }
  }, [defaultOrganisation]);

  const [searchOrganisations, { loading, error }] =
    useLazyQuery<SearchOrganisationsQuery>(SEARCH_ORGANISATIONS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchOrganisations({
          variables: {
            q: value,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.organisations_search ?? []);
        });
      }, 275),
    [searchOrganisations],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    } else {
      setOpen(false);
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      sx={sx}
      autoComplete={false}
      autoCorrect="off"
      open={open}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      value={selectedValue}
      getOptionLabel={option => option.name || ''}
      options={options}
      loading={loading}
      loadingText={t('Loading...')}
      noOptionsText={error ? t('Error') : t('No results')}
      onChange={(_event, value) => {
        setSelectedValue(value);
        onChange?.(value?.id ?? null);
      }}
      filterOptions={x => x}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          <ListItemText primary={option.name} secondary={option.website} />
        </ListItem>
      )}
      componentsProps={{
        paper: {
          elevation: 3,
          sx: {
            borderRadius: '4px',
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          onChange={handleSearch}
          variant="outlined"
          sx={{ background: 'white' }}
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'one-time-code', // disable autocomplete and autofill
            type: 'new-password',
            name: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            placeholder: InputProps?.placeholder ?? t('Search organisations'),
            startAdornment: InputProps?.startAdornment,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} disableShrink />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
