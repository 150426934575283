import { useQuery } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Title } from 'react-head';
import { logoutUser } from '@realadvisor/auth-client';
import { ChevronRight } from '@mui/icons-material';
import { RealadvisorLogoNoText } from '../../../icons/realadvisor-logo-no-text';
import type { GetTenantsQuery } from '../../__generated__/graphql';
import { useLocale } from '../../../src/hooks/locale';
import { getTenantSlugStageAndSubdomainFromHostname } from '../../../src/utils/tenant';
import { GET_TENANTS } from './selectTenantQueries';

const getSwitchUrl = (tenantSlug: string, tenantId: string) => {
  const returnTo = new URLSearchParams(location.search).get('return');
  const returnUrl = new URL(returnTo ?? '/', location.origin);

  returnUrl.searchParams.set(
    'auth_code',
    localStorage.getItem('refresh_token') ?? '',
  );
  returnUrl.searchParams.set('tenant_id', tenantId);

  // Add or replace tenant slug to the hostname
  const { slug, stage, subdomain } = getTenantSlugStageAndSubdomainFromHostname(
    returnUrl.hostname,
  );

  if (subdomain === 'dev-crm') {
    // we are accessing dev-crm without tenantSlug only stage
    returnUrl.hostname = `${
      stage ?? slug
    }--${tenantSlug}.${subdomain}.realadvisor.com`;
  } else if (!slug) {
    returnUrl.hostname = `${tenantSlug}.${returnUrl.hostname}`;
  } else {
    returnUrl.hostname = returnUrl.hostname.replace(slug, tenantSlug);
  }

  return returnUrl.href;
};

export const SelectTenant = () => {
  const { data, loading, error } = useQuery<GetTenantsQuery>(GET_TENANTS);
  const { t } = useLocale();

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>{error.message}</AlertTitle>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (loading) {
    return null;
  }

  const tenants = data?.tenants;

  // if only one tenant, redirect to it
  if (tenants?.length === 1) {
    const tenant = tenants[0];
    const tenantSlug = tenant.slug;
    const tenantId = tenant.id;
    const switchUrl = getSwitchUrl(tenantSlug, tenantId);
    location.href = switchUrl;
    return null;
  }

  return (
    <>
      <Title>{t('Select tenant')} | RealAdvisor CRM</Title>
      <Container maxWidth="xs" sx={{ pt: 5, pb: 5 }}>
        <Box sx={{ display: 'grid', gap: 0.75 }}>
          <Typography
            variant="h5"
            component="div"
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 16,
            }}
          >
            <RealadvisorLogoNoText size={32} css={{ marginRight: 8 }} />
            RealAdvisor
          </Typography>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            {t('Please select tenant')}
          </Typography>
          <Card variant="outlined" elevation={0}>
            <List disablePadding dense>
              {loading &&
                Array.from({ length: 5 }).map((_, i) => (
                  <ListItem key={i} divider={i < 4}>
                    <Skeleton variant="text" width="100%" />
                  </ListItem>
                ))}
              {tenants?.map((tenant, i) => (
                <ListItem
                  disableGutters
                  disablePadding
                  sx={{ p: 0 }}
                  key={tenant.id}
                  divider={i < tenants.length - 1}
                >
                  <ListItemButton
                    component="a"
                    href={getSwitchUrl(tenant.slug, tenant.id)}
                  >
                    <ListItemText>{tenant.name}</ListItemText>
                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ChevronRight />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Card>
          <Button component={Link} to="/login">
            {t('Back to login')}
          </Button>
          <Button color="error" onClick={() => logoutUser()}>
            {t('Logout')}
          </Button>
        </Box>
      </Container>
    </>
  );
};
