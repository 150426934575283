/* eslint-disable relay/unused-fields */
import { graphql } from 'react-relay';
import { useInputQuery } from '../src/hooks/relay';
import type { globalSearchOrganisationsQuery } from './__generated__/globalSearchOrganisationsQuery.graphql';

export const useOrganisationsSearch = () => {
  return useInputQuery<globalSearchOrganisationsQuery>(
    graphql`
      query globalSearchOrganisationsQuery($search: String!) {
        organisations_search_connection(first: 10, args: { search: $search }) {
          edges {
            node {
              __typename
              id
              name
              street
              street_number
              postcode
              locality
              state
              country
              organisation_images(order_by: { is_primary: desc }) {
                image {
                  url
                }
              }
              emails(order_by: { primary: desc }) {
                email
              }
            }
          }
        }
      }
    `,
  );
};

export type OrganisationsSearch = ReturnType<typeof useOrganisationsSearch>;
export type OrganisationsNode =
  globalSearchOrganisationsQuery['response']['organisations_search_connection']['edges'][number]['node'];
