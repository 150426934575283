import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { useLocale } from '../../../src/hooks/locale';
import type { ExportLotsQuery } from '../../__generated__/graphql';
import { flatten } from '../../utils/flatten';
import type { ExportDialogProps } from '../../../list-toolbar/ListToolbar';
import { EXPORT_LOTS } from './lotsQueries';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: 'lots_export.csv',
});

export const ListingsExport = (props: ExportDialogProps) => {
  const [getLots, { loading, data }] =
    useLazyQuery<ExportLotsQuery>(EXPORT_LOTS);
  const { t } = useLocale();

  const { where, open, onExporterClose } = props;

  useEffect(() => {
    if (open) {
      getLots({ variables: { where } });
    }
  }, [open, getLots, where]);

  // Flatten the data for CSV export
  const lots = data?.lots.map((lot: any) => flatten(lot));

  // remove all key which include __typename
  if (lots) {
    lots.forEach(lot => {
      Object.keys(lot).forEach(key => {
        if (key.includes('__typename')) {
          delete lot[key];
        }
      });
    });
  }

  // Generate and download CSV file
  const downloadCSV = () => {
    if (lots) {
      const csv = generateCsv(csvConfig)(lots);
      download(csvConfig)(csv);
    }
    onExporterClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onExporterClose}
      aria-labelledby="loading-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="loading-dialog-title">
        {loading ? t('Preparing Export Data') : t('Export Ready')}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Alert
            severity="info"
            icon={<CircularProgress size={16} disableShrink />}
          >
            <AlertTitle>{t('Please wait')}</AlertTitle>
            {t('We are preparing your data for download')}
          </Alert>
        ) : (
          <Stack gap={2}>
            <Alert severity="success">
              <AlertTitle>{t('Data is ready')}</AlertTitle>
              {t('Your data is ready for download')}
            </Alert>
            <Button
              onClick={downloadCSV}
              color="primary"
              variant="contained"
              startIcon={<FileDownload />}
            >
              {t('Download CSV')}
            </Button>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onExporterClose} color="primary">
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
