import * as React from 'react';
import { LocaleProvider, LocaleContext } from '../locale';
import type { Locale } from '../locale';

export type { Translate } from '@realadvisor/translator/engine';
export type { Language, IntlLocale } from '../locale';

export { LocaleProvider };

export const useLocale = (): Locale => {
  return React.useContext(LocaleContext);
};
