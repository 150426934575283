import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Avatar,
  Button,
  Divider,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  type GridColDef,
  type GridRowsProp,
  type GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { LoadingButton } from '@mui/lab';
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Image } from '@realadvisor/image';
import { Person, Delete, Search } from '@mui/icons-material';
import { useAppData } from '../../providers/AppDataProvider';
import type {
  GetUsersListQuery,
  DeleteUsersMutation,
  Users_Bool_Exp,
  GetUsersListCountQuery,
} from '../../__generated__/graphql';
import { RaDataGridPro } from '../../components/data-grid/RaDataGridPro';
import { type FiltersTableDef } from '../../components/table/TableFilters';
import { TimeAgo } from '../../components/TimeAgo';
import { UserSelect } from '../../components/user-select/UserSelect';
import { TopbarTitle } from '../../../src/shared/topbar';
import { useLocale } from '../../../src/hooks/locale';
import { toGlobalId } from '../../../shared/global-id';
import { UserCreateDialog } from '../../../src/shared/user-create-dialog';
import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import {
  GET_USERS_LIST,
  DELETE_USERS,
  GET_USERS_LIST_COUNT,
} from './userQueries';
import { UserDrawer } from './UserDrawer';
import { UsersImport } from './UsersImport';
import { UsersExport } from './UsersExport';

type User = GetUsersListQuery['users'][number];

export const Users = () => {
  const [searchParams] = useSearchParams();
  const editMode = searchParams.get('edit') === 'true';
  const navigate = useNavigate();
  const { t } = useLocale();
  const { me } = useAppData();
  const [selectedUsers, setSelectedUsers] = useState<GridRowSelectionModel>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const where: Users_Bool_Exp = JSON.parse(searchParams.get('where') || '{}');
  const defaultFilters: Users_Bool_Exp = {
    is_deleted: { _eq: false },
  };
  const order_by = JSON.parse(
    searchParams.get('order_by') || '[{ "created_at": "desc" }]',
  );
  const limit = parseInt(searchParams.get('limit') ?? '100');
  const offset = parseInt(searchParams.get('offset') ?? '0');
  const legacyMatch = useMatch('/users');

  const userLink = (id: string) =>
    legacyMatch
      ? {
          pathname: `/v1/users/${toGlobalId('User', id)}`,
        }
      : {
          pathname: `/v2/users/${id}`,
          search: searchParams.toString(),
        };

  const filterTables: FiltersTableDef[] = [
    {
      name: 'users',
      label: 'Users',
      relationshipPath: null,
      fields: { table: 'users' },
    },
    {
      name: 'emails',
      label: 'Emails',
      relationshipPath: 'emails',
      fields: { table: 'emails' },
    },
    {
      name: 'leads',
      label: 'Leads',
      relationshipPath: 'leads',
      fields: { table: 'leads' },
    },
    {
      name: 'subscriptions',
      label: 'Subscriptions',
      relationshipPath: 'subscriptions',
      fields: { table: 'subscriptions' },
    },
  ];

  const { data, loading, error } = useQuery<GetUsersListQuery>(GET_USERS_LIST, {
    variables: {
      where: { ...defaultFilters, ...where },
      order_by,
      limit,
      offset,
    },
  });
  const users: GridRowsProp<User> = data?.users ?? [];

  const { data: countData } = useQuery<GetUsersListCountQuery>(
    GET_USERS_LIST_COUNT,
    {
      variables: {
        where: { ...defaultFilters, ...where },
      },
    },
  );

  const [deleteUsers, deleteOptions] = useMutation<DeleteUsersMutation>(
    DELETE_USERS,
    {
      refetchQueries: [
        {
          query: GET_USERS_LIST,
          variables: {
            where: { ...defaultFilters, ...where },
            order_by,
            limit,
            offset,
          },
        },
      ],
    },
  );

  const handleDelete = async (ids: string[]) => {
    await deleteUsers({
      variables: {
        ids,
      },
      refetchQueries: [
        {
          query: GET_USERS_LIST,
          variables: {
            where: { ...defaultFilters, ...where },
            order_by,
            limit,
            offset,
          },
        },
      ],
    });
    if (deleteOptions.error) {
      console.error(deleteOptions.error);
    } else {
      setSelectedUsers([]);
    }
  };

  if (error) {
    return <Alert severity="error">{JSON.stringify(error)}</Alert>;
  }

  const columns: GridColDef<User>[] = [
    {
      field: 'created_at',
      headerName: 'Created',
      width: 75,
      renderCell: ({ value }) => <TimeAgo dateString={value} />,
    },
    {
      field: 'avatar',
      headerName: '',
      width: 50,
      sortable: false,
      filterable: false,
      display: 'flex',
      renderCell: ({ row }) => {
        const imageUrl = row?.user_images?.[0]?.image?.url;
        return (
          <Avatar>
            {imageUrl != null ? (
              <Image src={imageUrl} srcSize={[[40, 40]]} />
            ) : (
              [
                row.first_name?.charAt(0) ?? null,
                row.last_name?.charAt(0) ?? null,
              ].join('') ?? <Person />
            )}
          </Avatar>
        );
      },
    },
    {
      field: 'first_name',
      headerName: t('First name'),
      width: 100,
    },
    {
      field: 'last_name',
      headerName: t('Last name'),
      width: 150,
    },
    {
      field: 'emails',
      headerName: t('Emails'),
      width: 250,
      valueGetter: (_, row) => {
        return row.emails
          .sort(a => (a.primary ? -1 : 1))
          .map(({ email }) => email)
          .join(', ');
      },
    },
    {
      field: 'phone_numbers',
      headerName: t('Phone Numbers'),
      width: 150,
      valueGetter: (_, row) => {
        return row.phone_numbers
          .sort(a => (a.primary ? -1 : 1))
          .map(({ number }) => number)
          .join(', ');
      },
    },
  ];

  return (
    <>
      <TopbarTitle>{t('Users')}</TopbarTitle>
      <Snackbar open={deleteOptions.error != null} autoHideDuration={6000}>
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
          {deleteOptions.error?.message}
        </Alert>
      </Snackbar>
      <ListToolbar
        tableName="users"
        tableFiltersTables={filterTables}
        quickFilters={[
          {
            label: 'Created at',
            path: ['users_bool_exp', 'created_at'],
          },
          {
            label: 'Subscription status',
            path: ['users_bool_exp', 'subscriptions', 'status', '_in'],
          },
          {
            label: 'Show in agency pages',
            path: ['users_bool_exp', 'show_in_agency_pages'],
          },
        ]}
        searchComponent={
          <UserSelect
            autoFocus
            InputProps={{
              startAdornment: <Search />,
            }}
            sx={{ maxWidth: 350, flexGrow: 1, ml: 0 }}
            onChange={userId => {
              if (!userId) {
                return;
              }
              navigate(userLink(userId));
            }}
          />
        }
        newLink="new"
        ImportDialog={UsersImport}
        ExportDialog={UsersExport}
        bulkActions={
          me?.is_admin && (
            <>
              <LoadingButton
                disableElevation
                onClick={() => {
                  setDeleteOpen(true);
                }}
                loading={deleteOptions.loading}
                loadingPosition="start"
                startIcon={<Delete />}
              >
                {t('Delete')}
              </LoadingButton>
              <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
                <DialogTitle>
                  {t('Are you sure you want to delete these users?')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t(
                      'This action cannot be undone. You are about to delete {{count}} users.',
                      {
                        count: selectedUsers.length,
                      },
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteOpen(false)}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleDelete(selectedUsers as string[]);
                      setDeleteOpen(false);
                    }}
                    color="error"
                  >
                    {t('Delete')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }
      />
      <Divider sx={{ opacity: 0.5 }} />
      <RaDataGridPro
        columns={columns}
        rows={users}
        rowCount={countData?.users_aggregate?.aggregate?.count ?? 0}
        onRowClick={({ id }) => navigate(userLink(id as string))}
        loading={loading}
        checkboxSelection={editMode}
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelection => {
          setSelectedUsers(newSelection);
        }}
        rowSelectionModel={selectedUsers}
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
        }}
        columnVisibilityModel={{
          [GRID_CHECKBOX_SELECTION_COL_DEF.field]: editMode,
        }}
        sx={{
          borderRadius: 0,
          borderColor: 'transparent',
        }}
      />
      <Routes>
        <Route
          path="new"
          element={
            <UserCreateDialog
              open={true}
              onClose={() => navigate('.')}
              onCreate={({ id }) => navigate(`/v1/users/${id}`)}
            />
          }
        />
        <Route path=":userId/*" element={<UserDrawer />} />
      </Routes>
    </>
  );
};

export default Users;
