import * as React from 'react';
import { graphql } from 'react-relay';
import { Flex, Box, useSystem } from 'react-system';
import { useNavigate } from 'react-router-dom';
import { Paper, List, ListItem } from '@material-ui/core';
import { Input, IconButton, Avatar, CircularProgress } from '@material-ui/core';
import { ListSubheader, ListItemAvatar, ListItemText } from '@material-ui/core';
import { type UseComboboxReturnValue, useCombobox } from 'downshift';
import { Image } from '@realadvisor/image';
import { GpsFixed } from '../../icons/gps-fixed';
import { Home } from '../../icons/home';
import { Image as ImageIcon } from '../../icons/image';
import { Search } from '../../icons/search';
import { Close } from '../../icons/close';
import { useLocale } from '../../hooks/locale';
import { useInputQuery } from '../../hooks/relay';
import { useTheme } from '../../hooks/theme';
import { Portal } from '../../controls/popup';
import { UserAvatar } from '../../shared/user-avatar';
import { RelayApiWrapper, RelayHasuraWrapper } from '../../../networking';
import { fromHasuraGlobalId, toGlobalId } from '../../../shared/global-id';
import {
  type OrganisationsSearch,
  type OrganisationsNode,
  useOrganisationsSearch,
} from '../../../shared/global-search';
import type { GlobalSearchUsersQuery } from './__generated__/GlobalSearchUsersQuery.graphql';
import type { GlobalSearchLeadsQuery } from './__generated__/GlobalSearchLeadsQuery.graphql';
import type { GlobalSearchLotsQuery } from './__generated__/GlobalSearchLotsQuery.graphql';

type Props = {
  onClose: () => void;
  menuOpening: boolean;
};

const useSearchData = ({
  organisationsSearch,
}: {
  organisationsSearch: OrganisationsSearch;
}) => {
  const [organisations, refetchOrganisations, loadingOrganisations] =
    organisationsSearch;
  const [users, refetchUsers, loadingUsers] =
    useInputQuery<GlobalSearchUsersQuery>(
      graphql`
        query GlobalSearchUsersQuery($search: String!) {
          usersSearch(first: 10, search: $search) {
            edges {
              node {
                __typename
                ...userAvatar_user
                id
                firstName
                lastName
                emails {
                  email
                }
              }
            }
          }
        }
      `,
    );

  const [leads, refetchLeads, loadingLeads] =
    useInputQuery<GlobalSearchLeadsQuery>(
      graphql`
        query GlobalSearchLeadsQuery($search: String!) {
          leadsSearch(first: 10, filters: { search: $search }) {
            edges {
              node {
                __typename
                id
                contact {
                  firstName
                  lastName
                }
                property {
                  formattedAddress
                }
              }
            }
          }
        }
      `,
    );

  const [lots, refetchLots, loadingLots] = useInputQuery<GlobalSearchLotsQuery>(
    graphql`
      query GlobalSearchLotsQuery($search: String!) {
        lotsSearch(first: 10, filters: { search: $search }) {
          edges {
            node {
              __typename
              id
              title
              property {
                formattedAddress
              }
            }
          }
        }
      }
    `,
  );

  return {
    users: (users?.usersSearch?.edges ?? []).flatMap(edge =>
      edge?.node ? [edge.node] : [],
    ),
    leads: (leads?.leadsSearch?.edges ?? []).flatMap(edge =>
      edge?.node ? [edge.node] : [],
    ),
    lots: (lots?.lotsSearch?.edges ?? []).flatMap(edge =>
      edge?.node ? [edge.node] : [],
    ),
    organisations:
      organisations?.organisations_search_connection.edges?.map(
        edge => edge.node,
      ) ?? [],
    loading:
      loadingUsers || loadingLeads || loadingLots || loadingOrganisations,
    refetch: (search: string) => {
      refetchUsers({ search });
      refetchLeads({ search });
      refetchLots({ search });
      refetchOrganisations({ search });
    },
  };
};

type UsersNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GlobalSearchUsersQuery['response']['usersSearch']>['edges']
    >[number]
  >['node']
>;

const User = ({
  user,
  index,
  combobox,
}: {
  user: UsersNode;
  index: number;
  combobox: UseComboboxReturnValue<UsersNode>;
}) => {
  const { inputValue, highlightedIndex, getItemProps } = combobox;
  const term = inputValue.toLowerCase();
  const emails = user.emails.map(email => email.email);
  const matchedEmail = emails.find(v => v.includes(term));
  const email = matchedEmail ?? emails[0];
  return (
    <ListItem
      {...getItemProps({
        item: user,
        index,
        selected: index === highlightedIndex,
      })}
      button={true}
    >
      <ListItemAvatar>
        <UserAvatar
          customStyle={{ padding: '0px' }}
          user={user}
          disableLink={true}
        />
      </ListItemAvatar>
      <ListItemText
        primary={[user.firstName, ' ', user.lastName]}
        secondary={email}
      />
    </ListItem>
  );
};

type LeadsNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GlobalSearchLeadsQuery['response']['leadsSearch']>['edges']
    >[number]
  >['node']
>;

const Lead = ({
  lead,
  index,
  combobox,
}: {
  lead: LeadsNode;
  index: number;
  combobox: UseComboboxReturnValue<LeadsNode>;
}) => {
  const { highlightedIndex, getItemProps } = combobox;
  const user = lead.contact;
  const primary = user
    ? [user.firstName, ' ', user.lastName]
    : 'Anonymous User';
  return (
    <ListItem
      {...getItemProps({
        item: lead,
        index,
        selected: index === highlightedIndex,
      })}
      button={true}
    >
      <ListItemAvatar>
        <Avatar>
          <GpsFixed />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={lead.property?.formattedAddress}
      />
    </ListItem>
  );
};

type LotsNode = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<GlobalSearchLotsQuery['response']['lotsSearch']>['edges']
    >[number]
  >['node']
>;

const Lot = ({
  lot,
  index,
  combobox,
}: {
  lot: LotsNode;
  index: number;
  combobox: UseComboboxReturnValue<LotsNode>;
}) => {
  const { highlightedIndex, getItemProps } = combobox;
  return (
    <ListItem
      {...getItemProps({
        item: lot,
        index,
        selected: index === highlightedIndex,
      })}
      button={true}
    >
      <ListItemAvatar>
        <Avatar>
          <Home />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={lot.title}
        secondary={lot.property.formattedAddress}
      />
    </ListItem>
  );
};

const Organisation = ({
  item,
  index,
  combobox,
}: {
  item: OrganisationsNode;
  index: number;
  combobox: UseComboboxReturnValue<OrganisationsNode>;
}) => {
  const { colors } = useTheme();
  const { highlightedIndex, getItemProps } = combobox;
  const name = item.name;
  const email = item.emails[0]?.email;
  const imageUrl = item.organisation_images[0]?.image.url;
  return (
    <ListItem
      {...getItemProps({
        item,
        index,
        selected: index === highlightedIndex,
      })}
      button={true}
    >
      <Flex width="40px" height="40px" mr="16px">
        {imageUrl == null ? (
          <ImageIcon size={40} fill={colors.grey400} />
        ) : (
          <Image objectFit="contain" src={imageUrl} />
        )}
      </Flex>
      <ListItemText
        primary={[name, email != null ? '| ' + email : null]}
        secondary={[
          [item.street, item.street_number].filter(Boolean).join(' '),
          [item.postcode, item.locality].filter(Boolean).join(' '),
          [item.state, item.country].filter(Boolean).join(' - '),
        ]
          .filter(Boolean)
          .join(', ')}
      />
    </ListItem>
  );
};

const Popup = ({
  popupRef,
  open,
  children,
  menuOpening,
}: {
  popupRef: { current: null | HTMLElement };
  open: boolean;
  children: React.ReactNode;
  menuOpening: boolean;
}) => {
  const { media } = useSystem();
  const { depth } = useTheme();
  return (
    <Portal>
      <Paper
        ref={popupRef}
        square={true}
        style={{ display: open ? 'flex' : 'none' }}
        css={media({
          position: ['absolute', 'fixed'],
          zIndex: depth.inputPopup,
          left: (menuOpening ? 242 : 58) + 17, //sidebar container width when it close/open, refer layout.js:50
          transition: '0.3s',
          top: 8 + 48,
          width: ['100%', 'calc(100% - 32px)', 'calc(100% - 32px - 56px)'],
          maxHeight: [
            'calc(100% - 8px - 48px)',
            'calc(100% - 8px - 48px - 16px)',
          ],
        })}
      >
        {children}
      </Paper>
    </Portal>
  );
};

const ApiBasedGlobalSearch = ({
  onClose,
  menuOpening,
  organisationsSearch,
}: Props & { organisationsSearch: OrganisationsSearch }) => {
  const { users, organisations, leads, lots, loading, refetch } = useSearchData(
    {
      organisationsSearch,
    },
  );
  const navigate = useNavigate();
  const { t } = useLocale();
  const { colors, borderRadius } = useTheme();

  const suggestions = [...users, ...leads, ...lots, ...organisations];
  const indices = new Map();
  suggestions.forEach((suggestion, index) => {
    indices.set(suggestion, index);
  });

  const inputRef = React.useRef(null);
  const popupRef = React.useRef(null);
  const combobox = useCombobox({
    items: suggestions,
    itemToString: () => '',
    onInputValueChange: ({ inputValue }) => {
      if (inputValue != null && 1 < inputValue.length) {
        refetch(inputValue);
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        const getUrl = (item: (typeof suggestions)[number]) => {
          switch (item.__typename) {
            case 'User':
              return `/v1/users/${item.id}`;
            case 'Lead':
              return `/leads/${item.id}`;
            case 'Lot':
              return `/lots/${item.id}`;
            case 'organisations': {
              const id = toGlobalId(
                'Organisation',
                fromHasuraGlobalId(item.id),
              );
              return `/organisations/${id}`;
            }
            default:
              return '';
          }
        };
        onClose();
        navigate(getUrl(selectedItem));
      }
    },
  });

  const { getComboboxProps, getInputProps, getMenuProps } = combobox;
  const { inputValue } = combobox;

  return (
    <Box px={3} width={1} {...getComboboxProps()}>
      <Input
        {...getInputProps({
          onBlur: () => {
            if (inputValue.length <= 1) {
              onClose();
            }
          },
        })}
        css={{
          backgroundColor: colors.grey200,
          borderRadius: borderRadius.small,
          borderBottom: `1px solid ${colors.black}`,
        }}
        inputRef={inputRef}
        autoFocus={true}
        placeholder={t('search')}
        fullWidth={true}
        disableUnderline={true}
        startAdornment={
          <IconButton onClick={onClose}>
            {loading ? (
              <CircularProgress size={24} disableShrink />
            ) : (
              <Search />
            )}
          </IconButton>
        }
        endAdornment={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
      />

      <Popup
        popupRef={popupRef}
        menuOpening={menuOpening}
        open={suggestions.length !== 0 && 1 < inputValue.length}
      >
        <List
          {...getMenuProps()}
          css={{
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {users.length !== 0 && (
            <ListSubheader disableSticky={true}>{t('users')}</ListSubheader>
          )}
          {users.map(user => (
            <User
              key={user.id}
              user={user}
              index={indices.get(user)}
              combobox={combobox as UseComboboxReturnValue<UsersNode>}
            />
          ))}
          {leads.length !== 0 && (
            <ListSubheader disableSticky={true}>{t('leads')}</ListSubheader>
          )}
          {leads.map(lead => (
            <Lead
              key={lead.id}
              lead={lead}
              index={indices.get(lead)}
              combobox={combobox as UseComboboxReturnValue<LeadsNode>}
            />
          ))}
          {lots.length !== 0 && (
            <ListSubheader disableSticky={true}>{t('listings')}</ListSubheader>
          )}
          {lots.map(lot => (
            <Lot
              key={lot.id}
              lot={lot}
              index={indices.get(lot)}
              combobox={combobox as UseComboboxReturnValue<LotsNode>}
            />
          ))}
          {organisations.length !== 0 && (
            <ListSubheader disableSticky={true}>
              {t('organisations')}
            </ListSubheader>
          )}
          {organisations.map(organisation => (
            <Organisation
              key={organisation.id}
              item={organisation}
              index={indices.get(organisation)}
              combobox={combobox as UseComboboxReturnValue<OrganisationsNode>}
            />
          ))}
        </List>
      </Popup>
    </Box>
  );
};

const HasuraBaseGlobalSearch = ({ onClose, menuOpening }: Props) => {
  const organisationsSearch = useOrganisationsSearch();
  return (
    <RelayApiWrapper>
      <ApiBasedGlobalSearch
        onClose={onClose}
        menuOpening={menuOpening}
        organisationsSearch={organisationsSearch}
      />
    </RelayApiWrapper>
  );
};

export const GlobalSearch = ({ onClose, menuOpening }: Props) => {
  return (
    <RelayHasuraWrapper>
      <HasuraBaseGlobalSearch onClose={onClose} menuOpening={menuOpening} />
    </RelayHasuraWrapper>
  );
};
