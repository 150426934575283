import { ButtonBase, Typography } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import { useLocale } from '../../src/hooks/locale';
import { useTheme } from '../../src/hooks/theme';

export const UploadImageCard = ({ onClick }: { onClick: () => void }) => {
  const { t } = useLocale();
  const { colors } = useTheme();
  return (
    <div
      css={{
        position: 'relative',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        border: `2px dashed ${colors.divider}`,
        borderRadius: '8px',
        minHeight: '180px',
        height: '100%',
      }}
    >
      <ButtonBase
        onClick={onClick}
        focusRipple={true}
        css={{
          height: '100%',
          width: '100%',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <CloudUploadOutlined
          sx={{ fontSize: 48, color: colors.disabledText }}
        />
        <Typography variant="h6" color={colors.disabledText}>
          {t('Drag or click to upload')}
        </Typography>
      </ButtonBase>
    </div>
  );
};
