// useSchema.js
import { useContext } from 'react';
import { SchemaContext } from './SchemaProvider';

export const useSchema = () => {
  const context = useContext(SchemaContext);
  if (context == null) {
    throw new Error('useSchema must be used within a SchemaProvider');
  }
  return context;
};
