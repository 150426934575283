import type * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { green, red } from '@material-ui/core/colors';
import { type Translate, useLocale } from '../../src/hooks/locale';
import { formatPrice } from '../../src/utils/format-price';
import { Tag } from '../components/Tag';
import { gql } from '../__generated__';
import type { LotCardInfo_LotFragment } from '../__generated__/graphql';

export const LOT_CARD_INFO_FRAGMENT = gql(`
  fragment LotCardInfo_lot on lots {
    title
    currency
    sale_price
    rent_net
    rent_extra
    offer_type
    price_unit
    status
    hide_price_on_portals
    is_paid
    enquiries_aggregate {
      aggregate {
        count
      }
    }
    matching_buyer_leads: buyer_leads_aggregate(where: { status: { _eq: "matched" } }) {
      aggregate {
        count
      }
    }
    offers_aggregate {
      aggregate {
        count
      }
    }
    property {
      country_code
      route
      street_number
      postcode
      locality
      living_surface
      built_surface
      weighted_floor_surface
      computed_surface
      latest_appraisal {
        value
      }
    }
  }
`);

type Props = {
  lot: LotCardInfo_LotFragment;
  showTitle?: boolean;
  showInlineLabels?: boolean;
};

const getLotPrices = (
  lot: LotCardInfo_LotFragment,
  t: Translate,
  locale: Intl.LocalesArgument,
): {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  appraisalDiff?: null | number;
} => {
  const {
    offer_type,
    sale_price,
    rent_net,
    rent_extra,
    currency,
    price_unit,
    property,
  } = lot;

  let currencyToFormat: 'CHF' | 'GBP' | 'EUR';

  switch (currency) {
    case 'CHF':
      currencyToFormat = 'CHF';
      break;
    case 'GBP':
      currencyToFormat = 'GBP';
      break;
    case 'EUR':
      currencyToFormat = 'EUR';
      break;
    default:
      switch (property.country_code) {
        case 'CH':
          currencyToFormat = 'CHF';
          break;
        case 'GB':
          currencyToFormat = 'GBP';
          break;
        case 'FR':
        case 'DE':
        case 'ES':
        case 'IT':
        case 'NL':
        case 'PT':
        default:
          currencyToFormat = 'EUR';
          break;
      }
  }

  const formatNumber = (price: number) =>
    price.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'decimal',
    });

  const surface = property.computed_surface;

  if (offer_type === 'sell') {
    if (sale_price == null) {
      return {};
    }

    if (price_unit === 'sell') {
      const appraisal = property.latest_appraisal?.value;
      return {
        primary: formatPrice(sale_price, locale, currencyToFormat),
        secondary: surface && [
          formatPrice(sale_price / surface, locale, currencyToFormat),
        ],
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    if (price_unit === 'sellm2') {
      const appraisal = surface ? sale_price / surface : null;
      return {
        primary: [formatPrice(sale_price, locale, currencyToFormat), ' / m²'],
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    // in case a new price_unit was added
    return { primary: formatPrice(sale_price, locale, currencyToFormat) };
  }

  if (offer_type === 'rent') {
    if (rent_net == null) {
      return {};
    }

    const extra = (multiplier: number) =>
      rent_extra == null
        ? []
        : [' (+ ', formatNumber(rent_extra * multiplier), ')'];

    const secondary = (multiplier: number) =>
      surface && [
        formatPrice(
          (rent_net * multiplier) / surface,
          locale,
          currencyToFormat,
        ),
        ' / m² / ',
        t('year'),
        ...extra(multiplier),
      ];

    if (price_unit === 'daily') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('day'),
          ...extra(1),
        ],
        secondary: secondary(365),
      };
    }

    if (price_unit === 'monthly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('month'),
          ...extra(1),
        ],
        secondary: secondary(12),
      };
    }

    if (price_unit === 'weekly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('week'),
          ...extra(1),
        ],
        secondary: secondary(365 / 7),
      };
    }

    if (price_unit === 'yearly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / ',
          t('year'),
          ...extra(1),
        ],
        secondary: secondary(1),
      };
    }

    if (price_unit === 'm2yearly') {
      return {
        primary: [
          formatPrice(rent_net, locale, currencyToFormat),
          ' / m² / ',
          t('year'),
          ...extra(1),
        ],
      };
    }

    // in case a new price_unit was added
    return {
      primary: [formatPrice(rent_net, locale, currencyToFormat), ...extra(1)],
    };
  }

  return {};
};

export const getLotStatusTagVariant = (status: string) => {
  switch (status) {
    case 'available':
    case 'available_coming_soon':
    case 'available_off_market':
      return 'info';
    case 'reserved':
      return 'warning';
    case 'won':
      return 'success';
    case 'lost_sold_someone':
    case 'lost_sold_owner':
    case 'abandoned':
      return 'error';
    case 'draft':
    case 'paused':
    default:
      return 'neutral';
  }
};

export const LotCardInfo = (props: Props) => {
  const { t, locale } = useLocale();
  const { lot } = props;

  const prices = getLotPrices(lot, t, locale);

  const statusLabel = (() => {
    switch (lot.status) {
      case 'available':
        return t('available');
      case 'available_coming_soon':
        return t('availableComingSoon');
      case 'available_off_market':
        return t('availableOffMarket');
      case 'reserved':
        return t('reserved');
      case 'won':
        return t('won');
      case 'lost_sold_someone':
        return t('lostSoldBySomeone');
      case 'lost_sold_owner':
        return t('lostSoldByOwner');
      case 'abandoned':
        return t('lostAbandoned');
      case 'draft':
        return t('draft');
      case 'paused':
        return t('paused');
      default:
        return lot.status;
    }
  })();

  return (
    <Stack direction="column" flexGrow={1}>
      <Typography variant="caption" noWrap>
        {[
          lot.property.postcode,
          ' ',
          lot.property.locality,
          ', ',
          lot.property.route,
          ' ',
          lot.property.street_number,
        ]}
      </Typography>

      {props.showTitle != null && (
        <Typography variant="body1" noWrap>
          {lot.title != null && lot.title !== '' ? lot.title : '-'}
        </Typography>
      )}

      <Stack alignItems="center" direction="row">
        <Typography variant="h6" noWrap fontWeight={700}>
          {prices.primary != null ? (
            lot.hide_price_on_portals === true ? (
              <s>{prices.primary}</s>
            ) : (
              prices.primary
            )
          ) : (
            t('priceOnRequest')
          )}
        </Typography>

        {prices.appraisalDiff != null && (
          <Typography
            pl={1}
            variant="subtitle2"
            fontWeight={700}
            color={prices.appraisalDiff > 0 ? green[700] : red[700]}
          >
            {prices.appraisalDiff > 0 ? '+' : ''}
            {prices.appraisalDiff.toLocaleString(locale, {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            })}
            %
          </Typography>
        )}
      </Stack>

      <Typography mb={1} variant="body2">
        {prices.secondary ?? '-'}
      </Typography>
      {props.showInlineLabels != null && (
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Tag status={getLotStatusTagVariant(lot.status)}>{statusLabel}</Tag>
          {(lot?.enquiries_aggregate.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('enquiriesCount', {
                count: lot?.enquiries_aggregate.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
          {(lot?.matching_buyer_leads.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('matchesCount', {
                count: lot?.matching_buyer_leads.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
          {(lot?.offers_aggregate.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('offersCount', {
                count: lot?.offers_aggregate.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
        </Stack>
      )}
    </Stack>
  );
};
