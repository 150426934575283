// @flow

import * as React from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { DialogActions, Button } from '@material-ui/core';
import { FormControl, InputLabel } from '@material-ui/core';
import { Box, useResponsive } from 'react-system';
// $FlowFixMe[untyped-import]
import { useNavigate } from 'react-router-dom';
import { useLocale } from '../hooks/locale';
import { useSet } from '../hooks/set';
import { Checkbox } from '../controls/checkbox';
import { LotInput } from './lot-input';
import type { lotDuplicationDialogQuery } from './__generated__/lotDuplicationDialogQuery.graphql';
import type { lotDuplicationDialogMutation } from './__generated__/lotDuplicationDialogMutation.graphql';

type Props = {|
  lotId: ?string,
  developmentId: ?string,
  open: boolean,
  onClose: () => void,
|};

const Options = ({ lot, selectedOptions }) => {
  const { t } = useLocale();
  const photosCount = lot?.property.propertyImages?.length;
  const documentsCount = lot?.property.files.length;
  return (
    <>
      <Checkbox
        checked={selectedOptions.has('lot')}
        onChange={value => {
          if (value) {
            selectedOptions.add('lot');
          } else {
            selectedOptions.delete('lot');
          }
        }}
      >
        {t('lotDuplicationInfo')}
      </Checkbox>
      <Checkbox
        checked={selectedOptions.has('photos')}
        onChange={value => {
          if (value) {
            selectedOptions.add('photos');
          } else {
            selectedOptions.delete('photos');
          }
        }}
      >
        {photosCount == null
          ? t('photos')
          : t('photosCount', { count: photosCount })}
      </Checkbox>
      <Checkbox
        checked={selectedOptions.has('documents')}
        onChange={value => {
          if (value) {
            selectedOptions.add('documents');
          } else {
            selectedOptions.delete('documents');
          }
        }}
      >
        {documentsCount == null
          ? t('documents')
          : t('documentsCount', { count: documentsCount })}
      </Checkbox>
    </>
  );
};

const OptionsWithData = ({ lotId, selectedOptions }) => {
  const data = useLazyLoadQuery<lotDuplicationDialogQuery>(
    graphql`
      query lotDuplicationDialogQuery($id: ID!) {
        lotById(id: $id) {
          property {
            files {
              id
            }
            propertyImages {
              id
            }
          }
        }
      }
    `,
    { id: lotId },
  );
  return <Options lot={data.lotById} selectedOptions={selectedOptions} />;
};

export const LotDuplicationDialog = (props: Props): React.Node => {
  const responsive = useResponsive();
  const { t } = useLocale();
  const navigate = useNavigate();
  const selectedOptions = useSet<'lot' | 'photos' | 'documents'>([]);
  const fullScreen = responsive([true, false]);
  const [duplicateLot, duplicating] = useMutation<lotDuplicationDialogMutation>(
    graphql`
      mutation lotDuplicationDialogMutation($input: DuplicateLotInput!) {
        duplicateLot(input: $input) {
          lot {
            id
          }
        }
      }
    `,
  );

  const [selectedLot, setSelectedLot] = React.useState(null);
  const duplicatingLotId = props.lotId ?? selectedLot?.id;

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('whichDataCopyToTheNewLot')}</DialogTitle>

      <DialogContent>
        {props.lotId == null && (
          <Box mb={3}>
            <FormControl>
              <InputLabel>{t('lot')}</InputLabel>
              <LotInput
                filters={{ developmentId_eq: props.developmentId }}
                value={selectedLot}
                onChange={lot => setSelectedLot(lot)}
              />
            </FormControl>
          </Box>
        )}

        {duplicatingLotId == null ? (
          <Options lot={null} selectedOptions={selectedOptions} />
        ) : (
          <React.Suspense
            fallback={<Options lot={null} selectedOptions={selectedOptions} />}
          >
            <OptionsWithData
              lotId={duplicatingLotId}
              selectedOptions={selectedOptions}
            />
          </React.Suspense>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}>{t('cancel')}</Button>
        <Button
          color="primary"
          disabled={
            duplicatingLotId == null ||
            selectedOptions.size === 0 ||
            duplicating
          }
          onClick={() => {
            if (duplicatingLotId != null) {
              const input = {
                id: duplicatingLotId,
                options: selectedOptions.values(),
              };
              duplicateLot({
                variables: { input },
                onCompleted: payload => {
                  if (payload.duplicateLot?.lot) {
                    navigate(`/lots/${payload.duplicateLot.lot.id}`);
                  }
                },
              });
            }
          }}
        >
          {t('duplicate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
