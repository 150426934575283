import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import {
  DeleteOutline,
  StarBorderRounded,
  WallpaperRounded,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useLocale } from '../../../src/hooks/locale';
import { ImageGallery } from '../../components/image-gallery/ImageGallery';
import type {
  DeleteTeamImageMutation,
  DeleteTeamImageMutationVariables,
  UpdateTeamImagesMutation,
} from '../../__generated__/graphql';
import { ActionsMenu } from '../../components/actions-menu/ActionsMenu';
import { DELETE_TEAM_IMAGE, UPDATE_TEAM_IMAGES } from './teamQueries';

export const TeamImages = () => {
  const { t } = useLocale();
  const [dialog, setDialog] = useState<string | undefined>(undefined);
  const { teamId } = useParams() as { teamId: string };

  const [deleteImage] = useMutation<DeleteTeamImageMutation>(
    DELETE_TEAM_IMAGE,
    {
      update: (cache, _res, { variables }) => {
        const { id } = variables as DeleteTeamImageMutationVariables;
        cache.evict({ id: `team_images:${id}` });
      },
    },
  );

  const [updateTeam] = useMutation<UpdateTeamImagesMutation>(
    UPDATE_TEAM_IMAGES,
    {
      update: (cache, { data }) => {
        if (data?.update_teams_by_pk) {
          cache.modify({
            id: `teams:${teamId}`,
            fields: {
              cover_photo() {
                return data.update_teams_by_pk?.cover_photo ?? null;
              },
              logo() {
                return data.update_teams_by_pk?.logo ?? null;
              },
            },
          });
        }
      },
    },
  );

  const handleOpen = (id: string) => setDialog(id);
  const handleClose = () => setDialog(undefined);

  const handleDelete = async (id: string) => {
    await deleteImage({ variables: { id } });
    setDialog(undefined);
  };

  return (
    <ImageGallery
      parentId={teamId}
      parentType="team"
      cardActions={({ id, imageId }) => (
        <>
          <ActionsMenu
            label={t('Options')}
            actions={[
              {
                label: t('Make logo'),
                icon: <StarBorderRounded />,
                onClick: () =>
                  updateTeam({
                    variables: {
                      id: teamId,
                      team: { logo_image_id: imageId },
                    },
                  }),
              },
              {
                label: t('Make cover'),
                icon: <WallpaperRounded />,
                onClick: () =>
                  updateTeam({
                    variables: {
                      id: teamId,
                      team: { cover_photo_image_id: imageId },
                    },
                  }),
              },
              {
                label: t('Delete'),
                icon: <DeleteOutline />,
                onClick: () => handleOpen(id),
              },
            ]}
          />
          <Dialog open={dialog === id} onClose={handleClose}>
            <DialogTitle>{t('Delete image')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Are you sure you want to delete this image?')}
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={handleClose}>{t('Cancel')}</Button>
              <Button onClick={() => handleDelete(id)} color="error">
                {t('Delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    />
  );
};
