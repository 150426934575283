/**
 * @generated SignedSource<<7a363838e4fbfbaaa528ffc5e7c06345>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
type PropertyForm_root$fragmentType = any;
export type lotCreateDrawerPropertyFormQuery$variables = {||};
export type lotCreateDrawerPropertyFormQuery$data = {|
  +me: ?{|
    +id: string,
  |},
  +$fragmentSpreads: PropertyForm_root$fragmentType,
|};
export type lotCreateDrawerPropertyFormQuery = {|
  response: lotCreateDrawerPropertyFormQuery$data,
  variables: lotCreateDrawerPropertyFormQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "lotCreateDrawerPropertyFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PropertyForm_root"
      },
      (v1/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "lotCreateDrawerPropertyFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyType",
        "kind": "LinkedField",
        "name": "propertyTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mainType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantSettings",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ccc95ba131742dc07e829739dad719b3",
    "id": null,
    "metadata": {},
    "name": "lotCreateDrawerPropertyFormQuery",
    "operationKind": "query",
    "text": "query lotCreateDrawerPropertyFormQuery {\n  ...PropertyForm_root\n  me {\n    id\n  }\n}\n\nfragment PropertyForm_root on Query {\n  propertyTypes {\n    id\n    name\n    label\n    mainType\n  }\n  tenantSettings {\n    id\n    countryCode\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7de4b803e9f64d0639a372e4daf31fea";

export default node;
