import {
  Checkbox,
  FormControlLabel,
  Switch,
  type InternalStandardProps,
} from '@mui/material';
import type { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type RaCheckboxProps = Omit<
  InternalStandardProps<SwitchBaseProps, 'checkedIcon' | 'color' | 'icon'>,
  'name' | 'onChange' | 'onBlur' | 'checked' | 'ref'
> & {
  control: Control<any>;
  name: string;
  label: string;
  useSwitch?: boolean;
};

export const RaCheckbox: React.FC<RaCheckboxProps> = ({
  control,
  name,
  label,
  useSwitch = false,
  ...resProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            useSwitch ? (
              <Switch
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                ref={field.ref}
                checked={field.value ?? false}
                {...resProps}
              />
            ) : (
              <Checkbox
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                ref={field.ref}
                checked={field.value ?? false}
                {...resProps}
              />
            )
          }
          label={label}
        />
      )}
    />
  );
};
