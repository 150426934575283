import { useSearchParams } from 'react-router-dom';

export const useFiltersSearchParams = (): [
  URLSearchParams,
  (where: any) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  return [
    searchParams,
    (where: any) => {
      searchParams.set('where', JSON.stringify(where));
      searchParams.delete('limit');
      searchParams.delete('offset');
      setSearchParams(searchParams);
    },
  ];
};
