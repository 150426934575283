import React from 'react';
import { Rating } from '@mui/material';
import { type RatingProps } from '@mui/material/Rating/Rating';
import { roundRating } from '../utils/formatting';
import { useLocale } from '../../src/hooks/locale';

type RatingDisplayProps = {
  rating?: number | null;
  count?: number | null;
  showCount?: boolean;
  showAverage?: boolean;
  showRating?: boolean;
  showReviews?: boolean;
} & Omit<RatingProps, 'value'>;

const RatingDisplay: React.FC<RatingDisplayProps> = ({
  rating,
  count,
  showCount = true,
  showAverage = true,
  showRating = true,
  showReviews = false,
  ...restRating
}) => {
  const { t } = useLocale();
  const roundedRating = roundRating(rating);

  return (
    <>
      {showAverage && roundedRating.toFixed(1)}
      {showRating && <Rating value={roundedRating} {...restRating} />}
      {showCount && count !== undefined && (
        <>
          &nbsp;({count}
          {showReviews ? ` ${t('reviews')}` : null})
        </>
      )}
    </>
  );
};

export default RatingDisplay;
