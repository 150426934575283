import { type Dispatch, type SetStateAction, Suspense, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import type { ConditionOperator } from '../utils/parseWhereClause';

export const DICTIONARIES = [
  'offer_refused_types',
  'property_types',
  'document_types',
  'document_type_groups',
  'financing_lead_lost_types',
  'lead_lost_types',
  'lead_source_types',
  'occupation_types',
  // 'membership_types',
  // 'mandate_probability_types',
  // 'tags_types',
];

export type DictionaryOperator = Extract<ConditionOperator, '_in' | '_nin'>;
export type DictionaryName =
  | 'offer_refused_types'
  | 'property_types'
  | 'document_types'
  | 'document_type_groups'
  | 'financing_lead_lost_types'
  | 'lead_lost_types'
  | 'lead_source_types'
  | 'occupation_types';
// | 'membership_types'
// | 'mandate_probability_types'
// | 'tags_types';

type DictionaryFilterFormProps<T> = {
  value: string[];
  dictionaryName: DictionaryName;
  operator: T | null;
  onChange: (params: {
    value: DictionaryFilterFormProps<T>['value'];
    operator: T;
  }) => void;
};

const generateDictionaryQuery = (dictionaryName: DictionaryName) => gql`
  query GenerateDictionary {
    items: ${dictionaryName} {
      id
      label
    }
  }
`;

const generateSelectedDictionaryQuery = (dictionaryName: DictionaryName) => gql`
  query GenerateSelectedDictionary($ids: [uuid!]!) {
    items: ${dictionaryName}(where: { id: { _in: $ids } }) {
      id
      label
    }
  }
`;

type DictionaryItemsProps = {
  dictionaryName: DictionaryName;
  ids: string[];
};

export const DictionaryItemsContent = ({
  dictionaryName,
  ids,
}: DictionaryItemsProps) => {
  const query = generateSelectedDictionaryQuery(dictionaryName);

  const { data }: { data: { items: { id: string; label: string }[] } } =
    useSuspenseQuery(query, {
      variables: {
        ids,
      },
    });

  const items = data?.items ?? [];
  if (items.length === 1) {
    return <>{items[0].label}</>;
  } else if (items.length > 1) {
    return (
      <>
        {items[0].label} + {items.length - 1}
      </>
    );
  }
  return null;
};

export const DisplaySelectedDictionaryItems = (props: DictionaryItemsProps) => {
  return (
    <Suspense fallback={null}>
      <DictionaryItemsContent {...props} />
    </Suspense>
  );
};

const DictionarySelect = ({
  value,
  operator,
  setValue,
  onChange,
  dictionaryName,
}: {
  value: string[];
  operator: DictionaryOperator;
  setValue: Dispatch<SetStateAction<string[]>>;
  onChange: DictionaryFilterFormProps<DictionaryOperator>['onChange'];
  dictionaryName: DictionaryName;
}) => {
  const query = generateDictionaryQuery(dictionaryName);
  const { data }: { data: { items: { id: string; label: string }[] } } =
    useSuspenseQuery(query);

  const reasons = (data?.items.map(({ id, label }) => ({
    id: id ?? '',
    label: label ?? '',
  })) ?? []) as { id: string; label: string }[];
  return (
    <Autocomplete
      multiple
      size="small"
      fullWidth
      includeInputInList
      options={reasons}
      value={reasons.filter(reason => value.includes(reason.id))}
      disableCloseOnSelect
      clearOnBlur
      getOptionLabel={option => option.label}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense disableGutters disablePadding>
          <ListItemIcon>
            <Checkbox
              size="small"
              disableRipple
              sx={{
                '&.MuiCheckbox-root': {
                  padding: '4px',
                },
              }}
              checked={selected}
            />
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </ListItem>
      )}
      onChange={(_, newValues) => {
        const newIds = newValues.map(({ id }) => id);
        setValue(newIds);
        onChange({ operator, value: newIds });
      }}
      renderInput={params => <TextField {...params} size="small" hiddenLabel />}
    />
  );
};

export const DictionaryFilterForm = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
  dictionaryName,
}: DictionaryFilterFormProps<DictionaryOperator>) => {
  const [value, setValue] = useState<string[]>(initialValue ?? []);
  const [operator, setOperator] = useState(initialOperator ?? '_in');

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const selectedOperator = event.target.value as DictionaryOperator;
          setOperator(selectedOperator);
          onChange({ value, operator: selectedOperator });
        }}
      >
        {[
          { value: '_in', label: 'is any of...' },
          { value: '_nin', label: 'is not any of...' },
        ].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Suspense fallback={null}>
        <DictionarySelect
          value={value}
          setValue={setValue}
          operator={operator}
          onChange={onChange}
          dictionaryName={dictionaryName}
        />
      </Suspense>
    </Stack>
  );
};
