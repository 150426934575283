import React, { useEffect, useMemo, useReducer } from 'react';
import {
  Autocomplete,
  Box,
  debounce,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  type TextFieldProps,
  Typography,
} from '@mui/material';
import type { UseFormSetValue } from 'react-hook-form';
import { LocationOn, Search } from '@mui/icons-material';
import type { Map } from 'mapbox-gl';
import type {
  AutocompletePrediction,
  GeocoderResult,
  AutocompletionRequest,
} from '@realadvisor/hooks';
import {
  Mapbox as MapboxTS,
  LocationMarker as LocationMarkerTS,
} from '../Mapbox';

import { GOOGLE_MAPS_TOKEN } from '../../../src/config';
import { useLocale } from '../../../src/hooks/locale';

const loadScript = (src: string, position: HTMLElement, id: string) => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface Terms {
  offset: number;
  value: string;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlacePredictionType {
  description: string;
  matched_substrings: MainTextMatchedSubstrings[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Terms[];
  types: string[];
}

interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
  postcode_localities: string[];
}

interface LatLng {
  lat: () => number;
  lng: () => number;
}

interface LatLngBounds {
  northeast: LatLng;
  southwest: LatLng;
}

interface Geometry {
  location: LatLng;
  location_type: string;
  viewport: LatLngBounds;
  bounds: LatLngBounds;
}

interface PlaceType {
  types: string[];
  formatted_address: string;
  address_components: AddressComponents[];
  partial_match: boolean;
  place_id: string;
  postcode_localities: string[];
  geometry: Geometry;
}

export interface RaAddressType {
  street_number?: string | null;
  route?: string | null;
  locality?: string | null;
  postcode?: string | null;
  state?: string | null;
  country?: string | null;
  country_code?: string | null;
  lat?: number | null;
  lng?: number | null;
  google_place_id?: string;
}

type AddressKey = keyof RaAddressType;

type State = {
  inputValue?: string;
  address: RaAddressType;
  place_prediction: PlacePredictionType | null;
  place: PlaceType | null;
  inputFocused?: boolean;
};

type Action =
  | { type: 'setInputValue'; inputValue: string }
  | { type: 'setAddress'; address: RaAddressType }
  | { type: 'setAddressKey'; key: AddressKey; value: string | number }
  | { type: 'setPlacePrediction'; placePrediction: PlacePredictionType | null }
  | { type: 'setPlace'; place: any }
  | { type: 'setState'; state: State }
  | { type: 'setInputFocused'; inputFocused: boolean };

export type RaAddressInputProps = TextFieldProps & {
  region?: string;
  countryRestriction?: string;
  country?: string;
  setValue: UseFormSetValue<any>;
  path?: string;
  id: string;
  formValues?: any;
  includeGoogleFields?: boolean;
};

export const RaAddressInput = ({
  region,
  countryRestriction,
  setValue,
  size = 'small',
  disabled,
  required,
  path,
  formValues,
  includeGoogleFields = false,
}: RaAddressInputProps) => {
  const { t } = useLocale();
  const [options, setOptions] = React.useState<any>([]);
  const loaded = React.useRef(false);
  const autocompleteService = React.useRef<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  const geocoder = React.useRef<google.maps.Geocoder | undefined>(undefined);
  const mapbox = React.useRef<Map | null>(null);
  const mapboxTS = React.useRef<Map | null>(null);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_TOKEN}&libraries=places&callback=Function.prototype`,
        document.querySelector('head') as HTMLElement,
        'google-maps',
      );
    }
    loaded.current = true;
  }

  const geocode = async ({
    placeId,
    address,
  }: {
    placeId?: string;
    address?: string;
  }) => {
    if (!geocoder.current) {
      return;
    }
    geocoder.current.geocode(
      { placeId, address },
      (places: GeocoderResult[]) => {
        if (!places || places.length === 0) {
          return;
        }
        dispatch({ type: 'setPlace', place: places[0] });
        const address: RaAddressType = {};
        places[0].address_components.forEach((c: any) => {
          if (c.types.includes('route')) {
            address.route = c.long_name;
          }
          if (c.types.includes('street_number')) {
            address.street_number = c.long_name;
          }
          if (c.types.includes('sublocality')) {
            address.locality = c.long_name;
          }
          if (c.types.includes('locality')) {
            address.locality = c.long_name;
          }
          if (c.types.includes('postal_town')) {
            address.locality = c.long_name;
          }
          if (c.types.includes('postal_code')) {
            address.postcode = c.long_name;
          }
          if (c.types.includes('administrative_area_level_1')) {
            address.state = c.long_name;
          }
          if (c.types.includes('country')) {
            address.country_code = c.short_name;
            address.country = c.long_name;
          }
        });
        address.lat = places[0].geometry.location.lat();
        address.lng = places[0].geometry.location.lng();
        address.google_place_id = places[0].place_id;
        // document.activeElement?.blur();
        // onChange(address);
        dispatch({ type: 'setAddress', address });
      },
    );
  };

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'setInputValue':
          return { ...state, inputValue: action.inputValue };
        case 'setAddressKey':
          return {
            ...state,
            address: {
              ...state.address,
              [action.key]: action.value,
            },
          };
        case 'setAddress':
          return { ...state, address: action.address };
        case 'setPlacePrediction':
          return { ...state, place_prediction: action.placePrediction };
        case 'setPlace':
          return { ...state, place: action.place };
        case 'setState':
          return { ...action.state };
        case 'setInputFocused':
          return { ...state, inputFocused: action.inputFocused };
        default:
          return state;
      }
    },
    {
      inputValue: formValues.google_place_description,
      address: {
        country: formValues.country,
        country_code: formValues.country_code,
        locality: formValues.locality,
        postcode: formValues.postcode,
        route: formValues.route,
        state: formValues.state,
        street_number: formValues.street_number,
        lat: formValues.lat,
        lng: formValues.lng,
        google_place_id: formValues.google_place_id,
      },
      place_prediction: null,
      place: null,
      inputFocused: false,
    },
  );

  // Fill form values on address change
  useEffect(() => {
    for (const key in state.address) {
      const addressKey = key as AddressKey;
      if (!includeGoogleFields && addressKey === 'google_place_id') {
        continue;
      }
      setValue([path, addressKey].join('.'), state.address[addressKey], {
        shouldDirty: true,
      });
    }
    if (includeGoogleFields) {
      setValue([path, 'google_place_description'].join('.'), state.inputValue, {
        shouldDirty: true,
      });
    }
  }, [path, state.address, state.inputValue, setValue, includeGoogleFields]);

  // Declare debounced function to fetch predictions
  const fetchPredictions = useMemo(
    () =>
      debounce(
        (
          request: AutocompletionRequest,
          callback: (results?: readonly AutocompletePrediction[]) => void,
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        275,
      ),
    [],
  );

  // Fetch Predictions on input change or focus
  useEffect(() => {
    let active = true;

    // If input is not focused, then we don't fetch predictions
    if (!state.inputFocused) {
      return;
    }
    // If no google, then we can't do anything
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      geocoder.current = new window.google.maps.Geocoder();
    }
    // If no autocompleteService, then we can't do anything
    if (!autocompleteService.current) {
      return;
    }
    // If no input value, then we empty the options and return
    if (!state.inputValue || state.inputValue === '') {
      return setOptions([]);
    }

    fetchPredictions(
      {
        input: state.inputValue,
        region,
        componentRestrictions: countryRestriction
          ? { country: countryRestriction }
          : undefined,
      },
      (results?: readonly AutocompletePrediction[]) => {
        if (!active) {
          return;
        }
        let newOptions: readonly AutocompletePrediction[] = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      },
    );

    return () => {
      active = false;
    };
  }, [
    state.inputValue,
    state.inputFocused,
    fetchPredictions,
    countryRestriction,
    region,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              getOptionLabel={(option: any) =>
                typeof option === 'string' ? option : option.description
              }
              filterOptions={x => x}
              options={options}
              autoComplete
              disabled={disabled}
              includeInputInList
              filterSelectedOptions
              autoHighlight
              popupIcon={null}
              clearIcon={null}
              blurOnSelect
              onFocus={() => {
                dispatch({ type: 'setInputFocused', inputFocused: true });
              }}
              onBlur={() => {
                dispatch({ type: 'setInputFocused', inputFocused: false });
              }}
              value={state.inputValue}
              onChange={(_event: any, newValue: any) => {
                setOptions(newValue ? [newValue, ...options] : options);
                dispatch({
                  type: 'setPlacePrediction',
                  placePrediction: newValue,
                });
                geocode({
                  placeId: newValue?.place_id,
                });
              }}
              onInputChange={(_event, newInputValue) => {
                dispatch({
                  type: 'setInputValue',
                  inputValue: newInputValue,
                });
              }}
              size={size}
              fullWidth
              renderInput={({ inputProps, InputProps, ...params }) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size={size}
                  InputProps={{
                    ...InputProps,
                    startAdornment: <Search />,
                  }}
                  inputProps={{
                    ...inputProps,
                    value: state.inputValue || '',
                    spellCheck: false,
                    autoCorrect: 'off',
                    autoComplete: 'off',
                    autoCapitalize: 'off',
                  }}
                  style={{ background: 'white', paddingRight: 0 }}
                  placeholder={t('Search address...')}
                />
              )}
              renderOption={renderOption}
            />
          </Grid>

          <Grid item xs={9}>
            <FormControl fullWidth>
              <OutlinedInput
                fullWidth
                sx={{ background: 'white' }}
                name="route"
                size="small"
                placeholder={t('Street name')}
                autoComplete="street-address"
                value={state.address?.route || ''}
                inputProps={{
                  required,
                }}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'route',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <OutlinedInput
                placeholder={t('House #')}
                fullWidth
                size="small"
                sx={{ background: 'white' }}
                name="street_number"
                inputProps={{
                  required,
                }}
                disabled={disabled}
                value={state.address?.street_number || ''}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'street_number',
                    value: e.target.value,
                  })
                }
                autoComplete="street-number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{ background: 'white' }}
                fullWidth
                name="postcode"
                placeholder={t('Postal code')}
                size="small"
                autoComplete="postal-code"
                value={state.address?.postcode || ''}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'postcode',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{ background: 'white' }}
                size="small"
                fullWidth
                name="locality"
                placeholder={t('Locality')}
                autoComplete="address-level2"
                value={state.address?.locality || ''}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'locality',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <OutlinedInput
                fullWidth
                size="small"
                placeholder={t('State')}
                sx={{ background: 'white' }}
                name="state"
                autoComplete="address-level1"
                value={state.address?.state || ''}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'state',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <OutlinedInput
                fullWidth
                sx={{ background: 'white' }}
                name="country_code"
                size="small"
                placeholder={t('Country code')}
                autoComplete="country_code"
                value={state.address?.country_code || ''}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'country_code',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{ background: 'white' }}
                fullWidth
                name="country"
                size="small"
                placeholder={t('Country')}
                autoComplete="country"
                value={state.address?.country || ''}
                disabled={disabled}
                onChange={e =>
                  dispatch({
                    type: 'setAddressKey',
                    key: 'country',
                    value: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          {includeGoogleFields && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{ background: 'white' }}
                  fullWidth
                  name="google_place_id"
                  size="small"
                  placeholder={t('Google place id')}
                  value={state.address.google_place_id || ''}
                  onChange={e =>
                    dispatch({
                      type: 'setAddressKey',
                      key: 'google_place_id',
                      value: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{ background: 'white' }}
                fullWidth
                name="lat"
                size="small"
                placeholder={t('Latitude')}
                value={formValues.lat || ''}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{ background: 'white' }}
                fullWidth
                name="lng"
                size="small"
                placeholder={t('Longitude')}
                value={formValues.lng || ''}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ height: 300 }}>
          <MapboxTS
            mapRef={mapboxTS}
            style={{ borderRadius: 6 }}
            lat={state.address?.lat ?? undefined}
            lng={state.address?.lng ?? undefined}
            zoom={state.address?.lat ? 16 : 10}
          >
            {state.address.lat && (
              <LocationMarkerTS
                lat={state.address?.lat || 0}
                lng={state.address?.lng || 0}
                onDragend={latLng => {
                  mapboxTS.current?.panTo(latLng);
                  mapbox.current?.panTo(latLng);
                  setValue([path, 'lat'].join('.'), latLng.lat, {
                    shouldDirty: true,
                  });
                  setValue([path, 'lng'].join('.'), latLng.lng, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          </MapboxTS>
        </Box>
      </Grid>
    </Grid>
  );
};

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any,
) => {
  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', width: 44 }}>
          <LocationOn sx={{ color: 'text.secondary' }} />
        </Grid>
        <Grid
          item
          sx={{
            width: 'calc(100% - 44px)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {option.structured_formatting.main_text}

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
