import { Box, Button, Typography } from '@mui/material';
import { logoutUser } from '@realadvisor/auth-client';
import { useLocale } from '../hooks/locale';

export const NoAccess = () => {
  const { t } = useLocale();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop={20}
    >
      <Typography m={5} textAlign="center" variant="h4">
        {t("You don't have access to this resource")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          logoutUser();
        }}
      >
        {t('Log in with another account')}
      </Button>
    </Box>
  );
};
