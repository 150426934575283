import { Box } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  type GridDensity,
  type GridSortModel,
  type GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useNavigate, useSearchParams } from 'react-router-dom';

function generateNestedOrderBy(
  field: string,
  order: string | null | undefined,
): any {
  if (!order) {
    return null;
  }
  const parts = field.split('.');
  if (parts.length === 1) {
    return { [field]: order + '_nulls_last' };
  }
  const [firstPart, ...rest] = parts;
  return { [firstPart]: generateNestedOrderBy(rest.join('.'), order) };
}

function processMultipleFields(
  fields: string,
  order: string | null | undefined,
): any[] {
  return fields
    .split(',')
    .map(field => generateNestedOrderBy(field.trim(), order))
    .filter(Boolean);
}

const stringToDensity = (
  density: string | null | undefined,
  defaultDensity: GridDensity,
): GridDensity => {
  switch (density) {
    case 'compact':
      return 'compact';
    case 'standard':
      return 'standard';
    case 'comfortable':
      return 'comfortable';
    default:
      return defaultDensity;
  }
};

export const RaDataGridPro = ({
  sx,
  autoHeight,
  ...props
}: DataGridProProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const limit = parseInt(searchParams.get('limit') ?? '100');
  const offset = parseInt(searchParams.get('offset') ?? '0');

  const defaultVisibleColumns = props.columns.reduce((acc, column) => {
    return { ...acc, [column.field]: true };
  });

  const visible_columns: GridColumnVisibilityModel = JSON.parse(
    searchParams.get('visible_columns') ??
      JSON.stringify(defaultVisibleColumns),
  );

  const handeVisibleColumnsChange = (
    visible_columns: GridColumnVisibilityModel,
  ) => {
    searchParams.set('visible_columns', JSON.stringify(visible_columns));
    setSearchParams(searchParams);
  };
  const density: GridDensity = stringToDensity(
    searchParams.get('density'),
    props.density ?? 'standard',
  );

  const handleDensityChange = (density: GridDensity): void => {
    searchParams.set('density', density);
    setSearchParams(searchParams);
  };

  return (
    <Box flexGrow={autoHeight ? 0 : 1} sx={{ position: 'relative' }}>
      <div
        style={{
          ...(autoHeight
            ? {}
            : { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }),
          backgroundColor: 'white',
        }}
      >
        <DataGridPro
          pagination
          paginationMode={props.paginationMode ?? 'server'}
          paginationModel={{
            pageSize: limit,
            page: offset / limit,
          }}
          density={density}
          onDensityChange={handleDensityChange}
          pageSizeOptions={[100, 250, 500, 1000]}
          onPaginationModelChange={paginationModel => {
            const { page, pageSize } = paginationModel;
            searchParams.set('limit', pageSize.toString());
            searchParams.set('offset', (page * pageSize).toString());
            setSearchParams(searchParams);
          }}
          columnVisibilityModel={visible_columns}
          onColumnVisibilityModelChange={handeVisibleColumnsChange}
          sortingMode={props.sortingMode ?? 'server'}
          disableColumnMenu={props.disableColumnMenu ?? true}
          disableColumnSelector={props.disableColumnSelector ?? true}
          onRowClick={row =>
            navigate({
              pathname: `./${row.id}`,
              search: searchParams.toString(),
            })
          }
          onSortModelChange={(sortModel: GridSortModel) => {
            const order_by = sortModel.flatMap(({ field, sort }) =>
              processMultipleFields(field, sort),
            );

            searchParams.set('order_by', JSON.stringify(order_by));
            searchParams.set('offset', '0');
            setSearchParams(searchParams);
          }}
          sx={{
            ...(sx ?? {}),
            borderRadius: 0,
            // disable header selection style
            '.MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            // disable cell selection style
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            // pointer cursor on ALL rows
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
          autoHeight={autoHeight}
          {...props}
        />
      </div>
    </Box>
  );
};
