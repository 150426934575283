import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
} from 'react-hook-form';
import { type DateSelectDefinition } from './RaForm';

export type RaDateSelectProps<TFormData extends FieldValues = FieldValues> = {
  field: DateSelectDefinition<TFormData>;
  disabled?: boolean;
  control: Control<TFormData, any>;
};

export const RaDateSelect = <TFormData extends FieldValues = FieldValues>({
  field,
  disabled,
  control,
}: RaDateSelectProps<TFormData>) => {
  return (
    <Controller
      name={field.name}
      control={control}
      render={({ fieldState: { error }, field: { value, onChange } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel
            style={{
              marginBottom: '0.5rem',
              fontWeight: 500,
            }}
          >
            {field.label}
          </FormLabel>
          <DatePicker
            views={field.views}
            disabled={disabled}
            value={value}
            onChange={date => {
              if (date) {
                onChange(date as PathValue<TFormData, Path<TFormData>>);
              }
            }}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                sx: {
                  background: 'white',
                },
              },
            }}
            {...field.datePickerProps}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
