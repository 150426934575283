import { useSuspenseQuery } from '@apollo/client';
import type { AgentFeedQuery } from '../../__generated__/graphql';
import { Feed } from '../../components/feed/Feed';
import { AGENT_FEED } from './userQueries';

export const AgentFeed = ({ userId }: { userId: string }) => {
  const { data } = useSuspenseQuery<AgentFeedQuery>(AGENT_FEED, {
    variables: {
      user_id: userId,
    },
  });

  return <Feed activities={data.activities} />;
};
