import { useState } from 'react';
import { gql, useQuery, useMutation, type ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloudDownloadOutlined, DeleteOutline } from '@mui/icons-material';
import { MutationErrorModal } from '../../components/MutationErrorModal';
import { useLocale } from '../../../src/hooks/locale';
import type {
  GetTeamReviewsQuery,
  ScrapeReviewsMutation,
} from '../../__generated__/graphql';
import RatingDisplay from '../../components/RatingDisplay';

const TEAM_REVIEWS = gql`
  query GetTeamReviews($teamId: uuid!) {
    teams_by_pk(id: $teamId) {
      id
      last_review_scraping
      google_place_description
      google_reviews {
        id
        updated_at
        google_timestamp
        rating
        review_text
        original_review_text
        profile_name
        profile_url
        review_url
        profile_image_url
        owner_answer
      }
      google_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
          count
        }
      }
    }
  }
`;

const SCRAPE_REVIEWS = gql`
  mutation ScrapeReviews($teamId: uuid!) {
    scrape_google_reviews(team_id: $teamId) {
      status_message
      status_code
      tasks
    }
  }
`;

const DELETE_REVIEWS = gql`
  mutation DeleteReviews($teamId: uuid!) {
    delete_google_reviews(where: { team_id: { _eq: $teamId } }) {
      affected_rows
    }
  }
`;

export const TeamReviews = () => {
  const { t } = useLocale();
  const { teamId } = useParams();
  const [scrapeError, setScrapeError] = useState<ApolloError | null>(null);
  const [task, setTask] = useState<string | null>(null);

  const { data, error, refetch } = useQuery<GetTeamReviewsQuery>(TEAM_REVIEWS, {
    variables: { teamId },
  });

  const reviews = data?.teams_by_pk?.google_reviews ?? [];

  const [scrapeReviews, { loading: scraping }] =
    useMutation<ScrapeReviewsMutation>(SCRAPE_REVIEWS, {
      onError: e => setScrapeError(e),
      update: (cache, { data }) => {
        cache.modify({
          id: `teams:${teamId}`,
          fields: {
            last_review_scraping: () => new Date().toISOString(),
          },
        });
        setTask(data?.scrape_google_reviews?.tasks[0] ?? null);
      },
      refetchQueries: ['GetTeamReviews'],
    });
  const [deleteReviews] = useMutation(DELETE_REVIEWS);

  if (error) {
    return <div>Error loading reviews: {error.message}</div>;
  }

  const disableButton =
    scraping ||
    (data?.teams_by_pk?.last_review_scraping &&
      new Date(data?.teams_by_pk?.last_review_scraping).getTime() +
        1 * 60 * 1000 >
        Date.now());

  const tooltipTile =
    disableButton && task ? (
      <pre style={{ overflowX: 'scroll' }}>{JSON.stringify(task, null, 2)}</pre>
    ) : null;

  return (
    <>
      {scrapeError && (
        <MutationErrorModal
          error={scrapeError}
          onClose={() => setScrapeError(null)}
        />
      )}
      <Grid container spacing={2} padding={2}>
        <Grid item flexGrow={1}>
          <Typography variant="h5">{t('Google Reviews')}</Typography>
          <Typography variant="subtitle1">
            {data?.teams_by_pk?.google_place_description}
          </Typography>
          <Typography variant="subtitle2">
            {t('Average Rating')}:&nbsp;
            <RatingDisplay
              showRating={false}
              showReviews
              count={
                data?.teams_by_pk?.google_reviews_aggregate?.aggregate?.count
              }
              rating={
                data?.teams_by_pk?.google_reviews_aggregate?.aggregate?.avg
                  ?.rating
              }
            />
          </Typography>
        </Grid>
        <Grid item minWidth={150}>
          <ButtonGroup>
            <Button
              disableElevation
              // Disable for 5 minutes after last scraping
              disabled={disableButton}
              onClick={() => scrapeReviews({ variables: { teamId } })}
            >
              <CloudDownloadOutlined sx={{ ml: -1, mr: 0.75 }} />
              {t('Scrape Reviews')}
            </Button>
            <Button disableElevation disabled={reviews.length === 0}>
              <DeleteOutline
                sx={{ mx: -1 }}
                onClick={() =>
                  deleteReviews({
                    variables: { teamId },
                    refetchQueries: ['GetTeamReviews'],
                  })
                }
              />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {disableButton && (
            <Alert
              severity="info"
              sx={{ mb: 2 }}
              action={<Button onClick={() => refetch()}>Refresh</Button>}
              iconMapping={{
                info: (
                  <Tooltip title={tooltipTile}>
                    {<CloudDownloadOutlined />}
                  </Tooltip>
                ),
              }}
            >
              {t('Scraping reviews. Please come back and refresh in 5min.')}
            </Alert>
          )}
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Reviewed / Scraped')}</TableCell>
                  <TableCell>{t('Reviewer')}</TableCell>
                  <TableCell>{t('Review')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map(review => (
                  <TableRow key={review.id}>
                    <TableCell>
                      {review.google_timestamp}
                      <br />
                      {review.updated_at}
                    </TableCell>
                    <TableCell>
                      <a href={review.profile_url} target="_blank">
                        <Avatar
                          src={review.profile_image_url}
                          alt={review.profile_name}
                        />
                        {review.profile_name}
                      </a>
                    </TableCell>
                    <TableCell>
                      <RatingDisplay
                        rating={review.rating}
                        showAverage={false}
                        readOnly
                      />
                      <p>{review.original_review_text || review.review_text}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
