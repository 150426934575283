import { gql } from '../../__generated__';
import type { PropertyFragmentFragment } from '../../__generated__/graphql';

export type PropertyType = PropertyFragmentFragment['__property_type'];
export type PropertyTypeLight =
  | Pick<NonNullable<PropertyType>, 'main_type' | 'name'>
  | null
  | undefined;

export const PROPERTY_ADDRESS_FRAGMENT = gql(`
  fragment PropertyAddress on properties {
    street_number
    route
    locality
    state
    country
    country_code
    postcode
    lat
    lng
  }
`);

export const PROPERTY_FORM_DETAILS_FRAGMENT = gql(`
  fragment PropertyFormDetails on properties {
    property_type_id
    __property_type: property_type {
      id
      name
      label
      main_type
    }

    # Surfaces
    usable_surface
    living_surface
    gross_floor_surface
    basement_surface
    balcony_surface
    terrace_surface
    land_surface
    garden_surface
    commercial_surface
    residential_surface
    weighted_floor_surface
    built_surface
    building_volume
  
    # Numbers 
    number_of_residential_units
    number_of_commercial_units
    number_of_rooms
    number_of_bedrooms
    number_of_bathrooms
    number_of_toilets
    number_of_floors
    floor_of_flat
    number_of_indoor_parkings
    number_of_outdoor_parkings

    # HVAC
    heating
    heating_distribution
    cooling

    # Building condition
    construction_year
    renovation_year
    building_standard
    building_condition

    # Energy
    minergie_code
    energy_reference_year
    estimated_lower_energy_cost
    estimated_higher_energy_cost
    energy_certificate_version
    energy_inspection_date
    energy_consumption_class
    gas_emissions_class
    emissions
    consumption

    # Misc
    plot_number
    micro_location
    macro_location
    side
    orientation
  }
`);

export const PROPERTY_FRAGMENT = gql(`
  fragment PropertyFragment on properties {
    id
    created_at
    created_by
    ...PropertyFormDetails
    ...PropertyFeatures
    ...PropertyPoi
    ...PropertyFormPricing
    ...PropertyAddress
  }
`);

export const GET_PROPERTY = gql(`
  query getProperty($id: uuid!) {
    properties_by_pk(id: $id) {
      id
      ...PropertyFragment
    }
    property_types {
      id
      name
      label
      main_type
    }
    heating_enum {
      value
    }
    heating_distribution_enum {
      value
    }
    cooling_enum {
      value
    }
  }
`);

export const UPDATE_PROPERTY = gql(`
  mutation updateProperty($id: uuid!, $property: properties_set_input!) {
    update_properties_by_pk(pk_columns: { id: $id }, _set: $property) {
      id
      ...PropertyFragment
    }
  }
`);
