// @flow

import * as React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Avatar, IconButton } from '@material-ui/core';
import { Flex } from 'react-system';
import { Image } from '@realadvisor/image';
// $FlowFixMe[untyped-import]
import { useNavigate } from 'react-router-dom';
import { Person } from '../icons/person';

import type { userAvatar_user$key } from './__generated__/userAvatar_user.graphql';
import { getUserNameUtil } from './user-input';

type Props = {|
  user: ?userAvatar_user$key,
  size?: number,
  customStyle?: Object,
  link?: boolean,
  disableLink?: boolean,
|};

const getInitials = user => {
  let initials = '';
  if (user.firstName != null) {
    initials += user.firstName.charAt(0);
  }
  if (user.lastName != null) {
    initials += user.lastName.charAt(0);
  }
  return initials.length === 0 ? null : initials;
};

const getUserName = user => {
  if (user == null) {
    return '';
  }
  const firstName = user.firstName ?? '';
  const lastName = user.lastName ?? '';
  const email = user.primaryEmail?.email ?? null;
  return getUserNameUtil({ firstName, lastName, email });
};

export const UserAvatar = (props: Props): React.Node => {
  const user = useFragment(
    graphql`
      fragment userAvatar_user on User {
        id
        firstName
        lastName
        primaryImage {
          url
        }
        primaryEmail {
          email
        }
        referrer
        isBroker
        isAdmin
      }
    `,
    props.user,
  );

  const navigate = useNavigate();

  if (user == null) {
    return (
      <IconButton disabled={true}>
        <Avatar>
          <Person />
        </Avatar>
      </IconButton>
    );
  }

  const { size, disableLink, customStyle } = props;
  const { id } = user;

  const alt = getUserName(user);
  const avatar = (
    <Flex>
      <div css={{ position: 'relative', ...customStyle }}>
        {user.primaryImage?.url != null ? (
          <Image
            src={user.primaryImage.url}
            options={{ w: 100, f: 'jpg' }}
            css={{ width: 40, height: 40, borderRadius: '50%' }}
            alt={alt}
          />
        ) : (
          <Avatar
            alt={alt}
            css={size != null ? { width: size, height: size } : {}}
          >
            {getInitials(user) ?? <Person />}
          </Avatar>
        )}
        {user.referrer != null &&
          user.isAdmin !== true &&
          user.isBroker !== true && (
            <Avatar
              css={{
                position: 'absolute',
                right: 0,
                bottom: -4,
                width: 16,
                height: 16,
                backgroundColor: 'white',
              }}
              src={`https://www.google.com/s2/favicons?domain=${user.referrer}`}
            />
          )}
      </div>
    </Flex>
  );

  return disableLink === true ? (
    avatar
  ) : (
    <IconButton
      css={customStyle}
      onClick={event => {
        event.preventDefault();
        if (props.link !== false) {
          navigate(`/users/${id}`);
        }
      }}
    >
      {avatar}
    </IconButton>
  );
};
