import * as React from 'react';
import { graphql, useMutation } from 'react-relay';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { FilledInput, Button } from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { Box } from 'react-system';
import { prepareCodeChallenge } from '@realadvisor/auth-client';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import type { LoginEmailFormMutation } from './__generated__/LoginEmailFormMutation.graphql';

export const LoginEmailForm = ({ tenantId }: { tenantId: string | null }) => {
  const { t } = useLocale();
  const { colors } = useTheme();
  const [requestAuthLink, requesting] = useMutation<LoginEmailFormMutation>(
    graphql`
      mutation LoginEmailFormMutation($input: RequestAuthLinkInput!) {
        requestAuthLink(input: $input) {
          errors {
            email
          }
        }
      }
    `,
  );
  const [successMessage, setSuccessMessage] = React.useState<null | string>(
    null,
  );
  const form = useFormik({
    initialValues: {
      email: '',
    },
    validate: () => ({}),
    onSubmit: values => {
      const searchParams = new URLSearchParams(location.search);
      const redirect_back_uri = searchParams.get('return') ?? location.origin;
      const redirect_url = new URL('/login/email', location.origin);
      redirect_url.searchParams.set('redirect_uri', redirect_back_uri);
      if (tenantId != null) {
        redirect_url.searchParams.set('tenant_id', tenantId);
      }
      prepareCodeChallenge().then(code_challenge => {
        requestAuthLink({
          variables: {
            input: {
              email: values.email,
              code_challenge,
              redirect_uri: redirect_url.toString(),
            },
          },
          onCompleted: payload => {
            const error = payload.requestAuthLink?.errors?.email;
            if (error == null) {
              setSuccessMessage(t('authLinkSentTo', { email: values.email }));
            } else {
              form.setResponseErrors({ email: error });
            }
          },
        });
      });
    },
  });
  return (
    <Form onSubmit={form.submitForm}>
      <Box py={1}>
        <FormControl error={form.errors.email != null}>
          <InputLabel>Email</InputLabel>
          <FilledInput
            type="email"
            value={form.values.email}
            onChange={event => {
              form.setValues({ email: event.target.value });
              setSuccessMessage(null);
            }}
          />
          {form.errors.email != null && (
            <FormHelperText>{form.errors.email}</FormHelperText>
          )}
          {successMessage != null && (
            <FormHelperText css={{ color: colors.successText }}>
              {successMessage}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box py={1}>
        <Button
          css={{ textAlign: 'center' }}
          variant="contained"
          color="secondary"
          disabled={requesting}
          onClick={form.submitForm}
        >
          {t('getAuthenticationLink')}
        </Button>
      </Box>
    </Form>
  );
};
