import { useSuspenseQuery } from '@apollo/client';

import { useMemo } from 'react';
import type {
  LeadFeedQuery,
  ActivityFieldsFragment,
} from '../../__generated__/graphql';
import { Feed } from '../../components/feed/Feed';
import { LEAD_FEED } from './leadsQueries';

interface LeadFeedProps {
  contact_id: string;
  email_addresses?: string[];
}

export const LeadFeed = ({ contact_id, email_addresses }: LeadFeedProps) => {
  const skip_email_activities = !email_addresses?.length;

  const { data } = useSuspenseQuery<LeadFeedQuery>(LEAD_FEED, {
    variables: { user_id: contact_id, email_addresses, skip_email_activities },
  });

  const activities: ActivityFieldsFragment[] = useMemo(
    () =>
      [
        ...(data?.email_activities ?? []),
        ...(data?.lead_activites ?? []),
        ...(data?.user_activities ?? []),
      ].sort((a, b) => {
        if (a?.created_at > b?.created_at) {
          return -1;
        }
        if (a?.created_at < b?.created_at) {
          return 1;
        }
        return 0;
      }),
    [data],
  );

  return <Feed activities={activities} />;
};
