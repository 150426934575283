import { type ErrorOption, type FieldPath } from 'react-hook-form';
import {
  BALCONY_SURFACE_MAX,
  BALCONY_SURFACE_MIN,
  BASEMENT_SURFACE_MAX,
  BASEMENT_SURFACE_MIN,
  BUILT_SURFACE_MAX,
  BUILT_SURFACE_MIN,
  ENERGY_CONSUMPTION_MAX,
  ENERGY_CONSUMPTION_MIN,
  ESTIMATED_COST_MAX,
  ESTIMATED_COST_MIN,
  GARDEN_SURFACE_MAX,
  GARDEN_SURFACE_MIN,
  GROSS_FLOOR_SURFACE_MAX,
  GROSS_FLOOR_SURFACE_MIN,
  LAND_SURFACE_MAX,
  LAND_SURFACE_MIN,
  LIVING_SURFACE_MAX,
  LIVING_SURFACE_MIN,
  RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
  TERRACE_SURFACE_MAX,
  TERRACE_SURFACE_MIN,
  USABLE_SURFACE_MAX,
  USABLE_SURFACE_MIN,
  WEIGHTED_FLOOR_SURFACE_MAX,
  WEIGHTED_FLOOR_SURFACE_MIN,
} from '../../../../src/components/PropertyForm';
import { type Translate } from '../../../../src/hooks/locale';
import { getKeys } from '../../../utils/objects';
import { numberContainsDecimals, outOfRange } from '../../../utils/validation';
import {
  propertyDetailsConditionalFields,
  type PropertyFormDetailsData,
} from './propertyDetailsFormDefinition';

type SurfaceFields = Pick<
  PropertyFormDetailsData,
  | 'living_surface'
  | 'built_surface'
  | 'usable_surface'
  | 'residential_surface'
  | 'commercial_surface'
  | 'land_surface'
  | 'balcony_surface'
  | 'garden_surface'
  | 'basement_surface'
  | 'terrace_surface'
  | 'weighted_floor_surface'
  | 'gross_floor_surface'
>;
const validateSurfaces = (t: Translate, values: SurfaceFields) => {
  const errors: [FieldPath<SurfaceFields>, ErrorOption][] = [];

  if (
    outOfRange(
      values.living_surface,
      LIVING_SURFACE_MIN,
      LIVING_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'living_surface',
      {
        message: t('livingSurfaceMustBeInRange', {
          min: LIVING_SURFACE_MIN,
          max: LIVING_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(values.built_surface, BUILT_SURFACE_MIN, BUILT_SURFACE_MAX, true)
  ) {
    errors.push([
      'built_surface',
      {
        message: t('builtSurfaceMustBeInRange', {
          min: BUILT_SURFACE_MIN,
          max: BUILT_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.usable_surface,
      USABLE_SURFACE_MIN,
      USABLE_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'usable_surface',
      {
        message: t('usableSurfaceMustBeInRange', {
          min: USABLE_SURFACE_MIN,
          max: USABLE_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.residential_surface,
      RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      LAND_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'residential_surface',
      {
        message: t('residentialSurfaceMustBeInRange', {
          min: RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
          max: LAND_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.commercial_surface,
      RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      LAND_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'commercial_surface',
      {
        message: t('commercialSurfaceMustBeInRange', {
          min: RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
          max: LAND_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(values.land_surface, LAND_SURFACE_MIN, LAND_SURFACE_MAX, true)
  ) {
    errors.push([
      'land_surface',
      {
        message: t('landSurfaceMustBeInRange', {
          min: LAND_SURFACE_MIN,
          max: LAND_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.balcony_surface,
      BALCONY_SURFACE_MIN,
      BALCONY_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'balcony_surface',
      {
        message: t('balconySurfaceMustBeInRange', {
          min: BALCONY_SURFACE_MIN,
          max: BALCONY_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.garden_surface,
      GARDEN_SURFACE_MIN,
      GARDEN_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'balcony_surface',
      {
        message: t('gardenSurfaceMustBeInRange', {
          min: GARDEN_SURFACE_MIN,
          max: GARDEN_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.basement_surface,
      BASEMENT_SURFACE_MIN,
      BASEMENT_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'basement_surface',
      {
        message: t('basementSurfaceMustBeInRange', {
          min: BASEMENT_SURFACE_MIN,
          max: BASEMENT_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.terrace_surface,
      TERRACE_SURFACE_MIN,
      TERRACE_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'terrace_surface',
      {
        message: t('terraceSurfaceMustBeInRange', {
          min: TERRACE_SURFACE_MIN,
          max: TERRACE_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.weighted_floor_surface,
      WEIGHTED_FLOOR_SURFACE_MIN,
      WEIGHTED_FLOOR_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'weighted_floor_surface',
      {
        message: t('weightedFloorSurfaceMustBeInRange', {
          min: WEIGHTED_FLOOR_SURFACE_MIN,
          max: WEIGHTED_FLOOR_SURFACE_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.gross_floor_surface,
      GROSS_FLOOR_SURFACE_MIN,
      GROSS_FLOOR_SURFACE_MAX,
      true,
    )
  ) {
    errors.push([
      'gross_floor_surface',
      {
        message: t('grossFloorSurfaceMustBeInRange', {
          min: GROSS_FLOOR_SURFACE_MIN,
          max: GROSS_FLOOR_SURFACE_MAX,
        }),
      },
    ]);
  }

  return errors;
};

type RoomsFields = Pick<
  PropertyFormDetailsData,
  | '__main_type'
  | 'number_of_rooms'
  | 'number_of_bedrooms'
  | 'number_of_bathrooms'
  | 'number_of_toilets'
>;

const validateRooms = (t: Translate, values: RoomsFields) => {
  const errors: [FieldPath<RoomsFields>, ErrorOption][] = [];
  const MIN_ROOMS = values.__main_type === 'PROP' ? 0 : 1;

  if (values.number_of_rooms != null) {
    if (outOfRange(values.number_of_rooms, MIN_ROOMS, 50)) {
      errors.push([
        'number_of_rooms',
        {
          message: t('numberOfRoomsMustBeInRange', {
            min: MIN_ROOMS,
            max: 50,
          }),
        },
      ]);
    } else if (!numberContainsDecimals(values.number_of_rooms, [0, 5])) {
      errors.push([
        'number_of_rooms',
        {
          message: t('The number of rooms must be a 0.5 increment'),
        },
      ]);
    }
  }

  if (outOfRange(values.number_of_bedrooms, 0, 50, true)) {
    errors.push([
      'number_of_bedrooms',
      {
        message: t('numberOfBedroomsMustBeInRange', {
          min: 0,
          max: 50,
        }),
      },
    ]);
  }

  if (outOfRange(values.number_of_bathrooms, 0, 20, true)) {
    errors.push([
      'number_of_bedrooms',
      {
        message: t('numberOfBathroomsMustBeInRange', {
          min: 0,
          max: 20,
        }),
      },
    ]);
  }

  if (outOfRange(values.number_of_toilets, 0, 30, true)) {
    errors.push([
      'number_of_toilets',
      {
        message: t('numberOfToiletsMustBeInRange', {
          min: 0,
          max: 30,
        }),
      },
    ]);
  }

  return errors;
};

type EnergyFields = Pick<
  PropertyFormDetailsData,
  | 'consumption'
  | 'emissions'
  | 'estimated_lower_energy_cost'
  | 'estimated_higher_energy_cost'
>;

const validateEnergyFields = (t: Translate, values: EnergyFields) => {
  const errors: [FieldPath<EnergyFields>, ErrorOption][] = [];

  if (
    outOfRange(
      values.consumption,
      ENERGY_CONSUMPTION_MIN,
      ENERGY_CONSUMPTION_MAX,
      true,
    )
  ) {
    errors.push([
      'consumption',
      {
        message: t('consumptionBetweenError', {
          min: ENERGY_CONSUMPTION_MIN,
          max: ENERGY_CONSUMPTION_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.emissions,
      ENERGY_CONSUMPTION_MIN,
      ENERGY_CONSUMPTION_MAX,
      true,
    )
  ) {
    errors.push([
      'emissions',
      {
        message: t('emissionsBetweenError', {
          min: ENERGY_CONSUMPTION_MIN,
          max: ENERGY_CONSUMPTION_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.estimated_lower_energy_cost,
      ESTIMATED_COST_MIN,
      ESTIMATED_COST_MAX,
      true,
    )
  ) {
    errors.push([
      'estimated_lower_energy_cost',
      {
        message: t('minEstimatedBetweenError', {
          min: ESTIMATED_COST_MIN,
          max: ESTIMATED_COST_MAX,
        }),
      },
    ]);
  }

  if (
    outOfRange(
      values.estimated_higher_energy_cost,
      ESTIMATED_COST_MIN,
      ESTIMATED_COST_MAX,
      true,
    )
  ) {
    errors.push([
      'estimated_higher_energy_cost',
      {
        message: t('maxEstimatedBetweenError', {
          min: ESTIMATED_COST_MIN,
          max: ESTIMATED_COST_MAX,
        }),
      },
    ]);
  }

  if (
    values.estimated_lower_energy_cost != null &&
    values.estimated_higher_energy_cost != null &&
    values.estimated_lower_energy_cost > values.estimated_higher_energy_cost
  ) {
    errors.push(
      [
        'estimated_higher_energy_cost',
        { message: t('minEstimatedCostCannotExceedMaxError') },
      ],
      [
        'estimated_lower_energy_cost',
        { message: t('minEstimatedCostCannotExceedMaxError') },
      ],
    );
  }

  return errors;
};

export const getValidatePropertyDetailsFormData =
  (t: Translate) =>
  (
    data: PropertyFormDetailsData,
  ): [FieldPath<PropertyFormDetailsData>, ErrorOption][] => {
    const errors: [FieldPath<PropertyFormDetailsData>, ErrorOption][] = [];
    const values = getKeys(data).reduce(
      (acc: PropertyFormDetailsData, key): PropertyFormDetailsData => {
        const value = data[key] as any;
        acc[key] = value;

        const renderFn = propertyDetailsConditionalFields.get(key);
        if (renderFn && !renderFn(data)) {
          // We unset the value if the field is not rendered.
          acc[key] = null;
        }

        return acc;
      },
      {},
    );

    errors.push(...validateSurfaces(t, values));

    const currentYear = new Date().getFullYear();

    if (outOfRange(values.construction_year, 1000, currentYear + 3, true)) {
      errors.push([
        'construction_year',
        {
          message: t('constructionYearMustBeInRange', {
            min: 1000,
            max: currentYear + 3,
          }),
        },
      ]);
    }

    if (outOfRange(values.renovation_year, 1000, currentYear, true)) {
      errors.push([
        'renovation_year',
        {
          message: t('renovationYearMustBeInRange', {
            min: 1000,
            max: currentYear,
          }),
        },
      ]);
    }

    if (
      values.renovation_year != null &&
      values.construction_year != null &&
      values.renovation_year < values.construction_year
    ) {
      errors.push([
        'renovation_year',
        {
          message: t('renovationYearCannotBeBeforeConstructionYear'),
        },
      ]);
    }

    if (outOfRange(values.number_of_residential_units, 1, 500, true)) {
      errors.push([
        'number_of_residential_units',
        {
          message: t('numberOfResidentialUnitsMustBeInRange', {
            min: 1,
            max: 500,
          }),
        },
      ]);
    }

    if (outOfRange(values.number_of_commercial_units, 1, 500, true)) {
      errors.push([
        'number_of_commercial_units',
        {
          message: t('numberOfCommercialUnitsMustBeInRange', {
            min: 1,
            max: 500,
          }),
        },
      ]);
    }

    errors.push(...validateRooms(t, values));

    if (outOfRange(values.micro_location, 1, 5, true)) {
      errors.push([
        'micro_location',
        { message: t('microLocationMustBeInRange', { min: 1, max: 5 }) },
      ]);
    }

    if (outOfRange(values.macro_location, 1, 5, true)) {
      errors.push([
        'micro_location',
        { message: t('macroLocationMustBeInRange', { min: 1, max: 5 }) },
      ]);
    }

    if (outOfRange(values.building_volume, 40, 20000, true)) {
      errors.push([
        'building_volume',
        { message: t('buildingVolumeMustBeInRange', { min: 40, max: 20000 }) },
      ]);
    }

    errors.push(...validateEnergyFields(t, values));

    if (values.__main_type != null && values.property_type_id == null) {
      errors.push(['property_type_id', { message: t('typeIsRequired') }]);
    }

    if (
      values.number_of_floors != null &&
      values.floor_of_flat != null &&
      values.floor_of_flat > values.number_of_floors
    ) {
      errors.push([
        'floor_of_flat',
        { message: t('Floor of flat cannot be greater than number of floors') },
      ]);
    }

    return errors;
  };
