import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { Link, Route, Routes, useSearchParams } from 'react-router-dom';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import type {
  GridColDef,
  GridRowsProp,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import { TimeAgo } from '../../components/TimeAgo';
import { useLocale } from '../../../src/hooks/locale';
import { UserSelect } from '../../components/user-select/UserSelect';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { UserDrawer } from '../users/UserDrawer';
import type {
  OverviewGetTeamQuery,
  DeleteTeamUserMutation,
} from '../../__generated__/graphql';
import RatingDisplay from '../../components/RatingDisplay';
import {
  DELETE_TEAM_USER,
  OVERVIEW_GET_TEAM,
  ADD_TEAM_USER,
  UPDATE_TEAM_USER,
  REORDER_TEAM_USERS,
} from './teamQueries';

type TeamUser = NonNullable<
  OverviewGetTeamQuery['teams_by_pk']
>['teams_users'][0];

interface TeamUsersProps {
  team: OverviewGetTeamQuery['teams_by_pk'];
  loading?: boolean;
}

export const TeamUsers = ({ team, loading }: TeamUsersProps) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();

  const refetchQueries = [
    {
      query: OVERVIEW_GET_TEAM,
      variables: { id: team?.id, includeSubscriptions: false },
    },
  ];

  const [deleteTeamUser] =
    useMutation<DeleteTeamUserMutation>(DELETE_TEAM_USER);
  const [addTeamUser] = useMutation(ADD_TEAM_USER, {
    refetchQueries,
  });
  const [updateTeamUser] = useMutation(UPDATE_TEAM_USER, {
    refetchQueries,
  });

  const [updateTeamUserOrder] = useMutation(REORDER_TEAM_USERS, {
    refetchQueries,
  });

  const rows: GridRowsProp<TeamUser> = team?.teams_users ?? [];

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    await updateTeamUserOrder({
      variables: {
        team_id: team?.id,
        user_id: rows[params.oldIndex].user.id,
        target_position: params.targetIndex,
      },
    });
  };

  const columns: GridColDef<TeamUser>[] = [
    {
      field: 'user',
      headerName: t('User'),
      width: 200,
      renderCell: params => (
        <Link
          to={{
            pathname: `users/${params.row.user.id}`,
            search: searchParams.toString(),
          }}
        >
          <UserInfo user={params.row.user} />
        </Link>
      ),
      sortable: false,
    },
    {
      field: 'title',
      headerName: t('Title'),
      width: 180,
      editable: true,
      sortable: false,
    },
    {
      field: 'reviews',
      headerName: t('Reviews'),
      width: 150,
      renderCell: ({ row: { user } }) =>
        (user.reviews_aggregate.aggregate?.count ?? 0) > 0 && (
          <Box sx={{ gap: 1, alignItems: 'center', display: 'flex' }}>
            <RatingDisplay
              readOnly
              precision={0.1}
              rating={user.reviews_aggregate?.aggregate?.avg?.rating}
              count={user.reviews_aggregate?.aggregate?.count}
              size="small"
            />
          </Box>
        ),
    },
    {
      field: 'added',
      headerName: t('Added'),
      renderCell: params => <TimeAgo dateString={params.row.created_at} />,
      sortable: false,
    },
    {
      field: 'leads',
      headerName: t('Leads'),
      renderCell: params =>
        params.row.user.broker_leads_aggregate.aggregate?.count,
      sortable: false,
    },
    {
      field: 'lots',
      headerName: t('Lots'),
      renderCell: params => params.row.user.lots_aggregate.aggregate?.count,
      sortable: false,
    },
    {
      field: 'permissions',
      headerName: t('Permissions'),
      renderCell: params =>
        [
          params.row.user.is_admin ? 'Admin' : undefined,
          params.row.user.is_broker ? 'Broker' : undefined,
        ]
          .filter(Boolean)
          .join(', '),
      sortable: false,
    },
    {
      field: 'status',
      width: 80,
      headerName: t('Status'),
      getOptionValue: (value: any) => value.code,
      getOptionLabel: (value: any) => value.name,
      valueOptions: [
        { code: 'active', name: 'Active' },
        { code: 'deactivated', name: 'Deactivated' },
      ],
      valueGetter: (_, row) => (row.deactivated ? 'deactivated' : 'active'),
      valueSetter: (value, row) => {
        return { ...row, deactivated: value === 'deactivated' };
      },
      type: 'singleSelect',
      sortable: false,
      editable: true,
    },
    {
      field: 'team_role',
      headerName: t('Team Role'),
      width: 80,
      getOptionValue: (value: any) => value.code,
      getOptionLabel: (value: any) => value.name,
      valueOptions: [
        { code: 'leader', name: 'Leader' },
        { code: 'member', name: 'Member' },
      ],
      valueGetter: (_, row) => (row.is_leader ? 'leader' : 'member'),
      valueSetter: (value, row) => {
        return { ...row, is_leader: value === 'leader' };
      },
      type: 'singleSelect',
      sortable: false,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() =>
            deleteTeamUser({
              variables: {
                team_id: team?.id,
                user_id: params.row.user.id,
              },
              refetchQueries,
            })
          }
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ mb: 1, maxWidth: 300 }}>
        <UserSelect
          onChange={userId =>
            addTeamUser({
              variables: {
                user_id: userId,
                team_id: team?.id,
              },
            })
          }
          InputProps={{
            placeholder: t('Add user'),
          }}
        />
      </Box>
      <DataGridPro
        autoHeight
        rows={rows}
        columns={columns}
        loading={loading}
        rowReordering={true}
        onRowOrderChange={newModel => handleRowOrderChange(newModel)}
        getRowId={row => row.user.id}
        rowHeight={64}
        sx={{
          backgroundColor: '#fff',
          borderRadius: 4,
          '& .MuiDataGrid-main': {
            borderRadius: 4,
          },
        }}
        processRowUpdate={(newModel, oldModel) => {
          // update title, is_leader or deactivated has changed
          if (
            newModel.title !== oldModel.title ||
            newModel.is_leader !== oldModel.is_leader ||
            newModel.deactivated !== oldModel.deactivated
          ) {
            updateTeamUser({
              variables: {
                team_id: team?.id,
                user_id: newModel.user.id,
                team_user: {
                  title: newModel.title,
                  is_leader: newModel.is_leader,
                  deactivated: newModel.deactivated,
                },
              },
            });
          }
          return newModel;
        }}
      />

      <Routes>
        <Route
          path="users/:userId/*"
          element={<UserDrawer maxWidth={1200} />}
        />
      </Routes>
    </>
  );
};
