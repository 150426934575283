import { forwardRef, type Ref } from 'react';
import { useRifm } from 'rifm';
import { TextField, type TextFieldProps } from '@mui/material';
import {
  type CountryCode,
  getCountryCallingCode,
  formatIncompletePhoneNumber,
} from 'libphonenumber-js';

export const getInitialPhonePrefix = (countryCode: null | string): string => {
  return `+${getCountryCallingCode((countryCode as CountryCode) ?? 'CH')}`;
};

type Props = {
  onChange: (value: string) => void;
};

export const formatPhoneNumber = (str: string): string => {
  const clean = str?.replace(/[^\d]+/gi, '').slice(0, 16);
  const formatted = formatIncompletePhoneNumber(`+${clean}`);
  return formatted;
};

export const PhoneInput = forwardRef<HTMLInputElement, Props & TextFieldProps>(
  (props, ref: Ref<HTMLInputElement>) => {
    const rifm = useRifm({
      accept: /[\d+]+/g,
      format: formatPhoneNumber,
      value: formatPhoneNumber((props?.value || props.defaultValue) as string),
      onChange: v => props?.onChange(v === '+' ? '' : v),
    });

    return (
      <TextField
        {...props}
        ref={ref}
        type="tel"
        autoComplete="tel"
        onBlur={props.onBlur}
        value={rifm.value}
        onChange={rifm.onChange}
      />
    );
  },
);
