import { useMutation, useQuery } from '@apollo/client';
import { Alert, LinearProgress, Snackbar } from '@mui/material';
import { useMemo, useState } from 'react';
import { useLocale } from '../../../src/hooks/locale';
import { type GetListingEditContextQuery } from '../../__generated__/graphql';
import {
  GET_LISTING_EDIT_CONTEXT,
  UPDATE_LISTING_WITH_PROPERTY,
} from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { RaForm } from '../form/RaForm';
import {
  getDefaultPropertyDetailsFormValues,
  getPropertyDetailsFormDefinition,
  preparePropertyDetailsFormData,
  type PropertyFormDetailsData,
} from '../property-form/forms-definitions/propertyDetailsFormDefinition';
import { getValidatePropertyDetailsFormData } from '../property-form/forms-definitions/propertyDetailsFormValidation';
import { PropertyTypeProvider } from '../property-form/PropertyTypeProvider';
import { type ListingCreationStepComponent } from './CreateListingStepper';

export type PropertyDetailsStepForm = Omit<
  PropertyFormDetailsData,
  'property_type_id' | '__main_type'
>;

export const PropertyDetailsStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const { t, locale, countryCode } = useLocale();
  const { me } = useAppData();
  const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);

  const { data: context } = useQuery<GetListingEditContextQuery>(
    GET_LISTING_EDIT_CONTEXT,
  );

  const [update] = useMutation(UPDATE_LISTING_WITH_PROPERTY, {
    onError: () => setSnackBarDisplayed(true),
  });

  const isCHTenant = me?.tenant.country_code === 'CH';

  const formDefinition = useMemo(
    () =>
      listing == null
        ? null
        : getPropertyDetailsFormDefinition(
            isCHTenant,
            listing.currency,
            listing.property.country_code ?? countryCode,
            locale,
          ),
    [isCHTenant, locale, countryCode, listing],
  );

  const defaultValues = useMemo(
    () =>
      listing == null
        ? {}
        : getDefaultPropertyDetailsFormValues(listing.property),
    [listing],
  );

  if (listing == null || formDefinition == null) {
    return null;
  }

  if (context == null) {
    return <LinearProgress />;
  }

  const formContext = {
    propertyTypes: context.property_types,
    heatingTypes: context.heating_enum,
    heatingDistribution: context.heating_distribution_enum,
    coolingTypes: context.cooling_enum,
  };

  return (
    <>
      <RaForm
        formDefinition={formDefinition}
        onSubmit={(_, data) => {
          const property = preparePropertyDetailsFormData(data, isCHTenant);
          // We don't await to have a fast transition.
          update({
            variables: {
              id: listing.id,
              prop_id: listing.property.id,
              lot: {},
              property,
            },
          });

          return onFormSubmitted({ action: 'update' }, false, true);
        }}
        defaultValues={defaultValues}
        validate={getValidatePropertyDetailsFormData(t)}
        context={formContext}
        actionButtonsComponent={footerActionsComponent}
        contentScrollable
      >
        <PropertyTypeProvider propertyTypes={formContext.propertyTypes} />
      </RaForm>
      <Snackbar
        open={snackBarDisplayed}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnackBarDisplayed(false)}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {t('An error occured while updating the listing')}
        </Alert>
      </Snackbar>
    </>
  );
};
