import type { Translate } from '../../src/hooks/locale';

// TODO: Convert all enums to Hasura enums.
export enum EXPECTED_TIME_TO_SELL {
  NOT_SET = 'not_set',
  UNDER_3_MONTH = 'under_3_month',
  UNDER_6_MONTH = 'under_6_month',
  UNDER_12_MONTH = 'under_12_month',
  SHOWCASE_LISTING = 'showcase_listing',
}

export enum MANDATE_TYPE {
  COMMISSION_BASED = 'commission_based',
  FIXED_FEE = 'fixed_fee',
}

export enum LISTING_ORIGIN {
  SOURCED_BY_BROKER = 'sourced_by_broker',
  FSBO = 'fsbo',
  REALADVISOR_APPRAISAL = 'realadvisor_appraisal',
}

export enum SUCCESS_FEE_TYPE {
  CUSTOM = 'custom',
  PERCENTAGE_OF_PROCEEDS_ABOVE_SALE_PRICE = 'percentage_of_proceeds_above_sale_price',
}

export const getExpectedTimeToSellLabel = (
  expectedTimeToSell: string | null,
  t: Translate,
) => {
  switch (expectedTimeToSell) {
    case EXPECTED_TIME_TO_SELL.UNDER_3_MONTH:
      return t('Sell in 0-3 months');
    case EXPECTED_TIME_TO_SELL.UNDER_6_MONTH:
      return t('Sell in 3-6 months');
    case EXPECTED_TIME_TO_SELL.UNDER_12_MONTH:
      return t('Sell in 6-12 months');
    case EXPECTED_TIME_TO_SELL.SHOWCASE_LISTING:
      return t('Showcase listing');
    default:
      return null;
  }
};

export const getMandateTypeLabel = (
  mandateType: string | null,
  t: Translate,
) => {
  switch (mandateType) {
    case MANDATE_TYPE.COMMISSION_BASED:
      return t('Commission based');
    case MANDATE_TYPE.FIXED_FEE:
      return t('Fixed fee');
    default:
      return null;
  }
};

export const getListingOriginLabel = (origin: string | null, t: Translate) => {
  switch (origin) {
    case LISTING_ORIGIN.FSBO:
      return t('For sale by owner');
    case LISTING_ORIGIN.REALADVISOR_APPRAISAL:
      return t('RealAdvisor appraisal');
    case LISTING_ORIGIN.SOURCED_BY_BROKER:
      return t('Sourced by broker');
    default:
      return null;
  }
};

export const getSuccessFeeTypeLabel = (
  successFee: string | null,
  t: Translate,
) => {
  switch (successFee) {
    case SUCCESS_FEE_TYPE.CUSTOM:
      return t('Custom');
    case SUCCESS_FEE_TYPE.PERCENTAGE_OF_PROCEEDS_ABOVE_SALE_PRICE:
      return t('Percentage of proceeds above sale price');
    default:
      return null;
  }
};

export const getMandateTypeList = (t: Translate) =>
  Object.values(MANDATE_TYPE).map(value => ({
    value,
    label: getMandateTypeLabel(value, t) ?? value,
  }));

export const getExpectedTimeToSellList = (t: Translate) =>
  Object.values(EXPECTED_TIME_TO_SELL).map(value => ({
    value,
    label:
      value === EXPECTED_TIME_TO_SELL.NOT_SET
        ? ''
        : getExpectedTimeToSellLabel(value, t) ?? value,
  }));

export const getListingOriginList = (t: Translate) =>
  Object.values(LISTING_ORIGIN).map(value => ({
    value,
    label: getListingOriginLabel(value, t) ?? value,
  }));

export const getSuccessFeeTypeList = (t: Translate) =>
  Object.values(SUCCESS_FEE_TYPE).map(value => ({
    value,
    label: getSuccessFeeTypeLabel(value, t) ?? value,
  }));
