import { Box, Typography } from '@mui/material';
import { gql } from '../../__generated__';
import { type ListingPriceCellFragment } from '../../__generated__/graphql';

export const LISTING_PRICE_CELL_FRAGMENT = gql(`
  fragment ListingPriceCell on lots {
    sale_price
    rent_net
    property {
      latest_appraisal {
        value
      }
    }
  }
`);

export const ListingPriceCell: React.FC<{ row: ListingPriceCellFragment }> = ({
  row,
}) => {
  const price = row.sale_price ?? row.rent_net;
  const appraisal = row.property?.latest_appraisal?.value;
  if (!price) {
    return null;
  }
  if (!appraisal) {
    return (
      <Typography variant="body2" fontWeight={500}>
        {price.toLocaleString()}
      </Typography>
    );
  }
  const diff = typeof price === 'number' ? (price / appraisal - 1) * 100 : 0;
  const color =
    diff > 0 ? 'error.main' : diff < 0 ? 'success.main' : 'text.disabled';
  return (
    <Box>
      <Typography variant="body2" lineHeight="18px" fontWeight={500}>
        {price.toLocaleString()}
      </Typography>
      <Typography
        variant="caption"
        display="block"
        textAlign="right"
        color={color}
        lineHeight="18px"
      >
        {diff.toFixed(2)}%
      </Typography>
      <Typography
        variant="caption"
        textAlign="right"
        lineHeight="18px"
        sx={{ display: 'block', color: 'grey.500' }}
      >
        {appraisal.toLocaleString()}
      </Typography>
    </Box>
  );
};
