import type { ApolloError } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';

interface MutationErrorModalProps {
  error: ApolloError | null;
  onClose: () => void;
}

export const MutationErrorModal = ({
  error,
  onClose,
}: MutationErrorModalProps) => (
  <Dialog
    open={!!error}
    onClose={onClose}
    scroll="paper"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      Error: {error?.message}
    </DialogTitle>
    <DialogContent dividers>
      {error?.graphQLErrors.map((error, i) => (
        <>
          {Array.isArray(error.extensions) &&
            error.extensions.map((ext, j) => (
              <Alert severity="error" key={`${i}-${j}`} sx={{ mb: 1 }}>
                <AlertTitle>{ext.path.join('.')}</AlertTitle>
                {ext.message}
              </Alert>
            ))}
        </>
      ))}
      <Alert severity="error">
        <AlertTitle>{error?.message}</AlertTitle>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export const MutationErrorSnackbar = ({
  error,
  onClose,
}: MutationErrorModalProps) => (
  <Snackbar
    open={!!error}
    autoHideDuration={6000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <Alert
      onClose={onClose}
      severity="error"
      variant="filled"
      sx={{ width: '100%', alignItems: 'center' }}
    >
      <AlertTitle>{error?.message}</AlertTitle>
      {error?.graphQLErrors.map((error, i) => (
        <ul key={i}>
          {Array.isArray(error.extensions) &&
            error.extensions.map((ext, j) => (
              <li key={`${i}-${j}`} css={{ mb: 8 }}>
                <strong>{ext.path.join('.')}</strong>
                {ext.message}
              </li>
            ))}
        </ul>
      ))}
    </Alert>
  </Snackbar>
);
