import { useEffect, useState } from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import type { DrawerProps as MuiDrawerProps } from '@mui/material';
import { useTheme } from '../../../src/hooks/theme';
import type { Actions } from '../actions-menu/ActionsMenu';
import type { DrawerTab } from './DrawerTopBar';
import { DrawerTopBar } from './DrawerTopBar';

export type DrawerProps = {
  children: React.ReactNode;
  onClose: () => void;
  beforeClose?: () => boolean;
  PaperProps?: MuiDrawerProps['PaperProps'];
  title?: React.ReactNode;
  actions?: Actions[];
  tabs?: DrawerTab[];
  currentTab?: string;
  maxWidth?: number;
} & Omit<MuiDrawerProps, 'title'>;

export const Drawer = ({
  children,
  onClose,
  beforeClose = () => true,
  PaperProps,
  title,
  actions,
  tabs,
  currentTab,
  maxWidth = 1400,
  ...props
}: DrawerProps) => {
  const [open, setOpen] = useState(false);
  // Render Drawer animation and set Tab activate
  useEffect(() => setOpen(true), []);

  const handleClose = () => {
    if (beforeClose()) {
      setOpen(false);
      setTimeout(onClose, 300);
    }
  };

  const { sideBarWidth, colors } = useTheme();
  const { sx, ...restPaperProps } = PaperProps ?? {};

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: [
            '100%',
            '100%',
            '100%',
            `calc(100% - ${sideBarWidth}px)`,
            maxWidth,
          ],
          bgcolor: colors.superLightBlue,
          ...sx,
        },
        ...restPaperProps,
      }}
      {...props}
    >
      {(title || actions) && (
        <DrawerTopBar
          title={title}
          actions={actions}
          onClose={handleClose}
          tabs={tabs}
          currentTab={currentTab}
        />
      )}
      {children}
    </MuiDrawer>
  );
};
