import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PersonOutlineOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  debounce,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import type {
  GetUsersByIdsQuery,
  UserFilterFragment,
  UserFilterSearchUsersQuery,
} from '../../../__generated__/graphql';
import { UserAvatar, USER_AVATAR_FRAGMENT } from '../../UserAvatar';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';
import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import { AutocompletePaper, AutocompleteTextField } from './QuickFilters';

const USER_FILTER_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  fragment UserFilter on users {
    ...UserAvatar
    id
    full_name
    primaryEmail: emails(
      order_by: { primary: desc, verified: desc }
      limit: 1
    ) {
      email
    }
  }
`;

const SEARCH_USERS = gql`
  ${USER_FILTER_FRAGMENT}
  query UserFilterSearchUsers($q: String!) {
    search_users_tsv(
      args: { search_text: $q }
      order_by: { is_broker: desc }
      limit: 10
    ) {
      id
      ...UserFilter
    }
  }
`;

const GET_DEFAULT_USERS = gql`
  ${USER_FILTER_FRAGMENT}
  query GetUsersByIds($ids: [uuid!]!) {
    users(where: { id: { _in: $ids } }) {
      id
      ...UserFilter
    }
  }
`;

interface UserMultiSelectProps {
  onClose?: () => void;
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const UserMultiSelect = ({
  onChange,
  onClose,
  defaultValue,
}: UserMultiSelectProps) => {
  const [options, setOptions] = useState<UserFilterFragment[]>([]);
  const [searchUsers, { loading }] =
    useLazyQuery<UserFilterSearchUsersQuery>(SEARCH_USERS);
  const [value, setValue] = useState<UserFilterFragment[]>([]);

  const { data: defaultUsers } = useQuery<GetUsersByIdsQuery>(
    GET_DEFAULT_USERS,
    {
      skip: !defaultValue,
      variables: { ids: defaultValue },
    },
  );

  useEffect(() => {
    if (defaultUsers?.users) {
      setValue(defaultUsers.users);
    }
  }, [defaultUsers]);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchUsers({
          variables: {
            q: value,
          },
        }).then(response => {
          const newOptions = (response.data?.search_users_tsv ?? []).filter(
            user => !defaultValue.includes(user.id),
          );
          setOptions(newOptions);
        });
      }, 275),
    [searchUsers, defaultValue],
  );

  const handleSearch = (value: string) => {
    if (value) {
      debouncedSearch(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      openOnFocus
      open
      disablePortal
      size="small"
      clearOnBlur={false}
      value={value}
      loading={loading}
      onClose={onClose}
      sx={{ width: 350 }}
      options={[...value, ...options]}
      getOptionLabel={option => option.full_name ?? ''}
      filterOptions={x => x}
      PaperComponent={AutocompletePaper}
      onInputChange={(_, value) => handleSearch(value)}
      onChange={(_, value) => {
        onChange(value.map((v: UserFilterFragment) => v.id));
      }}
      renderInput={params => (
        <AutocompleteTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} key={option.id} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
            <ListItemAvatar>
              <UserAvatar user={option} />
            </ListItemAvatar>
          </ListItemIcon>
          <ListItemText
            primary={option.full_name}
            secondary={option.primaryEmail[0]?.email}
          />
        </ListItem>
      )}
    />
  );
};

export const UserFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams();
  const { getValueFromPath } = useSchema();
  const defaultValue = getValueFromPath(path, where)?.id?._in ?? [];

  const handleChange = (value: string[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
    }
    setFiltersParams(newWhere);
  };
  const handleDelete =
    defaultValue.length > 0 ? () => handleChange([]) : undefined;

  return (
    <FilterChip
      label={label}
      icon={<PersonOutlineOutlined />}
      onDelete={handleDelete}
      renderFilter={({ handleClose }) => (
        <UserMultiSelect
          onClose={handleClose}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};
