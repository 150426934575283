import * as React from 'react';
import {
  FilledInput,
  InputAdornment,
  Chip,
  Badge,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  IconButton,
  type FilledInputProps,
  type InputBaseComponentProps,
  type InputProps,
  CircularProgress,
} from '@material-ui/core';
import Downshift, {
  type ControllerStateAndHelpers,
  type DownshiftState,
  type StateChangeOptions,
} from 'downshift';
import { Box } from 'react-system';
import { Image } from '@realadvisor/image';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { debounce } from '@mui/material/utils';
import type { Cancelable } from '@mui/utils/debounce';
import { Person } from '../icons/person';
import { Cancel } from '../icons/cancel';
import { useTheme } from '../hooks/theme';
import { useLocale } from '../hooks/locale';
import { InputPopup, focusWrappedInputRef } from '../controls/popup';
import type {
  UserInputDefaultTeamsUsersQuery,
  UserInputFragment,
  UserInputSearchUsersQuery,
  Users_Bool_Exp,
} from '../../apollo/__generated__/graphql';
import { fromGlobalId, toGlobalId } from '../../shared/global-id';
import { useAppData } from '../../apollo/providers/AppDataProvider';
import { UserCreateDialog } from './user-create-dialog';

export type UserSearchFilters = {
  isBroker?: null | boolean;
  isAdmin?: null | boolean;
  isFinancing?: null | boolean;
  hasLeadsModule?: null | boolean;
  only_in_current_teams?: null | boolean;
  id_in?: null | Array<null | string>;
  id_nin?: null | Array<null | string>;
  id_sort_first_in?: null | Array<null | string>;
};

type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

export type User = {
  id: string;
  firstName: null | string;
  lastName: null | string;
  brandingLogo?: null | string;
  brandingColor?: null | string;
  brandingName?: null | string;
  brandingWebsite?: null | string;
  primaryImage: null | {
    url: string;
  };
  primaryEmail: null | {
    email: string;
  };
  organisation?: null | {
    name: null | string;
    formattedAddress: null | string;
  };
  subscription?: null | {
    status: SubscriptionStatus;
  };
};

type Props = {
  Input?: React.ComponentType<InputProps>;
  creatable?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  focusOnDelete?: boolean;
  filters?: UserSearchFilters;
  value: null | User;
  onChange: (user: null | User) => void;
  onBlur?: () => void;
};

type MultiUserProps = {
  Input?: (props: FilledInputProps) => JSX.Element;
  creatable?: boolean;
  clearable?: boolean;
  filters?: UserSearchFilters;
  value: User[];
  onChange: (users: User[]) => void;
};

const USER_INPUT_FRAGMENT = gql`
  fragment UserInput on users {
    id
    first_name
    last_name
    full_name
    primaryEmail: emails(
      order_by: { primary: desc, verified: desc }
      limit: 1
    ) {
      email
    }
    default_team {
      organisation {
        name
        street
        street_number
        postcode
        locality
        state
        country
      }
    }
    subscriptions {
      status
    }
    primaryImage: user_images(where: { is_primary: { _eq: true } }, limit: 1) {
      image {
        url
      }
    }
    branding_color
    branding_logo
    branding_name
    branding_website
  }
`;

const SEARCH_USERS = gql`
  ${USER_INPUT_FRAGMENT}
  query UserInputSearchUsers($q: String!, $where: users_bool_exp) {
    search_users_tsv(args: { search_text: $q }, where: $where, limit: 10) {
      ...UserInput
    }
  }
`;

const DEFAULT_TEAMS_USERS = gql`
  ${USER_INPUT_FRAGMENT}
  query UserInputDefaultTeamsUsers($id: uuid!) {
    users_by_pk(id: $id) {
      default_team {
        teams_users(
          where: {
            deactivated: { _eq: false }
            user: { is_deleted: { _eq: false } }
          }
        ) {
          user {
            ...UserInput
          }
        }
      }
    }
  }
`;

const useUserInputQuery = ({
  filters,
}: {
  filters: UserSearchFilters | null;
}) => {
  const { me } = useAppData();
  const userId = me?.id ?? null;
  const [users, setUsers] = React.useState<User[]>([]);
  const { refetch } = useQuery<UserInputDefaultTeamsUsersQuery>(
    DEFAULT_TEAMS_USERS,
    {
      skip: userId == null,
      variables: { id: userId },
    },
  );

  const [searchUsers, { loading, error }] =
    useLazyQuery<UserInputSearchUsersQuery>(SEARCH_USERS, {
      variables: {
        where: filters != null ? usersSearchWhereClause(filters) : {},
      },
    });

  const debouncedSearch = React.useMemo(
    () =>
      debounce((value: string) => {
        if (value.length < 3) {
          refetch().then(({ data }) => {
            const users = getItemsUserInput(
              data?.users_by_pk?.default_team?.teams_users?.map(u => u.user) ??
                null,
            );
            setUsers(users);
          });
        } else {
          searchUsers({
            variables: {
              q: value,
            },
            onCompleted: data => {
              const users = getItemsUserInput(data?.search_users_tsv ?? null);
              setUsers(users);
            },
          });
        }
      }, 150),
    [searchUsers, setUsers, refetch],
  );
  return { debouncedSearch, users, loading, error };
};

export const getUserNameUtil = ({
  firstName,
  lastName,
  email,
  returnEmptyIfNull = false,
}: {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  returnEmptyIfNull?: boolean;
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  if (email) {
    return email.split('@')[0];
  }
  return returnEmptyIfNull ? '' : 'User name is not specified';
};

export const getUserName = (user?: User | null): string => {
  if (user == null) {
    return '';
  }
  const firstName = user.firstName ?? '';
  const lastName = user.lastName ?? '';
  const email = user.primaryEmail?.email ?? null;
  return getUserNameUtil({ firstName, lastName, email });
};

const getItemsUserInput = (data: UserInputFragment[] | null) => {
  const transformUser = (datum: UserInputFragment): User => {
    const { name, street, street_number, postcode, locality, state, country } =
      datum?.default_team?.organisation ?? {};
    const formattedAddress = [
      [street, street_number].filter(Boolean).join(' '),
      [postcode, locality].filter(Boolean).join(' '),
      [state, country].filter(Boolean).join(' - '),
    ]
      .filter(Boolean)
      .join(', ');
    return {
      id: toGlobalId('User', datum.id),
      firstName: datum.first_name ?? null,
      lastName: datum.last_name ?? null,
      brandingColor: datum.branding_color,
      brandingLogo: datum.branding_logo,
      brandingName: datum.branding_name,
      brandingWebsite: datum.branding_website,
      primaryImage: datum.primaryImage?.[0]?.image?.url
        ? {
            url: datum.primaryImage?.[0]?.image?.url,
          }
        : null,
      primaryEmail: datum.primaryEmail?.[0]?.email
        ? {
            email: datum.primaryEmail?.[0]?.email,
          }
        : null,
      organisation: {
        name: name ?? '',
        formattedAddress,
      },
      subscription: {
        status: datum.subscriptions?.[0]?.status,
      },
    };
  };
  return data?.map(transformUser) ?? [];
};

export type UserAvatarProps = {
  user?: User | null;
  size: number;
  showSubscriptionStatus?: boolean;
};

export const UserAvatar = ({
  user,
  size,
  showSubscriptionStatus = false,
}: UserAvatarProps) => {
  const { colors } = useTheme();

  const subscriptionStatus = user?.subscription?.status ?? '';

  const badgeColor = ['active', 'trialing'].includes(subscriptionStatus)
    ? '#6CD362'
    : ['past_due', 'unpaid'].includes(subscriptionStatus)
    ? colors.orange400
    : ['incomplete', 'incomplete_expired', 'canceled'].includes(
        subscriptionStatus,
      )
    ? colors.red700
    : colors.grey500;

  const imageUrl = user?.primaryImage?.url;

  return (
    <Box css={{ width: size, height: size }} mr={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        invisible={showSubscriptionStatus !== true}
        variant="dot"
        css={{
          '.MuiBadge-dot': {
            backgroundColor: badgeColor,
            color: badgeColor,
            border: '1px solid #ffffff',
            height: '10px',
            minWidth: '10px',
            borderRadius: '6px',
          },
        }}
      >
        {imageUrl == null ? (
          <Person size={size} fill={colors.grey400} />
        ) : (
          <Image
            objectFit="cover"
            options={{ w: 40, h: 40, c: 'fill' }}
            src={imageUrl}
            css={{ borderRadius: '50%' }}
          />
        )}
      </Badge>
    </Box>
  );
};

const getCreatable = (string: string) => {
  // regex don't need to be complex since it's not a validation
  // we just need to know if string is email-like, not if it is valid
  const EMAIL_REGEX = /^\S{1,64}@\S{1,64}\.\S{1,64}$/;

  let firstName = null;
  let lastName = null;
  let primaryEmail = null;

  if (EMAIL_REGEX.test(string)) {
    primaryEmail = { email: string };
  } else {
    [firstName, lastName] = string.split(' ');
  }

  return {
    id: 'creatable',
    firstName,
    lastName,
    primaryImage: null,
    primaryEmail,
    subscription: null,
  };
};

const UserMenu = ({
  targetRef,
  items,
  creatable,
  loading,
  downshift,
}: {
  targetRef: { current: null | HTMLElement };
  items: User[];
  creatable: boolean;
  loading?: boolean;
  downshift: ControllerStateAndHelpers<User>;
}) => {
  const { t } = useLocale();
  const { inputValue, isOpen, highlightedIndex } = downshift;
  const { getMenuProps, getItemProps } = downshift;
  return (
    <InputPopup referenceRef={targetRef} open={isOpen}>
      <List {...getMenuProps()}>
        {!loading && (
          <>
            {items.length === 0 && (
              <ListItem disabled={true}>{t('noOptions')}</ListItem>
            )}
            {isOpen && creatable && (
              <ListItem
                {...getItemProps({ item: getCreatable(inputValue ?? '') })}
                button={true}
                selected={highlightedIndex === 0}
              >
                <UserAvatar user={null} size={40} />
                <ListItemText>
                  {t('createWithUserName', { name: inputValue ?? '' })}
                </ListItemText>
              </ListItem>
            )}
          </>
        )}

        {isOpen &&
          items.map((item, index) => (
            <ListItem
              {...getItemProps({ item })}
              key={item.id}
              button={true}
              selected={highlightedIndex === index + 1}
            >
              <UserAvatar user={item} size={40} />
              <ListItemText
                primary={getUserName(item)}
                secondary={item.primaryEmail?.email}
              />
            </ListItem>
          ))}
      </List>
    </InputPopup>
  );
};

const usersSearchWhereClause = (filters: UserSearchFilters) => {
  const where: Users_Bool_Exp = {};
  if (filters.isBroker != null) {
    where.is_broker = { _eq: filters.isBroker };
  }
  if (filters.isAdmin != null) {
    where.is_admin = { _eq: filters.isAdmin };
  }
  if (filters.id_in != null && filters.id_in.length !== 0) {
    where.id = { _in: filters.id_in.map(fromGlobalId) };
  }
  if (filters.id_nin != null && filters.id_nin.length !== 0) {
    where.id = { _nin: filters.id_nin.map(fromGlobalId) };
  }
  if (filters.isFinancing != null) {
    where.modules = { _contains: ['financing'] };
  }
  return where;
};

const UserInputContent = ({
  props,
  downshift,
  targetRef,
  debouncedSearch,
  loading,
}: {
  props: Pick<
    Props,
    | 'Input'
    | 'focusOnDelete'
    | 'clearable'
    | 'onBlur'
    | 'autoFocus'
    | 'onChange'
  >;
  downshift: ControllerStateAndHelpers<User>;
  targetRef: { current: null | HTMLElement };
  debouncedSearch: ((value: string) => void) & Cancelable;
  loading?: boolean;
}) => {
  const {
    Input = FilledInput,
    focusOnDelete = false,
    clearable = true,
  } = props;
  const { getInputProps, inputValue, selectedItem } = downshift;
  const { openMenu } = downshift;

  return (
    <Input
      {...(getInputProps({
        ref: targetRef,
        onFocus: () => {
          debouncedSearch(inputValue ?? '');
          openMenu();
        },
        onBlur: props.onBlur,
        autoFocus: props.autoFocus,
        startAdornment: selectedItem != null && (
          <InputAdornment position="start">
            <UserAvatar user={selectedItem} size={24} />
          </InputAdornment>
        ),
        endAdornment: (loading || (clearable && selectedItem)) && (
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={24} disableShrink />
            ) : (
              <IconButton
                onClick={() => {
                  if (focusOnDelete) {
                    focusWrappedInputRef(targetRef);
                  }
                  props.onChange(null);
                }}
              >
                <Cancel />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }) as any)}
    />
  );
};

export const UserInput = (props: Props) => {
  const { debouncedSearch, users, loading } = useUserInputQuery({
    filters: props.filters ?? null,
  });

  const {
    creatable = true,
    Input,
    focusOnDelete,
    clearable,
    onBlur,
    autoFocus,
    onChange,
  } = props;
  const targetRef = React.useRef(null);
  const [creating, setCreating] = React.useState<User | null>(null);
  return (
    <Downshift<User>
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={getUserName}
      onInputValueChange={inputValue => debouncedSearch(inputValue)}
      onChange={selectedItem => {
        if (selectedItem?.id === 'creatable') {
          setCreating(selectedItem);
        } else {
          selectedItem != null
            ? props.onChange({
                ...selectedItem,
                brandingColor: selectedItem.brandingColor,
                brandingName: selectedItem.brandingName,
                brandingWebsite: selectedItem.brandingWebsite,
                brandingLogo: selectedItem.brandingLogo,
                organisation:
                  selectedItem?.organisation != null
                    ? {
                        name: selectedItem.organisation.name,
                        formattedAddress:
                          selectedItem.organisation.formattedAddress,
                      }
                    : null,
              })
            : props.onChange(null);
        }
      }}
    >
      {downshift => {
        return (
          <div>
            <UserInputContent
              props={{
                Input,
                focusOnDelete,
                clearable,
                onBlur,
                autoFocus,
                onChange,
              }}
              downshift={downshift}
              targetRef={targetRef}
              loading={loading}
              debouncedSearch={debouncedSearch}
            />
            <UserMenu
              targetRef={targetRef}
              items={users}
              creatable={creatable}
              downshift={downshift}
              loading={loading}
            />
            <UserCreateDialog
              initialValues={{
                firstName: creating?.firstName ?? null,
                lastName: creating?.lastName ?? null,
              }}
              open={creating != null}
              onClose={() => setCreating(null)}
              onCreate={user => {
                setCreating(null);
                if (user) {
                  downshift.selectItem(user);
                }
              }}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

type SectionProps = {
  sections: {
    title: string;
    filter: (user: User) => boolean;
    sort?: (a: User, b: User) => number;
  }[];
  showSubscriptionStatus?: boolean;
} & Props;

export const UserSectionInput = (props: SectionProps) => {
  const { debouncedSearch, users, loading } = useUserInputQuery({
    filters: props.filters ?? null,
  });

  const { t } = useLocale();
  const {
    showSubscriptionStatus,
    Input,
    focusOnDelete,
    clearable,
    onBlur,
    autoFocus,
    onChange,
  } = props;
  const targetRef = React.useRef(null);

  return (
    <Downshift
      // make selectedItem always controlled
      selectedItem={props.value ?? null}
      itemToString={getUserName}
      onInputValueChange={inputValue => debouncedSearch(inputValue)}
      onChange={selectedItem => {
        if (selectedItem == null) {
          props.onChange(null);
        } else {
          props.onChange({
            ...selectedItem,
            organisation:
              selectedItem.organisation != null
                ? {
                    name: selectedItem.organisation.name,
                    formattedAddress:
                      selectedItem.organisation.formattedAddress,
                  }
                : null,
          });
        }
      }}
    >
      {downshift => {
        const items = props.sections.map(section => {
          const sectionUsers = users.filter(section.filter);

          if (section.sort != null) {
            sectionUsers?.sort(section.sort);
          }

          return {
            title: section.title,
            users: sectionUsers,
          };
        });
        const { getItemProps, highlightedIndex, isOpen, getMenuProps } =
          downshift;
        let itemIndex = -1;
        return (
          <div>
            <UserInputContent
              props={{
                Input,
                focusOnDelete,
                clearable,
                onBlur,
                autoFocus,
                onChange,
              }}
              downshift={downshift}
              targetRef={targetRef}
              loading={loading}
              debouncedSearch={debouncedSearch}
            />
            <InputPopup referenceRef={targetRef} open={isOpen}>
              <List {...getMenuProps()}>
                {items.length === 0 && (
                  <ListItem disabled={true}>{t('noOptions')}</ListItem>
                )}
                {isOpen &&
                  items.map(section => (
                    <React.Fragment key={section.title}>
                      <ListSubheader disableSticky={true}>
                        {section.title}
                      </ListSubheader>
                      {section.users.length ? (
                        section.users.map((item: User) => {
                          itemIndex += 1;

                          return (
                            <ListItem
                              {...getItemProps({
                                item,
                                index: itemIndex,
                              })}
                              key={itemIndex}
                              button={true}
                              selected={highlightedIndex === itemIndex}
                            >
                              <UserAvatar
                                user={item}
                                size={40}
                                showSubscriptionStatus={showSubscriptionStatus}
                              />
                              <ListItemText
                                primary={getUserName(item)}
                                secondary={item?.primaryEmail?.email}
                              />
                            </ListItem>
                          );
                        })
                      ) : (
                        <ListItem>
                          <ListItemText secondary={t('noUsers')} />
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </InputPopup>
          </div>
        );
      }}
    </Downshift>
  );
};

const multiInputStateReducer = (
  state: DownshiftState<User>,
  changes: StateChangeOptions<User>,
) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: false,
        inputValue: '',
      };
    default:
      return changes;
  }
};

const MultiValueInput = ({
  inputRef,
  className,
  selectedItems,
  removeItem,
  ...props
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  className: string;
  selectedItems: User[];
  removeItem: (user: User) => void;
} & InputBaseComponentProps) => {
  return (
    <div
      className={className}
      css={{ display: 'flex', flexWrap: 'wrap' }}
      onClick={event => focusWrappedInputRef({ current: event.currentTarget })}
    >
      {selectedItems.map(item => (
        <Chip
          key={item.id}
          css={{
            marginRight: 8,
            marginTop: 2,
            marginBottom: 2,
            flex: '0 0 auto',
          }}
          avatar={
            // chip adds own class to this div
            <div>
              <UserAvatar user={item} size={24} />
            </div>
          }
          label={getUserName(item)}
          onDelete={() => removeItem(item)}
        />
      ))}
      <input
        ref={inputRef}
        css={{
          all: 'inherit',
          padding: 0,
          width: 80,
          flex: '1 1 auto',
        }}
        {...props}
      />
    </div>
  );
};

export const getMultiUserShrink = (items: User[]): boolean | undefined =>
  items.length === 0 ? undefined : true;

export const UserMultiInput = (props: MultiUserProps) => {
  const { debouncedSearch, users, loading } = useUserInputQuery({
    filters: props.filters ?? null,
  });

  const { Input = FilledInput, clearable = true, creatable = true } = props;
  const targetRef = React.useRef(null);
  const [creating, setCreating] = React.useState<User | null>(null);
  const selectedItems = props.value;
  const setSelectedItems = props.onChange;
  const removeItem = (item: User) => {
    setSelectedItems(selectedItems.filter(i => i.id !== item.id));
  };
  const addItem = (item: User) => {
    setSelectedItems([...selectedItems, item]);
  };
  return (
    <Downshift<User>
      selectedItem={null}
      itemToString={() => ''}
      stateReducer={multiInputStateReducer}
      onInputValueChange={inputValue => debouncedSearch(inputValue)}
      onChange={item => {
        if (item?.id === 'creatable') {
          setCreating(item);
        } else if (
          item &&
          selectedItems.some(i => i.id === item.id) === false
        ) {
          addItem(item);
        }
      }}
    >
      {downshift => {
        const { getInputProps, inputValue, openMenu } = downshift;
        return (
          <div>
            <Input
              {...(getInputProps({
                ref: targetRef,
                onFocus: () => {
                  debouncedSearch(inputValue ?? '');
                  openMenu();
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                  if (event.key === 'Backspace' && inputValue?.length === 0) {
                    removeItem(selectedItems[selectedItems.length - 1]);
                  }
                },
                inputComponent: MultiValueInput,
                inputProps: {
                  selectedItems,
                  removeItem,
                },
                endAdornment: (loading ||
                  (clearable && selectedItems.length !== 0)) && (
                  <InputAdornment position="end">
                    {loading ? (
                      <CircularProgress size={24} disableShrink />
                    ) : (
                      <IconButton onClick={() => setSelectedItems([])}>
                        <Cancel />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }) as any)}
            />
            <UserMenu
              targetRef={targetRef}
              items={users}
              creatable={creatable}
              loading={loading}
              downshift={downshift}
            />
            <UserCreateDialog
              initialValues={{
                firstName: creating?.firstName ?? null,
                lastName: creating?.lastName ?? null,
                email: creating?.primaryEmail?.email,
              }}
              open={creating != null}
              onClose={() => setCreating(null)}
              onCreate={user => {
                setCreating(null);
                if (user) {
                  addItem(user);
                }
              }}
            />
          </div>
        );
      }}
    </Downshift>
  );
};
