export const formatPrice = (
  price: number,
  locale: Intl.LocalesArgument,
  currency: 'CHF' | 'EUR' | 'GBP',
  maximumFractionDigits = 0,
): string =>
  price.toLocaleString(locale ?? 'de-CH', {
    maximumFractionDigits,
    minimumFractionDigits: 0,
    style: 'currency',
    currency,
  });
