// @flow

import * as React from 'react';
import { IconButton, Button, AppBar, Toolbar } from '@material-ui/core';
import { Box } from 'react-system';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
// $FlowFixMe[untyped-import]
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocale } from '../hooks/locale';
import {
  DrawerLayer,
  DrawerContent,
  DrawerBottomToolbar,
} from '../controls/drawer';
import { ProgressButton } from '../controls/progress-button';
import { PropertyForm } from '../components/PropertyForm';
import { LotDuplicationDialog } from '../shared/lot-duplication-dialog';
import { KeyboardArrowLeft } from '../icons/keyboard-arrow-left';
import { useTheme } from '../hooks/theme';
import type { lotCreateDrawerPropertyFormQuery } from './__generated__/lotCreateDrawerPropertyFormQuery.graphql';
import type { lotCreateDrawerMutation } from './__generated__/lotCreateDrawerMutation.graphql';

const DuplicateOrCreateLot = ({ developmentId, onClose }) => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const data = useLazyLoadQuery<lotCreateDrawerPropertyFormQuery>(
    graphql`
      query lotCreateDrawerPropertyFormQuery {
        ...PropertyForm_root
        me {
          id
        }
      }
    `,
    {},
  );
  const [createLot, creating] = useMutation<lotCreateDrawerMutation>(
    graphql`
      mutation lotCreateDrawerMutation($input: UpsertLotInput!) {
        upsertLot(input: $input) {
          lot {
            id
            orderNr
            property {
              ...PropertyForm_property
              ...propertyPhotos_property
            }
          }
        }
      }
    `,
  );
  return (
    <PropertyForm
      holder="big-modal"
      root={data}
      property={null}
      disableValidation={true}
      handleSubmit={property => {
        createLot({
          variables: {
            input: {
              lot: {
                property,
                developmentId,
                origin: 'sourced_by_broker',
                brokerId: data.me?.id,
              },
            },
          },
          onCompleted: payload => {
            const lotId = payload.upsertLot?.lot?.id;
            if (lotId != null) {
              navigate(`/lots/${lotId}`);
            }
          },
        });
      }}
    >
      {({ form, submitForm, submitting }) => (
        <>
          <Box p={3}>{form}</Box>
          <DrawerBottomToolbar>
            <Button onClick={onClose}>{t('cancel')}</Button>
            <ProgressButton
              loading={creating || submitting}
              onClick={submitForm}
            >
              {t('create')}
            </ProgressButton>
          </DrawerBottomToolbar>
        </>
      )}
    </PropertyForm>
  );
};

type LotCreateDrawerProps = {|
  developmentId?: string,
  open: boolean,
|};
export const LotCreateDrawer = ({
  developmentId,
  open,
}: LotCreateDrawerProps): React.Node => {
  const { t } = useLocale();
  const { text, colors } = useTheme();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [duplicationDialog, setDuplicationDialog] = React.useState(false);

  const onClose = () =>
    navigate({
      pathname: '..',
      search,
    });
  return (
    <>
      <DrawerLayer
        width={['100vw', 'calc(100vw - 100px)', 960]}
        open={open}
        onClose={onClose}
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton color="inherit" onClick={onClose}>
              <KeyboardArrowLeft />
            </IconButton>
            <Box css={[text.h6, text.truncate(1)]} mr={2}>
              {developmentId != null ? t('createLot') : t('createNewListing')}
            </Box>
            <Box ml="auto">
              <Button
                css={{ color: colors.white }}
                onClick={() => setDuplicationDialog(true)}
              >
                {t('duplicate')}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <DrawerContent>
          <React.Suspense fallback={null}>
            <DuplicateOrCreateLot
              developmentId={developmentId}
              onClose={onClose}
            />
          </React.Suspense>
        </DrawerContent>
      </DrawerLayer>
      <LotDuplicationDialog
        lotId={null}
        developmentId={developmentId}
        open={duplicationDialog}
        onClose={() => setDuplicationDialog(false)}
      />
    </>
  );
};
