import type { LeadQualificationFunnelFragment } from '../__generated__/graphql';
import type { PropertyTypeLight } from '../components/property-form/propertyFormQueries';

export const COMMERCIAL_TYPES = [
  'indus_commercial',
  'indus_commercial_and_residential',
] as const;

export const LAND_TYPES = [
  'prop_building_land',
  'prop_agricultural_land',
  'prop_commercial_land',
  'prop_industrial_land',
] as const;

export const MULTI_FAMILY_TYPES = [
  'house_multiple_dwelling',
  'indus_commercial_and_residential',
] as const;

export const isCommercialProperty = (
  property: LeadQualificationFunnelFragment['property'],
) => COMMERCIAL_TYPES.includes(property?.property_type?.name as any);

export const isLandTypeProperty = (propertyType: PropertyTypeLight) =>
  LAND_TYPES.includes(propertyType?.name as any);

export const isMultiFamilyProperty = (
  property: LeadQualificationFunnelFragment['property'],
) => MULTI_FAMILY_TYPES.includes(property?.property_type?.name as any);

export const isMultiFamilyOrCommercialProperty = (
  property: LeadQualificationFunnelFragment['property'],
) =>
  Array.from(new Set([...MULTI_FAMILY_TYPES, ...COMMERCIAL_TYPES])).includes(
    property?.property_type?.name as any,
  );

type CompositePropertyType =
  | 'HOUSE_APPT'
  | 'HOUSE'
  | 'APPT'
  | 'ROOM'
  | 'PARK'
  | 'PROP'
  | 'BUILDING'
  | 'COMMERCIAL'
  | 'GASTRO'
  | 'OTHER';

type Composition = {
  propertyMainType_in?: string[];
  propertyMainType_nin?: string[];
  propertyType_in?: string[];
  propertyType_nin?: string[];
};

const substitutions: Array<[CompositePropertyType, Composition]> = [
  [
    'HOUSE',
    {
      propertyMainType_in: ['HOUSE'],
      propertyType_nin: ['house_multiple_dwelling'],
    },
  ],

  [
    'APPT',
    {
      propertyMainType_in: ['APPT'],
      propertyType_nin: ['apartment_single_room'],
    },
  ],

  [
    'HOUSE_APPT',
    {
      propertyMainType_in: ['HOUSE', 'APPT'],
      propertyType_nin: ['house_multiple_dwelling', 'apartment_single_room'],
    },
  ],

  ['ROOM', { propertyType_in: ['apartment_single_room'] }],

  ['PARK', { propertyMainType_in: ['PARK'] }],

  ['PROP', { propertyMainType_in: ['PROP'] }],

  [
    'BUILDING',
    {
      propertyType_in: [
        'house_multiple_dwelling',
        'indus_commercial_and_residential',
      ],
    },
  ],

  [
    'COMMERCIAL',
    {
      propertyMainType_in: ['INDUS', 'AGRI'],
      propertyType_nin: ['indus_commercial_and_residential'],
    },
  ],

  ['GASTRO', { propertyMainType_in: ['GASTRO'] }],
];

const coveredMainTypes = substitutions
  .map(([, { propertyMainType_in }]) => propertyMainType_in)
  .filter(Boolean)
  .reduce<string[]>((acc, cur) => [...acc, ...(cur ?? [])], []);

const coveredTypes = substitutions
  .map(([, { propertyType_in }]) => propertyType_in)
  .filter(Boolean)
  .reduce<string[]>((acc, cur) => [...acc, ...(cur ?? [])], []);

substitutions.push([
  'OTHER',
  { propertyMainType_nin: coveredMainTypes, propertyType_nin: coveredTypes },
]);

export const composePropertyType = (
  propertyMainType: null | string,
  propertyType: null | string,
): CompositePropertyType => {
  if (propertyMainType == null && propertyType == null) {
    return 'OTHER';
  }

  const substitution = substitutions.find(
    ([
      ,
      {
        propertyMainType_in,
        propertyMainType_nin,
        propertyType_in,
        propertyType_nin,
      },
    ]) => {
      if (propertyMainType != null) {
        if (
          propertyMainType_in != null &&
          !propertyMainType_in.includes(propertyMainType)
        ) {
          return false;
        }
        if (propertyMainType_nin?.includes(propertyMainType)) {
          return false;
        }
      }

      if (propertyType != null) {
        if (
          propertyType_in != null &&
          !propertyType_in.includes(propertyType)
        ) {
          return false;
        }
        if (propertyType_nin?.includes(propertyType)) {
          return false;
        }
      }

      return true;
    },
  );

  if (substitution == null) {
    return 'OTHER';
  }

  return substitution[0];
};
