import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { TemplateViewerDialog } from '../../src/controls/template-viewer-dialog';
import { type Language, useLocale } from '../../src/hooks/locale';

export interface IDocTemplate {
  id: string;
  name: string;
  isDefault: boolean;
  language: Language;
  isShownInUi: boolean;
  isAdminDefault: boolean;
}

interface TemplatesListButtonGroupProps {
  includeAsButton?: boolean;
  startIcon?: React.ReactNode;
  docTemplates: ReadonlyArray<IDocTemplate>;
  documentId: string;
  disabled: boolean;
  fullWidth?: boolean;
  label: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const getDefaultTemplate = (
  docTemplates: ReadonlyArray<IDocTemplate>,
  language: Language,
) => {
  const adminDefault = docTemplates.find(
    template => template.isAdminDefault && template.language === language,
  );

  if (adminDefault != null) {
    return adminDefault;
  }

  let docDefault: IDocTemplate | null = null;
  let anyDefault: IDocTemplate | null = null;

  for (const docTemplate of docTemplates) {
    if (docTemplate.isDefault) {
      // if we found template in necessary language, use it
      if (docTemplate.language === language) {
        docDefault = docTemplate;
        break;
        // else set english as default until we find necessary lang
      } else if (docTemplate.language === 'en') {
        docDefault = docTemplate;
        // just in case save any other default template index
      } else if (anyDefault == null) {
        anyDefault = docTemplate;
      }
    }
  }

  return docDefault ?? anyDefault ?? docTemplates[0];
};

export const TemplatesListButtonGroup: React.FC<
  TemplatesListButtonGroupProps
> = ({
  docTemplates,
  documentId,
  disabled,
  fullWidth,
  label,
  size,
  color = 'primary',
  includeAsButton = false,
  startIcon,
}) => {
  const { language } = useLocale();
  const [templateId, setTemplateId] = useState<string | null>(null);
  const anchorRef = useRef(null);
  const [anchor, setAnchor] = useState<null | HTMLButtonElement>(null);

  const open = Boolean(anchor);

  const shownTemplates = docTemplates.filter(template => template.isShownInUi);
  const defaultTemplate = getDefaultTemplate(shownTemplates, language);

  const renderButtons = () => [
    <Button
      key="openTemplateBtn"
      variant="outlined"
      size={size}
      title={defaultTemplate.name}
      onClick={() => setTemplateId(defaultTemplate.id)}
      startIcon={startIcon}
      ref={anchorRef}
      sx={theme => ({
        textTransform: 'none',
        ...(fullWidth === true ? { flexGrow: 1 } : {}),
        ...(includeAsButton === true
          ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              flexDirection: { xs: 'column', md: 'row' },
              '& .MuiButton-startIcon': {
                [theme.breakpoints.down('md')]: {
                  mr: 0,
                },
              },
            }
          : {}),
      })}
    >
      {label}
    </Button>,
    <Button
      key="dropdownBtn"
      size={size}
      sx={theme => ({
        paddingX: 0,
        [theme.breakpoints.down('md')]: { minWidth: '25px !important' },
        ...(includeAsButton === true ? { width: 'auto' } : {}),
      })}
      onClick={() => setAnchor(anchorRef.current)}
      id="templates-list-button"
      aria-controls={open ? 'templates-list-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      <ArrowDropDownIcon />
    </Button>,
  ];

  return (
    <>
      {includeAsButton ? (
        renderButtons()
      ) : (
        <ButtonGroup
          sx={fullWidth === true ? { display: 'flex' } : {}}
          color={color}
          variant="outlined"
          disabled={disabled}
        >
          {renderButtons()}
        </ButtonGroup>
      )}
      <Menu
        anchorEl={anchor}
        open={open}
        onClick={() => setAnchor(null)}
        onClose={() => setAnchor(null)}
        id="templates-list-menu"
        MenuListProps={{
          'aria-labelledby': 'templates-list-button',
        }}
      >
        {shownTemplates.map(item => (
          <MenuItem key={item.id} onClick={() => setTemplateId(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <TemplateViewerDialog
        templateId={templateId ?? ''}
        documentId={documentId}
        open={templateId != null}
        onClose={() => setTemplateId(null)}
      />
    </>
  );
};
