import React, { useState, useMemo } from 'react';
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  CircularProgress,
  Checkbox,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import type { ConditionOperator } from '../utils/parseWhereClause';
import type {
  GetPlacesQuery,
  GetSelectedPlacesQuery,
} from '../__generated__/graphql';

export type PlaceOperator = Extract<ConditionOperator, '_in'>;

type FilterPlacesFormProps = {
  value: string[] | 'true' | 'false';
  operator: PlaceOperator | null;
  onChange: (params: {
    value: FilterPlacesFormProps['value'];
    operator: PlaceOperator;
  }) => void;
};

const GET_SELECTED_PLACES = gql`
  query GetSelectedPlaces($place_ids: [uuid!]) {
    places(where: { id: { _in: $place_ids } }) {
      id
      label
      name
    }
  }
`;

type DisplaySelectedPlacesProps = {
  placeIds: string[];
};

export const DisplaySelectedPlaces: React.FC<DisplaySelectedPlacesProps> = ({
  placeIds,
}) => {
  const [places, setPlaces] = useState<GetSelectedPlacesQuery['places']>([]);

  useQuery<GetSelectedPlacesQuery>(GET_SELECTED_PLACES, {
    variables: { place_ids: placeIds },
    onCompleted: data => {
      setPlaces(data?.places ?? []);
    },
  });

  // display first 4 places seperate by comma and count the rest
  if (places.length > 0) {
    const first4Places = places.slice(0, 4);
    const restPlaces = places.slice(4);
    const restPlacesCount = restPlaces.length;
    return (
      <>
        {first4Places.map(place => place.label).join(', ')}
        {restPlacesCount > 0 && `, +${restPlacesCount}`}
      </>
    );
  }

  return null;
};

const GET_PLACES = gql`
  query GetPlaces($searchText: String) {
    search_places(args: { search: $searchText }, limit: 20) {
      id
      label
      name
      country {
        name
      }
    }
  }
`;

export const FilterPlacesForm: React.FC<FilterPlacesFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
}) => {
  const [value, setValue] = useState<GetPlacesQuery['search_places']>([]);
  const [operator, setOperator] = useState(initialOperator ?? '_in');
  const [open, setOpen] = useState(false);

  useQuery<GetSelectedPlacesQuery>(GET_SELECTED_PLACES, {
    variables: { place_ids: initialValue },
    skip: initialValue.length === 0,
    onCompleted: data => {
      setValue(data?.places ?? null);
    },
  });

  const [getPlaces, { data, loading }] =
    useLazyQuery<GetPlacesQuery>(GET_PLACES);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        getPlaces({
          variables: {
            searchText: value,
          },
        });
      }, 500),
    [getPlaces],
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      debouncedSearch(event.target.value);
    }
  };

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const selectedOperator = event.target.value as PlaceOperator;
          if (['_in'].includes(selectedOperator)) {
            setOperator(selectedOperator);
            onChange({
              value: value?.map(place => place.id) ?? null,
              operator: selectedOperator,
            });
          }
        }}
      >
        {[{ value: '_in', label: 'is any of' }].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value ?? []}
        disableCloseOnSelect
        size="small"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option?.label ?? ''}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} dense disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                size="small"
                disableRipple
                sx={{
                  '&.MuiCheckbox-root': {
                    padding: '4px',
                  },
                }}
                checked={selected}
              />
            </ListItemIcon>
            <ListItemText
              primary={option.label ?? option.name}
              primaryTypographyProps={{
                noWrap: true,
              }}
              secondaryTypographyProps={{
                noWrap: true,
              }}
              secondary={option.country?.name ?? '-'}
            />
          </ListItem>
        )}
        options={data?.search_places ?? []}
        loading={loading}
        filterOptions={x => x}
        autoComplete
        multiple
        includeInputInList
        fullWidth
        noOptionsText="No places"
        clearOnBlur
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange({
            value: newValue?.map(place => place.id) ?? null,
            operator,
          });
        }}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            hiddenLabel
            onChange={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} disableShrink />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};
