import { useRifm } from 'rifm';
import { FilledInput } from '@material-ui/core';

type Props = {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

const refuse = /[^\d]+/gi;

const parse = (string: string) => string.replace(refuse, '');

const mask = (string: string) =>
  string.slice(0, 4) + '____'.slice(string.length);

const formatYear = (string: string) => {
  return mask(parse(string));
};

export const YearInput = (props: Props) => {
  const rifm = useRifm({
    format: formatYear,
    value: props.value,
    onChange: value => props.onChange(parse(value)),
  });

  return (
    <FilledInput
      inputProps={{ type: 'tel' }}
      disabled={props.disabled}
      value={rifm.value}
      onChange={rifm.onChange}
      onBlur={props.onBlur}
    />
  );
};
