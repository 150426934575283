// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import mapbox from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_TOKEN } from '../config';
import { LocationOn } from '../icons/location-on';

type LngLatLike = {|
  lat: number,
  lng: number,
|};

// allows to use callback in effects without triggering update on every render
const useStableCallback = <T: (...$ReadOnlyArray<any>) => any>(
  callback: ?T,
): T => {
  const callbackRef = React.useRef(callback);
  const stable: any = React.useCallback(
    (...args) => callbackRef.current?.(...args),
    [],
  );
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return stable;
};

type MapboxProps = {|
  mapRef: {| current: null | mapbox.Map |},
  lat: number,
  lng: number,
  zoom: number,
  mapStyle?: string,
  onClick?: ({| latLng: LngLatLike |}) => void,
  onMoveend?: ({| center: LngLatLike, zoom: number |}) => void,
  style?: { [string]: any },
|};

export const Mapbox = ({
  mapRef,
  lat,
  lng,
  zoom,
  mapStyle,
  onClick,
  onMoveend,
  style,
}: MapboxProps): React.Node => {
  const containerRef = React.useRef(null);
  const initialViewportRef = React.useRef({ center: [lng, lat], zoom });
  // initialize map
  React.useEffect(() => {
    if (containerRef.current != null) {
      const map = new mapbox.Map({
        testMode: null,
        container: containerRef.current,
        accessToken: MAPBOX_TOKEN,
        attributionControl: false,
        style: mapStyle ?? 'mapbox://styles/spingwun/cjoeeyssz16n82rlmpsofotvw',
        // initial center and zoom should be set
        ...initialViewportRef.current,
      });
      map.addControl(new mapbox.NavigationControl({}));
      mapRef.current = map;
      return () => {
        map.remove();
        mapRef.current = null;
      };
    }
  }, [mapRef, mapStyle]);
  // sync viewport
  React.useEffect(() => {
    mapRef.current?.jumpTo({ center: [lng, lat], zoom });
  }, [mapRef, lng, lat, zoom]);
  // track click and moveend
  const stableOnClick = useStableCallback(onClick);
  const stableOnMoveend = useStableCallback(onMoveend);
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const handleClick = event => {
        const { lat, lng } = event.lngLat;
        stableOnClick({ latLng: { lat, lng } });
      };
      const handleMoveend = () => {
        const { lat, lng } = map.getCenter();
        const zoom = map.getZoom();
        stableOnMoveend({ center: { lat, lng }, zoom });
      };
      map.on('click', handleClick);
      map.on('moveend', handleMoveend);
      return () => {
        map.off('click', handleClick);
        map.off('moveend', handleMoveend);
      };
    }
  }, [mapRef, stableOnClick, stableOnMoveend]);
  return (
    <>
      {/* emotion global styles breaks markers styles */}
      <div
        style={{ ...style, width: '100%', height: '100%' }}
        ref={containerRef}
      ></div>
    </>
  );
};

const useMarker = (mapRef, markerRef, lat, lng, anchor) => {
  const [container, setContainer] = React.useState(null);
  const initialLatLngRef = React.useRef([lat, lng]);
  // initialize marker
  React.useEffect(() => {
    if (mapRef.current != null) {
      const map = mapRef.current;
      const [initialLat, initialLng] = initialLatLngRef.current;
      const element = document.createElement('div');
      const marker = new mapbox.Marker({
        element,
        anchor,
      })
        .setLngLat([initialLng, initialLat])
        .addTo(map);
      setContainer(element);
      markerRef.current = marker;
      return () => {
        marker.remove();
      };
    }
  }, [mapRef, markerRef, anchor]);
  // sync location
  React.useEffect(() => {
    markerRef.current?.setLngLat([lng, lat]);
  }, [markerRef, lat, lng]);
  return container;
};

type Anchor =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

type StaticMarkerProps = {|
  mapRef: {| current: null | mapbox.Map |},
  lat: number,
  lng: number,
  anchor?: Anchor,
  children: React.Node,
|};

export const StaticMarker = ({
  mapRef,
  lat,
  lng,
  anchor,
  children,
}: StaticMarkerProps): React.Node => {
  const markerRef = React.useRef<null | mapbox.Marker>(null);
  const container = useMarker(mapRef, markerRef, lat, lng, anchor);
  React.useEffect(() => {
    if (container) {
      container.style.cursor = 'arrow';
    }
  }, [container]);
  return container && ReactDOM.createPortal(children, container);
};

type DraggableMarkerProps = {|
  mapRef: {| current: mapbox.Map | null |},
  lat: number,
  lng: number,
  anchor?: Anchor,
  onDragend: ({| latLng: LngLatLike |}) => void,
  children: ({| dragging: boolean |}) => React.Node,
|};

export const DraggableMarker = ({
  mapRef,
  lat,
  lng,
  anchor,
  onDragend,
  children,
}: DraggableMarkerProps): React.Node => {
  const markerRef = React.useRef<null | mapbox.Marker>(null);
  const [dragging, setDragging] = React.useState(false);
  const container = useMarker(mapRef, markerRef, lat, lng, anchor);
  const stableOnDragend = useStableCallback(onDragend);
  React.useEffect(() => {
    if (container) {
      container.style.cursor = 'move';
      markerRef.current?.setDraggable(true);
    }
  }, [container]);
  React.useEffect(() => {
    if (markerRef.current != null) {
      const marker = markerRef.current;
      const handleDragstart = () => {
        setDragging(true);
      };
      const handleDragend = event => {
        const lngLat = event.target.getLngLat();
        stableOnDragend({
          // converting to plain object
          latLng: { lat: lngLat.lat, lng: lngLat.lng },
        });
        setDragging(false);
      };
      marker.on('dragstart', handleDragstart);
      marker.on('dragend', handleDragend);
      return () => {
        marker.off('dragstart', handleDragstart);
        marker.off('dragend', handleDragend);
      };
    }
  }, [stableOnDragend]);
  return container && ReactDOM.createPortal(children({ dragging }), container);
};

type LocationMarkerProps = {|
  mapRef: {| current: mapbox.Map | null |},
  lat: number,
  lng: number,
  onDragend?: ({| latLng: LngLatLike |}) => void,
|};

export const LocationMarker = ({
  mapRef,
  lat,
  lng,
  onDragend,
}: LocationMarkerProps): React.Node => {
  const style = {
    filter: [
      'drop-shadow(1px 1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(1px -1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(-1px 1px 1px rgba(255, 255, 255, 1))',
      'drop-shadow(-1px -1px 1px rgba(255, 255, 255, 1))',
    ].join(' '),
    width: 40,
    height: 40,
    color: '#2196f3',
  };
  if (onDragend) {
    return (
      <DraggableMarker
        mapRef={mapRef}
        lat={lat}
        lng={lng}
        anchor="bottom"
        onDragend={onDragend}
      >
        {({ dragging }) => (
          <LocationOn
            css={[
              style,
              {
                transition: 'all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                willChange: 'width, height',
                ':hover': { color: '#64b5f6', width: 44, height: 44 },
                '&, &:hover': dragging
                  ? { color: '#64b5f6', width: 52, height: 52 }
                  : {},
              },
            ]}
          />
        )}
      </DraggableMarker>
    );
  } else {
    return (
      <StaticMarker mapRef={mapRef} lat={lat} lng={lng} anchor="bottom">
        <LocationOn css={style} />
      </StaticMarker>
    );
  }
};
