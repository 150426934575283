import { gql } from '@apollo/client';
import { USER_INFO_USER_FRAGMENT } from '../../components/data-grid/UserInfo';
import { TEAM_INFO_TEAM_FRAGMENT } from '../../components/data-grid/TeamInfo';
import { PROPERTY_CELL_LISTING_FRAGMENT } from '../../components/data-grid/PropertyCell';

export const GET_PROPERTY_TRANSACTIONS = gql`
  ${PROPERTY_CELL_LISTING_FRAGMENT}
  ${TEAM_INFO_TEAM_FRAGMENT}
  ${USER_INFO_USER_FRAGMENT}
  query getPropertyTransactions(
    $where: property_transactions_bool_exp!
    $order_by: [property_transactions_order_by!]
    $limit: Int!
    $offset: Int!
  ) {
    property_transactions(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      signature_date
      estimated_signature_date
      payment_date
      purchase_price
      seller_broker {
        id
        ...UserInfo_user
      }
      buyer_broker {
        id
        ...UserInfo_user
      }
      seller_broker_team {
        id
        ...TeamInfo_team
      }
      buyer_broker_team {
        id
        ...TeamInfo_team
      }
      property {
        ...PropertyCell_property
        living_surface
        built_surface
        land_surface
        number_of_rooms
        number_of_bedrooms
        property_type {
          label
        }
      }
      commissions {
        id
        is_paid
        amount
      }
      lot {
        sellers {
          seller {
            id
            ...UserInfo_user
          }
        }
      }
      commissions_aggregate(where: { type: { _eq: "total" } }) {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_TRANSACTIONS_LIST_COUNT = gql`
  query getPropertyTransactionsListCount(
    $where: property_transactions_bool_exp!
  ) {
    property_transactions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const DRAWER_GET_PROPERTY_TRANSACTION = gql`
  query TransactionDrawerGetTransaction($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      lot_id
      property_id
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($id: uuid!) {
    delete_property_transactions_by_pk(id: $id) {
      id
    }
  }
`;

export const TRANSACTION_FORM_FRAGMENT = gql`
  ${USER_INFO_USER_FRAGMENT}
  fragment TransactionFormFragment on property_transactions {
    id
    created_by
    created_at
    signature_date
    signature_date
    buyer_broker_id
    seller_broker_id
    buyer_broker_team_id
    seller_broker_team_id
    signature_date
    estimated_signature_date
    payment_date
    purchase_price
    is_published
  }
`;

export const TRANSACTION_FORM_QUERY = gql`
  ${TRANSACTION_FORM_FRAGMENT}
  query TransactionFormQuery($id: uuid!) {
    property_transactions_by_pk(id: $id) {
      id
      ...TransactionFormFragment
    }
  }
`;

export const TRANSACTION_FORM_UPDATE = gql`
  ${TRANSACTION_FORM_FRAGMENT}
  ${USER_INFO_USER_FRAGMENT}
  ${TEAM_INFO_TEAM_FRAGMENT}
  mutation TransactionFormUpdate(
    $id: uuid!
    $transaction: property_transactions_set_input!
  ) {
    update_property_transactions_by_pk(
      pk_columns: { id: $id }
      _set: $transaction
    ) {
      id
      ...TransactionFormFragment
      seller_broker {
        id
        ...UserInfo_user
      }
      buyer_broker {
        id
        ...UserInfo_user
      }
      buyer_broker_team {
        id
        ...TeamInfo_team
      }
      seller_broker_team {
        id
        ...TeamInfo_team
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  ${TRANSACTION_FORM_FRAGMENT}
  ${USER_INFO_USER_FRAGMENT}
  ${TEAM_INFO_TEAM_FRAGMENT}
  mutation CreateTransaction(
    $transaction: property_transactions_insert_input!
  ) {
    insert_property_transactions_one(object: $transaction) {
      id
      ...TransactionFormFragment
      seller_broker {
        id
        ...UserInfo_user
      }
      buyer_broker {
        id
        ...UserInfo_user
      }
      buyer_broker_team {
        id
        ...TeamInfo_team
      }
      seller_broker_team {
        id
        ...TeamInfo_team
      }
      property {
        id
      }
    }
  }
`;

export const GET_USER_DEFAULT_TEAM_ID = gql`
  query GetUserDefaultTeamId($id: uuid!) {
    users_by_pk(id: $id) {
      default_team_id
    }
  }
`;
