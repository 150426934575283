import { useQuery } from '@apollo/client';
import { Alert, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Image } from '@realadvisor/image';
import { StoreRounded } from '@mui/icons-material';
import type { OverviewGetTeamQuery } from '../../__generated__/graphql';
import { useTheme } from '../../../src/hooks/theme';
import { useAppData } from '../../providers/AppDataProvider';
import { TeamScoreCards } from './TeamScoreCards';
import { TeamUsers } from './TeamUsers';
import { OVERVIEW_GET_TEAM } from './teamQueries';
import { CoverPhotoPlaceholder } from './CoverPhotoPlaceholder';

export const TeamOverview = () => {
  const { colors } = useTheme();
  const { teamId } = useParams() as { teamId: string };
  const { me } = useAppData();

  const { loading, data, error } = useQuery<OverviewGetTeamQuery>(
    OVERVIEW_GET_TEAM,
    {
      variables: { id: teamId, includeSubscriptions: me?.is_admin || false },
    },
  );

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <div
        style={{
          width: '100%',
          height: 260,
          position: 'relative',
          flexShrink: 0,
          borderBottom: `1px solid ${colors.divider}`,
        }}
      >
        {data?.teams_by_pk?.cover_photo?.url ? (
          <Image
            src={data?.teams_by_pk?.cover_photo?.url}
            options={{ w: 1000 }}
          />
        ) : (
          <CoverPhotoPlaceholder />
        )}
        <div
          style={{
            position: 'absolute',
            left: 20,
            bottom: 20,
            height: 150,
            width: 150,
            borderRadius: 12,
            backgroundColor: 'white',
            padding: 12,
            display: 'flex',
          }}
        >
          {data?.teams_by_pk?.logo?.url ? (
            <Image
              src={data?.teams_by_pk?.logo?.url ?? ''}
              options={{ w: 250 }}
              objectFit="contain"
            />
          ) : (
            <StoreRounded
              style={{
                fontSize: 100,
                color: colors.divider,
                margin: 'auto',
              }}
            />
          )}
        </div>
      </div>
      <Grid container spacing={2} padding={2} zIndex={2}>
        <Grid item xs={12}>
          {me?.is_admin && (
            <TeamScoreCards team={data?.teams_by_pk} loading={loading} />
          )}
          <TeamUsers team={data?.teams_by_pk} loading={loading} />
        </Grid>
      </Grid>
    </>
  );
};
