import * as React from 'react';
import { Stack, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import type { ConditionOperator } from '../utils/parseWhereClause';

export type EnumOperator = Extract<
  ConditionOperator,
  '_in' | '_nin' | '_is_null'
>;

type FilterEnumFormProps = {
  value: string[] | boolean;
  operator: EnumOperator | null;
  enumValues: string[];
  onChange: (params: {
    value: string[] | boolean;
    operator: EnumOperator;
  }) => void;
};

export const FilterEnumForm: React.FC<FilterEnumFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  enumValues: initialEnumValues,
  onChange,
}) => {
  const [value, setValue] = React.useState<string[] | boolean>(
    Array.isArray(initialValue) ? initialValue : initialValue === false,
  );
  const [operator, setOperator] = React.useState<EnumOperator>(
    initialOperator ?? '_in',
  );

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const newOperator = event.target.value as EnumOperator;
          if (newOperator === '_is_null' && Array.isArray(value)) {
            setValue(true);
          } else if (newOperator !== '_is_null' && !Array.isArray(value)) {
            setValue([]);
          }
          setOperator(newOperator);
          onChange({ value, operator: newOperator });
        }}
      >
        {[
          { value: '_in', label: 'is any of...' },
          { value: '_nin', label: 'is not any of...' },
          { value: '_is_null', label: 'is present' },
        ].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={value}
        multiple={operator !== '_is_null'}
        //@ts-ignore
        hiddenLabel
        renderValue={selected =>
          Array.isArray(selected)
            ? [
                selected[0],
                selected.length > 1 ? `+ ${selected.length - 1}` : null,
              ]
                .filter(Boolean)
                .join(', ')
            : selected.toString()
        }
        onChange={event => {
          if (operator === '_is_null') {
            const newValue = event.target.value === 'true';
            setValue(newValue);
            onChange({ operator, value: newValue });
          } else if (['_in', '_nin'].includes(operator)) {
            const newValue = event.target.value as string[];
            setValue(newValue);
            onChange({ operator, value: newValue });
          }
        }}
      >
        {operator !== '_is_null' &&
          initialEnumValues.map(item => (
            <MenuItem key={item} value={item} dense>
              <Checkbox
                size="small"
                checked={
                  Array.isArray(value) ? value.indexOf(item) > -1 : undefined
                }
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        {operator === '_is_null' &&
          ['true', 'false'].map(item => (
            <MenuItem key={item} value={item} dense>
              <ListItemText primary={item} />
            </MenuItem>
          ))}
      </Select>
    </Stack>
  );
};
