import { Property_Side_Enum_Enum } from '../__generated__/graphql';

import type { Translate } from '../../src/hooks/locale';
import { type IntlLocale, type LotCurrency } from '../../src/locale';
import type {
  Cooling_Enum_Enum,
  Heating_Distribution_Enum_Enum,
  Heating_Enum_Enum,
  ListingPriceFragment,
} from '../__generated__/graphql';
import { formatCurrency } from './formatting';

export const getOfferTypeOptions = (t: Translate) => [
  { id: 'rent', label: t('rent') },
  { id: 'sell', label: t('sell') },
];

export const getPriceUnitOptions = (
  t: Translate,
  offerType:
    | ReturnType<typeof getOfferTypeOptions>[number]['id']
    | null
    | undefined,
) =>
  offerType === 'rent'
    ? [
        { value: 'monthly', label: t('monthly') },
        { value: 'yearly', label: t('yearly') },
        { value: 'weekly', label: t('weekly') },
        { value: 'daily', label: t('daily') },
        { value: 'm2yearly', label: t('yearlyPerMeterSquared') },
      ]
    : [
        { value: 'sell', label: t('totalPrice') },
        { value: 'sellm2', label: t('pricePerMeterSquared') },
      ];

export const getListingPrices = (
  lot: ListingPriceFragment,
  t: Translate,
  locale: IntlLocale,
): {
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  appraisalDiff?: null | number;
} => {
  const {
    offer_type,
    sale_price,
    price_unit,
    rent_net,
    rent_extra,
    currency,
    property,
  } = lot;

  const currencyToFormat = (currency ??
    (property.country_code === 'CH' ? 'CHF' : 'EUR')) as LotCurrency;

  const formatNumber = (price: number) =>
    price.toLocaleString(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'decimal',
    });

  const surface =
    property.weighted_floor_surface != null
      ? { value: property.weighted_floor_surface, label: t('weighted') }
      : property.living_surface != null
      ? { value: property.living_surface, label: t('living') }
      : property.built_surface != null
      ? { value: property.built_surface, label: t('living') }
      : null;

  if (offer_type === 'sell') {
    if (sale_price == null) {
      return {};
    }

    if (price_unit === 'sell') {
      const appraisal = property.latest_appraisal?.value;

      return {
        primary: formatCurrency(sale_price, currencyToFormat, locale),
        secondary: surface && [
          formatCurrency(sale_price / surface.value, currencyToFormat, locale),
          ' / m² (',
          surface.label,
          ')',
        ],
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    if (price_unit === 'sellm2') {
      const appraisal =
        property.latest_appraisal?.price_per_living_surface_meter_squared;
      return {
        primary: [
          formatCurrency(sale_price, currencyToFormat, locale),
          ' / m²',
        ],
        appraisalDiff:
          appraisal != null && appraisal !== 0
            ? ((sale_price - appraisal) / appraisal) * 100
            : null,
      };
    }

    // in case a new price_unit was added
    return { primary: formatCurrency(sale_price, currencyToFormat, locale) };
  }

  if (offer_type === 'rent') {
    if (rent_net == null) {
      return {};
    }

    const extra = (multiplier: number) =>
      rent_extra == null
        ? []
        : [' (+ ', formatNumber(rent_extra * multiplier), ')'];

    const secondary = (multiplier: number) =>
      surface && [
        formatCurrency(
          (rent_net * multiplier) / surface.value,
          currencyToFormat,
          locale,
        ),
        ' / m² (',
        surface.label,
        ') / ',
        t('year'),
        ...extra(multiplier),
      ];

    if (price_unit === 'daily') {
      return {
        primary: [
          formatCurrency(rent_net, currencyToFormat, locale),
          ' / ',
          t('day'),
          ...extra(1),
        ],
        secondary: secondary(365),
      };
    }

    if (price_unit === 'monthly') {
      return {
        primary: [
          formatCurrency(rent_net, currencyToFormat, locale),
          ' / ',
          t('month'),
          ...extra(1),
        ],
        secondary: secondary(12),
      };
    }

    if (price_unit === 'weekly') {
      return {
        primary: [
          formatCurrency(rent_net, currencyToFormat, locale),
          ' / ',
          t('week'),
          ...extra(1),
        ],
        secondary: secondary(365 / 7),
      };
    }

    if (price_unit === 'yearly') {
      return {
        primary: [
          formatCurrency(rent_net, currencyToFormat, locale),
          ' / ',
          t('year'),
          ...extra(1),
        ],
        secondary: secondary(1),
      };
    }

    if (price_unit === 'm2yearly') {
      return {
        primary: [
          formatCurrency(rent_net, currencyToFormat, locale),
          ' / m² / ',
          t('year'),
          ...extra(1),
        ],
      };
    }

    // in case a new price_unit was added
    return {
      primary: [
        formatCurrency(rent_net, currencyToFormat, locale),
        ...extra(1),
      ],
    };
  }

  return {};
};

export const getPropertyHeatingLabel = (
  heatingSlug: Heating_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (heatingSlug) {
    case 'none':
      return t('none');
    case 'central':
      return t('centralHeating');
    case 'district':
      return t('districtHeating');
    case 'oil':
      return t('heatingOil');
    case 'gas':
      return t('gas');
    case 'electric':
      return t('electric');
    case 'wood':
      return t('wood');
    case 'solar':
      return t('solarCollector');
    case 'heat_pump':
      return t('heatPump');
    case 'other':
      return t('other');
    default:
      return null;
  }
};

export const getPropertyHeatingDistributionLabel = (
  heatingDistributionSlug: Heating_Distribution_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (heatingDistributionSlug) {
    case 'radiators':
      return t('radiators');
    case 'floor':
      return t('floorHeating');
    case 'forced_air':
      return t('forcedAir');
    case 'stove':
      return t('stove');
    case 'other':
      return t('other');
    default:
      return null;
  }
};

export const getPropertyCoolingLabel = (
  coolingSlug: Cooling_Enum_Enum | undefined | null,
  t: Translate,
) => {
  switch (coolingSlug) {
    case 'none':
      return t('none');
    case 'air_conditioning':
      return t('airConditioning');
    case 'ceiling_fans':
      return t('ceilingFans');
    default:
      return null;
  }
};

export const getPropertyOrientations = (t: Translate) => [
  { value: 'N', label: t('northShort') },
  { value: 'NE', label: t('northEastShort') },
  { value: 'E', label: t('eastShort') },
  { value: 'SE', label: t('southEastShort') },
  { value: 'S', label: t('southShort') },
  { value: 'SW', label: t('southWestShort') },
  { value: 'W', label: t('westShort') },
  { value: 'NW', label: t('northWestShort') },
];

export const getPropertySides = (
  t: Translate,
): { value: Property_Side_Enum_Enum; label: string }[] => [
  { value: Property_Side_Enum_Enum.Exterior, label: t('exterior') },
  { value: Property_Side_Enum_Enum.Interior, label: t('interior') },
];

export const getEnergyClassValues = (t: Translate) => [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' },
  { value: 'd', label: 'D' },
  { value: 'e', label: 'E' },
  { value: 'f', label: 'F' },
  { value: 'g', label: 'G' },
  { value: 'not_set', label: t('notSet') },
];
