import { gql } from '../../__generated__';

export const STEP_COVER_PHOTO_FRAGMENT = gql(`
  fragment StepCoverPhoto on cma_reports {
    id
    updated_at
    language
    contact {
      ...UserInfo_user
    }
    broker {
      ...UserInfo_user
    }
    cover_image {
      id
      url
    }
    lead {
      id
      property {
        id
        updated_at
        lat
        lng
        property_images(
          where: { display_on_brochure: { _eq: true } }
          order_by: { order_nr: desc }
        ) {
          id
          image {
            id
            url
          }
        }
      }
    }
    doc_template {
      id
    }
  }
`);

export const STEP_HEDONISTIC_VALUATION_FRAGMENT = gql(`
  fragment StepHedonisticValuation on cma_reports {
    id
    updated_at
    include_hedonistic_valuation
    include_yield_valuation
    estimated_monthly_rent
    estimated_yield
    include_intrinsic_valuation
    lead {
      id
      property {
        id
        updated_at
        latest_appraisal {
          id
          updated_at
        }
        appraisals(order_by: { created_at: desc_nulls_last }) {
          id
        }
      }
    }
  }
`);

export const CMA_REPORT_FILE_FRAGMENT = gql(`
  fragment CmaReportFile on cma_report_files {
    cma_report_id
    file_id
    is_visible
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const CMA_PROPERTY_FILE_FRAGMENT = gql(`
  fragment CmaPropertyFile on property_files {
    property_id
    file_id
    order_nr
    file {
      id
      name
      url
      mimetype
      created_at
    }
  }
`);

export const STEP_APPEND_FILES_FRAGMENT = gql(`
  fragment StepAppendFiles on cma_reports {
    id
    cma_report_files(order_by: { order_nr: desc }) {
      cma_report_id
      file_id
      ...CmaReportFile
    }

    lead {
      id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);

export const CMA_REPORT_EDITOR_FRAGMENT = gql(`
  fragment CMAReportEditor on cma_reports {
    id
    updated_at
    lead {
      id
      property {
        id
        updated_at
        street_number
        route
        postcode
        locality
        updated_at
      }
    }

    doc_template {
      id
      content
      language
      filename_template
      reference
    }
  }
`);

export const STEP_INTRODUCTION_FRAGMENT = gql(`
  fragment StepIntroduction on cma_reports {
    id
    updated_at
    introduction
  }
`);

export const STEP_PROPERTY_DETAILS_FRAGMENT = gql(`
  fragment StepPropertyDetails on cma_reports {
    id
    updated_at
    comments
    lead {
      id
      property {
        id
        updated_at
      }
    }
  }
`);

export const STEP_OTHER_VALUATIONS_FRAGMENT = gql(`
  fragment StepOtherValuations on cma_reports {
    id
    updated_at
    include_cuprate_valuation
    caprate_estimated_monthly_rent
    caprate_minimum_required_yield
    include_intrinsic_valuation
    construction_cost_base
    intrinsic_building_volume
    intrinsic_building_surface
    intrinsic_construction_cost
    intrinsic_construction_year
    intrinsic_renovation_year
    intrinsic_annual_deprecation_rate
    intrinsic_land_surface
    intrinsic_land_value
    lead {
      id
      property {
        id
        updated_at
        latest_appraisal {
          id
          value
          rent_value
        }
        building_volume
        construction_year
        renovation_year
        land_surface
        living_surface
        usable_surface
        basement_surface
        commercial_surface
        balcony_surface
        garden_surface
        gross_floor_surface
        residential_surface
        terrace_surface
        weighted_floor_surface
      }
    }
  }
`);

export const STEP_FINAL_FRAGMENT = gql(`
  fragment StepFinal on cma_reports {
    id
    updated_at
    positive_comments
    negative_comments
    suggested_market_value
  }
`);

export const STEP_POTENTIAL_BUYERS_FRAGMENT = gql(`
  fragment StepPotentialBuyers on cma_reports {
    id
    updated_at
    max_budget
    min_budget
    include_potential_buyers
    suggested_market_value
    lead {
      id
      property {
        id
        latest_appraisal {
          id
          min
          max
        }
      }
    }
  }
`);

export const DELETE_CMA_REPORT = gql(`
  mutation DeleteCmaReport($id: uuid!) {
    delete_cma_reports_by_pk(id: $id) {
      id
    }
  }
`);

export const DOC_TEMPLATES_CMA_REPORT = gql(`
  query DocTemplatesCmaReport {
    doc_templates(
      where: { type: { _eq: cma_report }, is_shown_in_ui: { _eq: true } }
      order_by: {
        is_admin_default: desc_nulls_last
        is_default: desc_nulls_last
      }
    ) {
      id
      name
      language
      is_default
      is_admin_default
    }
  }
`);

export const GET_CMA_REPORT = gql(`
  query GetCmaReport($id: uuid!) {
    cma_reports_by_pk(id: $id) {
      id
      lead {
        id
        property {
          id
          street_number
          route
          postcode
          locality
        }
      }
      ...CMAReportEditor
      ...StepIntroduction
      ...StepCoverPhoto
      ...StepPropertyDetails
      ...StepHedonisticValuation
      ...StepOtherValuations
      ...StepFinal
      ...StepPotentialBuyers
      ...StepAppendFiles
    }
  }
`);

export const GET_PROPERTY_APPRAISABLE = gql(`
  query GetPropertyAppraisable($propertyId: uuid!) {
    property_appraisable(property_id: $propertyId) {
      appraisable
    }
  }
`);

// Mutations
export const UPDATE_CMA_COVER = gql(`
  mutation UpdateCmaStepCover($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepCoverPhoto
    }
  }
`);

export const STEP_COVER_INSERT_PROPERTY_IMAGE = gql(`
  mutation StepCoverInsertPropertyImage(
    $object: property_images_insert_input!
  ) {
    insert_property_images_one(object: $object) {
      id
      image {
        id
        url
      }
    }
  }
`);

export const UPDATE_CMA_INTRODUCTION = gql(`
  mutation UpdateCmaIntroduction($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepIntroduction
    }
  }
`);

export const UPDATE_PROPERTY_DETAILS = gql(`
  mutation UpdatePropertyDetails($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPropertyDetails
    }
  }
`);

export const UPDATE_HEDONISTIC_VALUATION = gql(`
  mutation UpdateHedonisticValuation(
    $id: uuid!
    $input: cma_reports_set_input!
  ) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepHedonisticValuation
    }
  }
`);

export const UPDATE_OTHER_VALUATIONS = gql(`
  mutation UpdateOtherValuations($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepOtherValuations
    }
  }
`);

export const UPDATE_FINAL = gql(`
  mutation UpdateFinal($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepFinal
    }
  }
`);

export const UPDATE_POTENTIAL_BUYERS = gql(`
  mutation UpdatePotentialBuyers($id: uuid!, $input: cma_reports_set_input!) {
    update_cma_reports_by_pk(pk_columns: { id: $id }, _set: $input) {
      ...StepPotentialBuyers
    }
  }
`);

export const STEP_ADD_CMA_REPORT_FILE = gql(`
  mutation StepAddCmaReportFile($object: cma_report_files_insert_input!) {
    insert_cma_report_files_one(object: $object) {
      cma_report_id
      file_id
      cma_report {
        id
        cma_report_files(order_by: { order_nr: desc }) {
          cma_report_id
          file_id
          ...CmaReportFile
        }
      }
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE = gql(`
  mutation StepUpdateCmaReportFile(
    $input: cma_report_files_set_input!
    $file_id: uuid!
    $cma_report_id: uuid!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: $input
    ) {
      returning {
        cma_report_id
        file_id
        cma_report {
          id
          cma_report_files(order_by: { order_nr: desc }) {
            cma_report_id
            file_id
            ...CmaReportFile
          }
        }
      }
    }
  }
`);

export const STEP_UPDATE_CMA_REPORT_FILE_VISIBILITY = gql(`
 mutation StepUpdateCmaReportFileVisibility(
    $file_id: uuid!
    $cma_report_id: uuid!
    $is_visible: Boolean!
  ) {
    update_cma_report_files(
      where: {
        file_id: { _eq: $file_id }
        cma_report_id: { _eq: $cma_report_id }
      }
      _set: { is_visible: $is_visible }
    ) {
      returning {
        cma_report_id
        file_id
        is_visible
      }
    }
	}`);

export const STEP_APPEND_ADD_PROPERTY_FILE = gql(`
  mutation StepAppendAddPropertyFile($object: property_files_insert_input!) {
    insert_property_files_one(object: $object) {
      property_id
      file_id
      property {
        id
        property_files(order_by: { order_nr: desc }) {
          property_id
          file_id
          ...CmaPropertyFile
        }
      }
    }
  }
`);
