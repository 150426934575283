import { createContext, useMemo } from 'react';
import {
  type IntrospectionType,
  type IntrospectionTypeRef,
} from 'graphql/utilities/getIntrospectionQuery';
import { useAppData } from '../../providers/AppDataProvider';

interface SchemaContext {
  getTypeFromPath: (path: string[]) => IntrospectionType | undefined;
  getTypeByName: (name: string) => IntrospectionType | undefined;
  resolveTypeName: (type: IntrospectionType) => string | null;
  getValueFromPath: (path: string[], where: any) => any;
}

export const SchemaContext = createContext<SchemaContext | undefined>(
  undefined,
);

export const SchemaProvider = ({ children }: { children: React.ReactNode }) => {
  // Initialize your caches here
  const typeNameCache = new Map();

  const { schema } = useAppData();
  const types = schema?.__schema?.types as IntrospectionType[];

  const getTypeByName = useMemo(() => {
    const typeByNameCache = new Map();
    return (name: string): IntrospectionType | undefined => {
      if (!types) {
        return undefined;
      }

      if (typeByNameCache.has(name)) {
        return typeByNameCache.get(name);
      }

      const type = types.find(t => t.name === name);
      typeByNameCache.set(name, type);

      if (!type) {
        throw new Error(`Could not find type with name ${name}`);
      }

      return type;
    };
  }, [types]);

  const resolveTypeName = (type: IntrospectionType | IntrospectionTypeRef) => {
    const typeKey = JSON.stringify(type);
    if (typeNameCache.has(typeKey)) {
      return typeNameCache.get(typeKey);
    }

    let currentType = type;

    while (currentType) {
      if (currentType.kind === 'LIST' || currentType.kind === 'NON_NULL') {
        currentType = currentType.ofType;
      } else if (currentType.name) {
        typeNameCache.set(typeKey, currentType.name);
        return currentType.name;
      } else {
        break;
      }
    }

    typeNameCache.set(typeKey, null);
    return null;
  };

  const getTypeFromPath = (path: string[]) => {
    const [baseType, ...rest] = path;
    let currentType = getTypeByName(baseType);

    rest.forEach(key => {
      if (currentType?.kind !== 'INPUT_OBJECT') {
        return;
      }
      const field = currentType?.inputFields?.find(f => f.name === key);
      if (!field) {
        return;
      }
      const typeName = resolveTypeName(field.type);
      currentType = getTypeByName(typeName);
    });

    return currentType;
  };

  const getValueFromPath = (path: string[], where: any) => {
    const [, ...rest] = path;
    let value = where;
    for (let i = 0; i < rest.length; i++) {
      value = value?.[rest[i]];
    }
    return value;
  };

  // Provide the memoized functions through context
  return (
    <SchemaContext.Provider
      value={{
        getTypeFromPath,
        getTypeByName,
        resolveTypeName,
        getValueFromPath,
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};
