import React, { useState, useEffect } from 'react';
import { useMutation, type DocumentNode } from '@apollo/client';
import { CSVImporter } from 'csv-import-react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  Alert,
  AlertTitle,
  Stack,
  CircularProgress,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useLocale } from '../../../src/hooks/locale';

type CSVColumnDataType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'string[]'
  | 'number[]'
  | undefined;

export type ColumnType = {
  key: string;
  name: string;
  required: boolean;
  description?: string;
  suggested_mappings?: readonly string[];
  data_type?: CSVColumnDataType;
};

type FromCSVColumnDataType<T> = T extends 'string'
  ? string | undefined
  : T extends 'number'
  ? number | undefined
  : T extends 'boolean'
  ? boolean | undefined
  : T extends 'string[]'
  ? string[] | undefined
  : T extends 'number[]'
  ? number[] | undefined
  : never;

type GeneratedFormattedDataType<C extends ReadonlyArray<ColumnType>> = {
  [K in C[number] as K['key']]: FromCSVColumnDataType<K['data_type']>;
};

export type CSVImportModalProps<
  TVariables,
  C extends ReadonlyArray<ColumnType>,
> = {
  objectLabel: string;
  open: boolean;
  columns: C;
  onImportSuccess: () => void;
  onImporterClose: () => void;
  mutationQuery: DocumentNode;
  constructMutationVariables: (
    formattedData: GeneratedFormattedDataType<C>[],
  ) => TVariables;
};

function transformValue(value: any, dataType: CSVColumnDataType) {
  if (value === 'null' || value === '') {
    return undefined;
  }

  switch (dataType) {
    case 'boolean':
      return ['true', 'TRUE'].includes(value);
    case 'number':
      return parseFloat(value);
    case 'string[]':
      return value.split(',');
    case 'number[]':
      return value.split(',').map((v: string) => parseFloat(v));
    case 'string':
    default:
      return value;
  }
}

export function CSVImportModal<TVariables, C extends ReadonlyArray<ColumnType>>(
  props: CSVImportModalProps<TVariables, C>,
) {
  const [showImporter, setShowImporter] = useState(true);
  const [insertData, { data, loading, error, reset }] = useMutation<
    null,
    TVariables
  >(props.mutationQuery);
  const { t } = useLocale();

  useEffect(() => {
    if (data) {
      props.onImportSuccess();
    }
  }, [data, props]);

  const handleImport = async (importData: {
    rows: { values: Record<string, any> }[];
  }) => {
    const formattedData = importData?.rows?.map(({ values }) => {
      const formattedListing = {} as GeneratedFormattedDataType<C>;

      // Assume values is of type Record<string, any>
      for (const [key, value] of Object.entries(values)) {
        const column = props.columns.find(c => c.key === key);
        if (!column) {
          continue;
        } // Skip if column not defined

        // Cast the key to the specific keyof GeneratedFormattedDataType<C>
        const keyOfType = key as keyof GeneratedFormattedDataType<C>;
        formattedListing[keyOfType] = transformValue(
          value,
          column.data_type,
        ) as GeneratedFormattedDataType<C>[typeof keyOfType];
      }

      return formattedListing;
    });

    setShowImporter(false);

    const variables = props.constructMutationVariables(formattedData);

    await insertData({
      variables,
    });
  };

  const handleClose = () => {
    props.onImporterClose();
    setShowImporter(true);
    reset();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth={showImporter ? 'lg' : 'md'}
      fullWidth
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          {t('Import {{label}}', {
            label: props.objectLabel,
          })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {!showImporter && (
        <Stack p={2} spacing={2}>
          {error && (
            <Alert severity="error">
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Alert>
          )}
          {error && (
            <Button
              variant="contained"
              onClick={() => {
                setShowImporter(true);
                reset();
              }}
            >
              {t('Try again')}
            </Button>
          )}
          {loading && (
            <Alert
              severity="info"
              icon={<CircularProgress size={18} disableShrink />}
            >
              <AlertTitle>{t('Importing')}</AlertTitle>
              {t('Importing data...')}
            </Alert>
          )}
          {data && <Alert severity="success">{t('Import successful')}</Alert>}
        </Stack>
      )}
      {showImporter && (
        <CSVImporter
          darkMode={false}
          onComplete={handleImport}
          isModal={false}
          template={{
            columns: props.columns,
          }}
        />
      )}
    </Dialog>
  );
}
