import * as React from 'react';
import { FormHelperText } from '@material-ui/core';
import { loginByPhone } from '@realadvisor/auth-client';
import { useLocale } from '../../hooks/locale';
import { PinInput } from './PinInput';

type Props = {
  phone: string;
  tenantId: string | null;
};

export const LoginVerifyCodeForm = (props: Props) => {
  const { t } = useLocale();
  const [{ error, commitIndex }, dispatch] = React.useReducer<
    React.Reducer<{ error: null | string; commitIndex: number }, string>
  >(
    (state, error) => ({
      error,
      commitIndex: state.commitIndex + 1,
    }),
    { error: null, commitIndex: 0 },
  );
  return (
    <>
      <PinInput
        key={commitIndex}
        length={4}
        onChange={code => {
          const { searchParams, origin } = new URL(location.href);
          loginByPhone({
            // normalize phone for db request
            number: props.phone.replace(/[^+\d]/g, ''),
            code,
            tenantId: props.tenantId,
          }).then(async response => {
            if (response.ok) {
              const returnTo = searchParams.get('return');
              if (props.tenantId != null) {
                if (returnTo != null) {
                  location.href = returnTo;
                } else {
                  location.href = '/';
                }
              } else {
                const redirectUri = new URL('/en/select-tenant', origin);
                if (returnTo != null) {
                  redirectUri.searchParams.set('return', returnTo);
                }
                location.href = redirectUri.href;
              }
            } else if (response.status >= 400) {
              const result = await response.json();
              let message = t('somethingWentWrong');
              if (result.error === 'EXPIRED_CODE') {
                message = t('authPhoneExpired');
              }
              if (result.error === 'WRONG_CODE') {
                message = t('authPhoneWrongCode');
              }
              if (result.error === 'WRONG_CODE_TOO_MANY_TIMES') {
                message = t('authPhoneWrongCodeManyTimes');
              }
              dispatch(message);
            }
          });
        }}
      />
      {error != null && <FormHelperText error={true}>{error}</FormHelperText>}
    </>
  );
};
