import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  CreditCardOutlined,
  DeleteForever,
  Edit,
  OpenInNew,
  PhotoLibraryOutlined,
  SettingsOutlined,
  StarOutlineRounded,
  VisibilityOutlined,
} from '@mui/icons-material';

import type { GetUserQuery } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import { useLocale } from '../../../src/hooks/locale';
import { getUUID, toGlobalId } from '../../../shared/global-id';
import {
  generateTab,
  type DrawerTab,
} from '../../components/drawer/DrawerTopBar';
import { useAppData } from '../../providers/AppDataProvider';
import { GET_USER } from './userQueries';
import { UserOverview } from './UserOverview';
import { UserSubscriptions } from './UserSubscriptions';
import { UserSettings } from './UserSettings';
import { DeletedUser } from './DeletedUser';
import { UserImages } from './UserImages';
import { UserReviews } from './UserReviews';

interface UserDrawerProps {
  maxWidth?: number;
}

export const UserDrawer = ({ maxWidth = 1200 }: UserDrawerProps) => {
  const navigate = useNavigate();
  const { t, language } = useLocale();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { me } = useAppData();

  const { userId } = useParams() as { userId: string };
  const id = getUUID(userId);

  const currentTab =
    matchPath('/teams/:teamId/overview/users/:userId/:tab', pathname)?.params
      .tab ??
    matchPath('/v2/users/:userId/:tab', pathname)?.params.tab ??
    'overview';

  const { data, error } = useQuery<GetUserQuery>(GET_USER, {
    variables: { id, includeSubscriptions: me?.is_admin },
  });

  const user = data?.users_by_pk;

  const drawerActions = [
    {
      label: 'Edit',
      disabled: false,
      icon: <Edit />,
    },
    {
      label: 'Delete',
      disabled: false,
      icon: <DeleteForever />,
    },
    {
      label: 'Open legacy view',
      icon: <OpenInNew />,
      onClick: () => open(`/${language}/v1/users/${toGlobalId('User', id)}}`),
    },
  ];

  const tabsData: [string, string, React.ReactElement?][] = [
    [t('Overview'), 'overview', <VisibilityOutlined />],
    [t('Images'), 'images', <PhotoLibraryOutlined />],
    [t('Subscriptions'), 'subscriptions', <CreditCardOutlined />],
    [t('Reviews'), 'reviews', <StarOutlineRounded />],
    [t('Settings'), 'settings', <SettingsOutlined />],
  ];

  const tabs: DrawerTab[] = tabsData
    .filter(([, value]) => value !== 'subscriptions' || me?.is_admin)
    .map(([label, value, icon]) =>
      generateTab([searchParams.toString(), label, value, icon]),
    );

  return (
    <Drawer
      maxWidth={maxWidth}
      onClose={() =>
        navigate({
          pathname: '../',
          search: searchParams.toString(),
        })
      }
      title={[t('User'), data?.users_by_pk?.full_name].join(': ')}
      actions={drawerActions}
      tabs={tabs}
      currentTab={currentTab}
    >
      {error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : (
        <>
          {user?.is_deleted && <DeletedUser />}
          <Routes>
            <Route
              index
              element={
                <Navigate
                  replace
                  to={{
                    pathname: './overview',
                    search: searchParams.toString(),
                  }}
                />
              }
            />
            <Route
              path="overview"
              element={<UserOverview user={data?.users_by_pk} />}
            />
            <Route
              path="subscriptions"
              element={<UserSubscriptions user={data?.users_by_pk} />}
            />
            <Route path="images" element={<UserImages />} />
            <Route path="reviews" element={<UserReviews />} />
            <Route path="settings" element={<UserSettings />} />
          </Routes>
        </>
      )}
    </Drawer>
  );
};
