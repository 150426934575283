import { CheckBoxOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';
import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';

export const BooleanFilter = ({
  label,
  path,
  addWhereClause,
  deleteWhereClause,
  where,
}: QuickFilterProps) => {
  const parentPath = path.slice(0, -1);
  const pathName = path[path.length - 1];

  const [, setFiltersParams] = useFiltersSearchParams();
  const { getValueFromPath } = useSchema();
  const parentOr = getValueFromPath([...parentPath, '_or'], where);

  const defaultTrueChecked = parentOr?.some(
    (or: any) => or[pathName]?._eq === true,
  );
  const defaultFalseChecked = parentOr?.some(
    (or: any) => or[pathName]?._eq === false,
  );
  const defaultNullChecked = parentOr?.some(
    (or: any) => or[pathName]?._is_null === true,
  );

  const apply = ({
    trueChecked = defaultTrueChecked,
    falseChecked = defaultFalseChecked,
    nullChecked = defaultNullChecked,
  }: {
    trueChecked?: boolean;
    falseChecked?: boolean;
    nullChecked?: boolean;
  }) => {
    let newWhere = where;
    newWhere = deleteWhereClause(newWhere, [...parentPath, '_or'], true);
    if (trueChecked) {
      newWhere = addWhereClause(newWhere, [...parentPath, '_or', pathName], {
        _eq: true,
      });
    }
    if (falseChecked) {
      newWhere = addWhereClause(newWhere, [...parentPath, '_or', pathName], {
        _eq: false,
      });
    }
    if (nullChecked) {
      newWhere = addWhereClause(newWhere, [...parentPath, '_or', pathName], {
        _is_null: true,
      });
    }
    setFiltersParams(newWhere);
  };

  const handleDelete = () => {
    const newWhere = deleteWhereClause(where, [...parentPath, '_or'], true);
    setFiltersParams(newWhere);
  };

  return (
    <FilterChip
      label={label}
      icon={<CheckBoxOutlined />}
      onDelete={
        defaultTrueChecked || defaultFalseChecked || defaultNullChecked
          ? handleDelete
          : undefined
      }
      renderFilter={() => (
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultTrueChecked}
                  onChange={e => {
                    apply({
                      trueChecked: e.target.checked,
                    });
                  }}
                />
              }
              label="True"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultFalseChecked}
                  onChange={e => {
                    apply({
                      trueChecked: defaultTrueChecked,
                      falseChecked: e.target.checked,
                      nullChecked: defaultNullChecked,
                    });
                  }}
                />
              }
              label="False"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultNullChecked}
                  onChange={e => {
                    apply({
                      trueChecked: defaultTrueChecked,
                      falseChecked: defaultFalseChecked,
                      nullChecked: e.target.checked,
                    });
                  }}
                />
              }
              label="null"
            />
          </FormGroup>
        </Paper>
      )}
    />
  );
};
