import { Skeleton, Typography } from '@mui/material';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

export const ActivitySkeleton = () => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ height: '6px', flexGrow: 0 }} />
        <Skeleton variant="circular" width={24} height={24} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Typography variant="body2" color="text.secondary">
          <Skeleton variant="text" width={350} />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Skeleton variant="text" width={150} />
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};
