import { Avatar, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { Link, type To } from 'react-router-dom';
import type {
  UserInfo_EnquiryFragment,
  UserInfo_UserFragment,
} from '../../__generated__/graphql';
import { USER_AVATAR_FRAGMENT, UserAvatar } from '../UserAvatar';
import {
  USER_EMAILS_FRAGMENT,
  USER_PHONE_NUMBERS_FRAGMENT,
} from '../contact-card/ContactCard';

export const USER_INFO_USER_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  ${USER_PHONE_NUMBERS_FRAGMENT}
  ${USER_EMAILS_FRAGMENT}
  fragment UserInfo_user on users {
    ...UserAvatar
    id
    ...UserPhoneNumbers
    ...UserEmails
  }
`;

export const USER_INFO_ENQUIRY_FRAGMENT = gql`
  fragment UserInfo_enquiry on enquiries {
    first_name
    last_name
    email_address
    phone_number
  }
`;

interface UserInfoProps {
  user?: UserInfo_UserFragment | null;
  enquiry?: UserInfo_EnquiryFragment | null;
  referrer?: null | string;
  enableLink?: boolean;
  to?: To;
  showPhone?: boolean;
  showEmail?: boolean;
}

export const UserInfo = (props: UserInfoProps) => {
  const {
    referrer,
    enableLink = false,
    user,
    enquiry,
    to,
    showEmail = true,
    showPhone = false,
  } = props;

  if (user != null && enquiry != null) {
    console.error('Only user or only enquiry should be passed to UserInfo');
    return null;
  }
  if (user == null && enquiry == null) {
    console.error('User or enquiry should be passed to UserInfo');
    return null;
  }

  const firstName = user?.first_name ?? enquiry?.first_name;
  const lastName = user?.last_name ?? enquiry?.last_name;
  const email = user?.emails?.[0]?.email ?? enquiry?.email_address;
  const phone = user?.phone_numbers?.[0]?.number ?? enquiry?.phone_number;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
        height: '100%',
      }}
    >
      <UserAvatar
        user={
          user ?? {
            first_name: firstName,
            last_name: lastName,
          }
        }
      />
      {referrer != null && (
        <Avatar
          style={{
            height: '100%',
            width: 18,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
          src={`https://www.google.com/s2/favicons?domain=${referrer}`}
        />
      )}
      <div style={{ minWidth: 0 }}>
        {enableLink ? (
          <Link to={to ? to : `/v2/users/${user?.id}`}>
            <Typography variant="body2" fontWeight={500} noWrap>
              {[firstName, lastName].join(' ')}
            </Typography>
          </Link>
        ) : (
          <Typography variant="body2" noWrap>
            {[firstName, lastName].join(' ')}
          </Typography>
        )}
        {showEmail && (
          <Typography
            variant="caption"
            color="text.secondary"
            noWrap
            paragraph
            marginBottom={0}
          >
            {email}
          </Typography>
        )}
        {showPhone && (
          <Typography
            variant="caption"
            color="text.secondary"
            noWrap
            paragraph
            marginBottom={0}
          >
            {phone}
          </Typography>
        )}
      </div>
    </div>
  );
};
