import { useState, useRef, useEffect } from 'react';
import type { SyntheticEvent, KeyboardEvent } from 'react';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Chip,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

type Props = {
  options: Array<{ label: string; value: string }>;
  initialSelected: string;
  onChange: (options: { label: string; value: string }) => void;
};

export const SelectChip = (props: Props) => {
  const { options, initialSelected, onChange } = props;
  const initialOption = options.find(o => o.value === initialSelected);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(initialOption);

  useEffect(() => {
    setSelected(options.find(o => o.value === initialSelected));
  }, [initialSelected, options]);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Chip
          ref={anchorRef}
          component="button"
          variant="outlined"
          aria-controls={open ? 'group-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          size="small"
          sx={{
            paddingRight: 0,
            '& .MuiChip-label': {
              paddingRight: '2px',
            },
          }}
          label={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              <span>{selected?.label}</span>
              <ArrowDropDown />
            </Stack>
          }
          onClick={handleToggle}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    dense
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {options.map(option => (
                      <MenuItem
                        key={option.value}
                        selected={option.value === selected?.value}
                        onClick={e => {
                          setSelected(option);
                          onChange(option);
                          handleClose(e);
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};
