import { useMutation, useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  VisibilityOutlined,
  DeleteForever,
  PhotoLibraryOutlined,
  SettingsOutlined,
  CellTower,
  Insights,
  RecordVoiceOverOutlined,
  StarOutlineRounded,
  LocalAtmOutlined,
} from '@mui/icons-material';

import type { DrawerGetTeamQuery } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import { useLocale } from '../../../src/hooks/locale';
import {
  generateTab,
  type DrawerTab,
} from '../../components/drawer/DrawerTopBar';
import { fromArrayToStringHasura } from '../../../shared/global-id';
import { DRAWER_GET_TEAM, DELETE_TEAM } from './teamQueries';
import { TeamOverview } from './TeamOverview';
import { TeamSettings } from './TeamSettings';
import { TeamImages } from './TeamImages';
import { TeamPortals } from './TeamPortals';
import { TeamStats } from './TeamStats';
import { TeamContacts } from './TeamContacts';
import { TeamReviews } from './TeamReviews';
import { TeamTransactions } from './TeamTransactions';

export const TeamDrawer = () => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const [searchParams] = useSearchParams();

  const { teamId } = useParams() as { teamId: string };

  const currentTab =
    useMatch('/teams/:teamId/:tab/*')?.params.tab ?? 'overview';

  const { data, error } = useQuery<DrawerGetTeamQuery>(DRAWER_GET_TEAM, {
    variables: { id: teamId },
  });

  const userIds = data?.teams_by_pk?.teams_users_aggregate?.nodes?.map(
    elem => elem.user_id as string,
  );

  const [deleteTeam] = useMutation(DELETE_TEAM);

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const drawerActions = [
    {
      label: t('Delete team'),
      disabled: false,
      onClick: () => {
        if (window.confirm(t('Are you sure you want to delete this team?'))) {
          // Delete team and remove from cache
          deleteTeam({
            variables: {
              teamId,
              userIds,
              userIdsString: fromArrayToStringHasura(userIds ?? []),
            },
            update: cache => {
              cache.evict({ id: `teams:${teamId}` });
              cache.gc();
            },
          }).then(() => {
            // Close drawer
            handleClose();
          });
        }
      },
      icon: <DeleteForever />,
    },
  ];

  const tabsData: [string, string, React.ReactElement?][] = [
    [t('Overview'), 'overview', <VisibilityOutlined />],
    [t('Images'), 'images', <PhotoLibraryOutlined />],
    [t('Settings'), 'settings', <SettingsOutlined />],
    [t('Transactions'), 'transactions', <LocalAtmOutlined />],
    [t('Portals'), 'portals', <CellTower />],
    [t('Stats'), 'stats', <Insights />],
    [t('Contacts'), 'valuation-requests', <RecordVoiceOverOutlined />],
    [t('Google Reviews'), 'reviews', <StarOutlineRounded />],
  ];

  const tabs: DrawerTab[] = tabsData.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  return (
    <Drawer
      onClose={handleClose}
      title={`${t('Team')} / ${data?.teams_by_pk?.name}`}
      actions={drawerActions}
      currentTab={currentTab}
      tabs={tabs}
    >
      {error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      ) : (
        <Routes>
          <Route
            index
            element={
              <Navigate
                replace
                to={{
                  pathname: './overview',
                  search: searchParams.toString(),
                }}
              />
            }
          />
          <Route path="overview/*" element={<TeamOverview />} />
          <Route path="images" element={<TeamImages />} />
          <Route path="settings" element={<TeamSettings />} />
          <Route path="transactions/*" element={<TeamTransactions />} />
          <Route path="portals/*" element={<TeamPortals />} />
          <Route path="stats/*" element={<TeamStats />} />
          <Route path="valuation-requests/*" element={<TeamContacts />} />
          <Route path="reviews" element={<TeamReviews />} />
        </Routes>
      )}
    </Drawer>
  );
};
