import { Alert, Grid } from '@mui/material';
import { Suspense } from 'react';
import type { GetUserQuery } from '../../__generated__/graphql';
import { FeedSkeleton } from '../../components/feed/FeedSkeleton';
import { ContactCard } from '../../components/contact-card/ContactCard';
import { LeadFeed } from '../leads/LeadFeed';
import { AgentFeed } from './AgentFeed';

interface UserOverviewProps {
  user: GetUserQuery['users_by_pk'];
}

export const UserOverview = ({ user }: UserOverviewProps) => {
  if (!user) {
    return null;
  }
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} md={4} maxWidth={400}>
        <ContactCard user={user} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Suspense fallback={<FeedSkeleton />}>
          {user?.is_broker || user?.is_admin ? (
            <AgentFeed userId={user.id} />
          ) : (
            <LeadFeed
              contact_id={user?.id}
              email_addresses={user?.emails?.map(e => e.email)}
            />
          )}
        </Suspense>
        <Alert severity="info">
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </Alert>
      </Grid>
    </Grid>
  );
};
