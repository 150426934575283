import { type ReactElement, useRef, useState } from 'react';
import { Button, Chip, Popover } from '@mui/material';
import { Close } from '@mui/icons-material';

interface FilterChipProps {
  label: string;
  icon?: ReactElement;
  onDelete?: () => void;
  renderFilter: (props: { handleClose: () => void }) => React.ReactNode;
}

export const FilterChip = ({
  label,
  icon,
  onDelete,
  renderFilter,
}: FilterChipProps) => {
  const anchor = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Chip
        icon={icon}
        ref={anchor}
        onClick={() => setOpen(true)}
        variant="outlined"
        label={label}
        onDelete={onDelete}
        deleteIcon={<Close />}
        component={Button}
        size="medium"
        color={onDelete ? 'primary' : 'default'}
        sx={{
          textTransform: 'none',
          fontWeight: 500,
          borderRadius: 2,
          '& .MuiSvgIcon-root': {
            fontSize: '1.3rem',
          },
          '&:hover': {
            background: 'white',
          },
          '&.MuiChip-root': {
            background: 'white',
          },
          '&.MuiChip-root:hover': {
            background: 'white',
          },
        }}
      />
      {open && (
        <Popover
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={anchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transitionDuration={0}
          slotProps={{
            paper: {
              style: {
                minWidth: 250,
                background: 'transparent',
                boxShadow: 'none',
                overflow: 'visible', // Make sure the suggestions overflow the Popover
                padding: '0 0 auto 0',
              },
            },
          }}
        >
          {open ? renderFilter({ handleClose: () => setOpen(false) }) : null}
        </Popover>
      )}
    </>
  );
};
