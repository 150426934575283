import { useQuery } from '@apollo/client';
import type { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import { RaDataGridPro } from '../../components/data-grid/RaDataGridPro';
import type {
  GetPropertyTransactionsListCountQuery,
  GetPropertyTransactionsQuery,
} from '../../__generated__/graphql';
import { useLocale } from '../../../src/hooks/locale';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { PropertyCell } from '../../components/data-grid/PropertyCell';
import {
  GET_PROPERTY_TRANSACTIONS,
  GET_PROPERTY_TRANSACTIONS_LIST_COUNT,
} from '../transactions/transactionQueries';
import { TransactionDrawer } from '../transactions/TransactionDrawer';
import { TransactionCreateDrawer } from '../transactions/TransactionCreateDrawer';
import { useAppData } from '../../providers/AppDataProvider';

type Transaction =
  GetPropertyTransactionsQuery['property_transactions'][number];

export const TeamTransactions = () => {
  const { t, intlLocale } = useLocale();
  const { teamId } = useParams();
  const { me } = useAppData();
  const isAdmin = me?.is_admin;

  const where = {
    _or: [
      { buyer_broker: { teams_users: { team_id: { _eq: teamId } } } },
      { seller_broker: { teams_users: { team_id: { _eq: teamId } } } },
    ],
  };

  const { data, loading, error } = useQuery<GetPropertyTransactionsQuery>(
    GET_PROPERTY_TRANSACTIONS,
    {
      variables: {
        where,
        order_by: [
          {
            created_at: 'desc',
          },
          {
            signature_date: 'desc',
          },
        ],
        limit: 1000,
        offset: 0,
      },
    },
  );

  const transactions: GridRowsProp<Transaction> =
    data?.property_transactions ?? [];

  const { data: countData } = useQuery<GetPropertyTransactionsListCountQuery>(
    GET_PROPERTY_TRANSACTIONS_LIST_COUNT,
    {
      variables: {
        where,
      },
    },
  );

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat(intlLocale, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  };

  const columns: GridColDef<Transaction>[] = [
    {
      field:
        'lot.property.postcode,lot.property.locality,lot.property.route,lot.property.street_number',
      headerName: 'Listing',
      width: 250,
      renderCell: ({ row }) => <PropertyCell property={row.property} />,
      sortable: false,
    },
    {
      field: 'seller_broker.last_name,seller_broker.first_name',
      headerName: t('Seller agent'),
      width: 250,
      renderCell: ({ row }) =>
        row.seller_broker && <UserInfo user={row.seller_broker} />,
      sortable: false,
    },
    {
      field: 'buyer_broker.last_name,buyer_broker.first_name',
      headerName: t('Buyer agent'),
      width: 250,
      renderCell: ({ row }) =>
        row.buyer_broker && <UserInfo user={row.buyer_broker} />,
      sortable: false,
    },
    {
      field: 'signature_date',
      headerName: 'Signature Date',
      width: 150,
      renderCell: ({ row }) => {
        if (
          row.signature_date != null ||
          row.estimated_signature_date != null
        ) {
          return formatDate(
            new Date(row.signature_date ?? row.estimated_signature_date),
          );
        } else {
          return '-';
        }
      },
      sortable: false,
    },
    {
      field: 'purchase_price',
      headerName: t('Purchase price'),
      width: 150,
      renderCell: ({ row }) => (
        <div
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          {row.purchase_price && Intl.NumberFormat().format(row.purchase_price)}
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        padding: 2,
      }}
    >
      {isAdmin && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            component={Link}
            to="new"
            disableElevation
          >
            <AddCircleOutlineOutlined sx={{ ml: -1, mr: 0.75 }} />
            {t('Transaction')}
          </Button>
        </Box>
      )}
      <RaDataGridPro
        loading={loading}
        rows={transactions}
        columns={columns}
        rowCount={
          countData?.property_transactions_aggregate?.aggregate?.count ?? 0
        }
      />
      <Routes>
        <Route path="new/*" element={<TransactionCreateDrawer />} />
        <Route
          path=":transactionId/*"
          element={<TransactionDrawer maxWidth={1200} />}
        />
      </Routes>
    </Box>
  );
};
