import * as React from 'react';
import type { FilledInputProps } from '@material-ui/core';
import { FilledInput, Popper, Paper } from '@material-ui/core';
import { Calendar } from '@material-ui/pickers';
import { useRifm } from 'rifm';
import { parse, format, isValid } from 'date-fns';
import { useClickOutsideObserver } from '@realadvisor/observe';
import { useTheme } from '../hooks/theme';

type Props = {
  Input?: (props: FilledInputProps) => JSX.Element;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

const DATE_FORMAT = 'dd/MM/yyyy';

export const parseDate = (date: string): null | Date => {
  const parsed = parse(date, DATE_FORMAT, Date.now());
  if (!isValid(parsed)) {
    return null;
  }
  return parsed;
};

// any here fixes "recursion limit exceeded"
export const formatDate = (date: Date | number): any => {
  return isValid(date) ? format(date, DATE_FORMAT) : '';
};

const refuse = /[^\d]+/gi;

const parseDateString = (dateString: string) => dateString.replace(refuse, '');

const formatTimeString = (dateString: string) => {
  const parsed = parseDateString(dateString);
  const days = parsed.slice(0, 2).padEnd(2, '_');
  const months = parsed.slice(2, 4).padEnd(2, '_');
  const years = parsed.slice(4, 8).padEnd(4, '_');
  return `${days}/${months}/${years}`;
};

const useNow = () => {
  const nowRef = React.useRef<null | Date>(null);
  if (nowRef.current == null) {
    const now = new Date();
    nowRef.current = now;
    return now;
  } else {
    return nowRef.current;
  }
};

export const DateInput = (props: Props) => {
  const inputRef = React.useRef(null);
  const popupRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const now = useNow();
  const { depth } = useTheme();
  const Input = props.Input ?? FilledInput;
  const rifm = useRifm({
    format: formatTimeString,
    value: props.value,
    onChange: newValue => props.onChange(newValue),
  });

  useClickOutsideObserver([inputRef, popupRef], () => setOpen(false));

  return (
    <>
      <Input
        inputRef={inputRef}
        value={rifm.value}
        onChange={rifm.onChange}
        onKeyDown={event => {
          if (event.key === ' ') {
            event.preventDefault();
            // toggle because escape propagates to modals
            setOpen(v => !v);
          }
          if (event.key === 'Enter' && open === true) {
            event.preventDefault();
            setOpen(false);
          }
          if (event.key === 'Escape' && open === true) {
            setOpen(false);
          }
        }}
        onFocus={() => setOpen(true)}
        onBlur={() => {
          if (hovered === false) {
            setOpen(false);
          }
          if (props.onBlur) {
            props.onBlur();
          }
        }}
      />

      <Popper
        open={open}
        anchorEl={inputRef.current}
        css={{ zIndex: depth.inputPopup }}
      >
        <Paper
          ref={popupRef}
          css={{ overflow: 'hidden' }}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Calendar
            date={parseDate(props.value) ?? now}
            onChange={(date, isFinish) => {
              if (date != null) {
                props.onChange(formatDate(date));
              }
              if (isFinish) {
                setOpen(false);
              }
            }}
          />
        </Paper>
      </Popper>
    </>
  );
};
