import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  ButtonBase,
  keyframes,
  useTheme,
  CircularProgress,
  Card,
  CardActionArea,
  Button,
} from '@mui/material';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { useResizeRect } from '@realadvisor/observe';
import { type UseFormSetValue, useFormContext } from 'react-hook-form';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useMutation } from '@apollo/client';
import { useLocale } from '../../../src/hooks/locale';
import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { FilePdf } from '../../../icons/file-pdf';
import { useCMATemplateViewerDialog } from '../../../src/routes/Documents/cma-template-viewer-context';
import { customPalette } from '../../../src/styles';
import {
  UPDATE_CMA_COVER,
  UPDATE_CMA_INTRODUCTION,
  UPDATE_FINAL,
  UPDATE_HEDONISTIC_VALUATION,
  UPDATE_OTHER_VALUATIONS,
  UPDATE_POTENTIAL_BUYERS,
  UPDATE_PROPERTY_DETAILS,
} from './cmaReportsQueries';

export type PageId =
  | 'page-cover'
  | 'page-introduction'
  | 'page-property'
  | 'page-hedonistic-valuation'
  | 'page-comparables-list'
  | 'page-other-valuations'
  | 'page-final'
  | 'page-potential-buyers'
  | 'page-append-files';

export const useUpdateCmaReport = (
  page: Exclude<PageId, 'page-comparables-list' | 'page-append-files'>,
) => {
  const cmaMutations = {
    'page-cover': useMutation(UPDATE_CMA_COVER),
    'page-introduction': useMutation(UPDATE_CMA_INTRODUCTION),
    'page-property': useMutation(UPDATE_PROPERTY_DETAILS),
    'page-hedonistic-valuation': useMutation(UPDATE_HEDONISTIC_VALUATION),
    'page-other-valuations': useMutation(UPDATE_OTHER_VALUATIONS),
    'page-potential-buyers': useMutation(UPDATE_POTENTIAL_BUYERS),
    'page-final': useMutation(UPDATE_FINAL),
  } as const;
  const [updateCmaReport, { loading: updating }] = cmaMutations[page];

  return [updateCmaReport, updating] as const;
};

type LoadingCardProps = {
  loading: boolean;
  children: React.ReactNode;
};

export const LoadingCard: React.FC<LoadingCardProps> = ({
  loading,
  children,
}) => {
  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        animation: `.8s ease-out 0s 1 ${fadeIn};`,
        borderRadius: '10px',
      }}
    >
      {loading && (
        <Box sx={{ position: 'absolute', top: 12, right: 12, zIndex: 1 }}>
          <CircularProgress variant="indeterminate" size={24} />
        </Box>
      )}
      <Box sx={{ opacity: loading ? 0.6 : 'initial', display: 'flex' }}>
        {children}
      </Box>
    </Box>
  );
};

export const SaveIndicator = ({ loading }: { loading: boolean }) => {
  const [key, setKey] = useState('');
  const { t } = useLocale();
  const { typography } = useTheme();

  const prevLoadingRef = useRef(loading);

  useEffect(() => {
    if (prevLoadingRef.current === true && loading === false) {
      setKey(Math.random().toString());
    }
    prevLoadingRef.current = loading;
  }, [loading]);

  const fadeInOut = keyframes`
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `;

  return key ? (
    <Box
      key={key}
      sx={[
        typography.subtitle2,
        { opacity: 0 },
        { animation: `3s linear 2s 1 ${fadeInOut}` },
      ]}
    >
      {t('saved')}
    </Box>
  ) : null;
};

export const FadeIn = (props: {
  children: Exclude<React.ReactNode, undefined>;
  disabled: boolean;
  disableAnimation: boolean;
}) => {
  if (props.disableAnimation === true) {
    return <>{props.children ?? null}</>;
  }

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  return (
    <div
      css={[
        {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          animation: `.5s ease 1 ${fadeIn}`,
          overflowY: 'auto',
          position: 'relative',
          transition: 'background-color 0.5s ease',
          backgroundColor: customPalette.superLightBlue,
        },
        props.disabled && {
          backgroundColor: '#eee',
        },
      ]}
    >
      {props.children}
    </div>
  );
};

const MAX_SCROLL_BAR_SIZE = 32;
const FILTER_SCROLL_DISTANCE = 150;

export const HorizontalScrollContainer = ({
  height,
  children,
  nodeIndex,
}: {
  nodeIndex: number;
  height: number;
  children: React.ReactNode;
}) => {
  const { palette } = useTheme();
  const [showScrollLeft, setShowScrollLeft] = useState(false);
  const [showScrollRight, setShowScrollRight] = useState(false);
  const menuContainerRef = useRef<HTMLElement | null>(null);
  const menuRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const firstChildObserver = new IntersectionObserver(
      ([entry]) => {
        setShowScrollLeft(!entry.isIntersecting);
      },
      { threshold: 0.7 },
    );

    const lastChildObserver = new IntersectionObserver(
      ([entry]) => {
        setShowScrollRight(!entry.isIntersecting);
      },
      { threshold: 0.7 },
    );

    const firstChildElement = menuRef.current?.firstElementChild;
    const lastChildElement = menuRef.current?.lastElementChild;

    if (firstChildElement != null && lastChildElement != null) {
      firstChildObserver.observe(firstChildElement);
      lastChildObserver.observe(lastChildElement);

      return () => {
        firstChildObserver.unobserve(firstChildElement);
        lastChildObserver.unobserve(lastChildElement);
      };
    }
  }, [menuRef]);

  useEffect(() => {
    const node = menuRef.current?.children[nodeIndex];
    node?.scrollIntoView({ block: 'end' });
  }, [menuRef, nodeIndex]);

  const baseArrowButtonStyles = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    width: 36,
  };

  return (
    <div
      css={{ overflow: 'hidden', position: 'relative', height: `${height}px` }}
    >
      <Box
        display="flex"
        ref={menuContainerRef}
        alignItems="center"
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth',
          height: '100%',
          boxSizing: 'content-box',
          paddingBottom: `${scrollbarSize() || MAX_SCROLL_BAR_SIZE}px`,
        }}
      >
        <Box display="flex" ref={menuRef} pr={3} sx={{ minWidth: '100%' }}>
          {children}
        </Box>
      </Box>
      {showScrollLeft && (
        <ButtonBase
          onClick={() => {
            if (menuContainerRef.current) {
              menuContainerRef.current.scrollBy(-FILTER_SCROLL_DISTANCE, 0);
            }
          }}
          sx={{
            ...baseArrowButtonStyles,
            background:
              'linear-gradient(90deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
            left: 0,
          }}
        >
          <Box
            css={{
              width: 0,
              height: 0,
              borderTop: '6px solid transparent',
              borderBottom: '6px solid transparent',
              borderRight: `6px solid ${palette.text.secondary}`,
            }}
          />
        </ButtonBase>
      )}
      {showScrollRight && (
        <ButtonBase
          onClick={() => {
            if (menuContainerRef.current) {
              menuContainerRef.current.scrollBy(FILTER_SCROLL_DISTANCE, 0);
            }
          }}
          sx={{
            ...baseArrowButtonStyles,
            background:
              'linear-gradient(270deg, rgba(246,247,249,1) 70%, rgba(246,247,249,0) 100%)',
            borderRight: `1px solid ${palette.grey[300]}`,
            right: 0,
          }}
        >
          <div
            css={{
              width: 0,
              height: 0,
              borderTop: '6px solid transparent',
              borderBottom: '6px solid transparent',
              borderLeft: `6px solid ${palette.text.secondary}`,
            }}
          />
        </ButtonBase>
      )}
    </div>
  );
};

// found somewhere on stackoverflow:
// scrollIntoView that returns Promise for tracking animation completion
const smoothScroll = (elem: Element, options: any, cb: () => void) => {
  let same = 0; // a counter
  let lastPos: number | null = null; // last known Y position
  // pass the user defined options along with our default
  const scrollOptions = { behavior: 'smooth', block: 'center', ...options };

  // let's begin
  elem.scrollIntoView(scrollOptions);
  requestAnimationFrame(check);

  // this function will be called every painting frame
  // for the duration of the smooth scroll operation
  function check() {
    // check our current position
    const newPos = elem.getBoundingClientRect().top;

    if (newPos === lastPos) {
      // same as previous
      same += 1; // increase counter
      if (same > 2) {
        return cb(); // we've come to an halt
      }
    } else {
      same = 0; // reset our counter
      lastPos = newPos; // remember our current position
    }
    // check again next painting frame
    requestAnimationFrame(check);
  }
};

export function useMarkupRenderer(
  iFrameRef: React.MutableRefObject<HTMLIFrameElement>,
  markup: string | null,
  visiblePageId: PageId | null,
) {
  const iFrameRect = useResizeRect(iFrameRef);
  useEffect(() => {
    const iFrameDocument = iFrameRef.current.contentDocument;
    if (iFrameDocument == null) {
      return;
    }
    iFrameDocument.documentElement.innerHTML = markup ?? 'Fetching data...';
    if (iFrameRect != null && markup != null) {
      const iFrameBody = iFrameDocument.body;
      const iFramePage = iFrameBody.querySelector(
        `page#${visiblePageId ?? ''}`,
      );
      const pages = iFrameBody.querySelectorAll(
        'page',
      ) as unknown as HTMLDivElement[];
      for (const page of Array.from(pages)) {
        page.style.margin = '4mm 0';
        if (page.id !== visiblePageId) {
          page.style.display = 'none';
        }
      }

      if (iFramePage != null) {
        const { width: containerWidth } = iFrameRect;
        const { width: pageWidth } = iFramePage.getBoundingClientRect();
        const { marginLeft, marginRight } = getComputedStyle(iFramePage);
        const margin =
          Math.abs(Number.parseFloat(marginLeft)) +
          Math.abs(Number.parseFloat(marginRight));
        const fullPageWidth = Math.ceil(pageWidth + margin);
        const scale = Math.min(containerWidth / fullPageWidth, 1);
        iFrameBody.style.transform = `scale(${scale - 0.15})`;
        iFrameBody.style.transformOrigin = '50% 20px';
        iFrameBody.style.overflow = 'hidden';
        iFrameBody.style.display = 'flex';
        iFrameBody.style.flexDirection = 'column';
        iFrameBody.style.justifyContent = 'center';
        iFrameBody.style.alignItems = 'center';
      }
    }
  }, [iFrameRect, iFrameRef, markup, visiblePageId]);
}

export function useMarkupRendererWithScroll(
  iFrameRef: React.MutableRefObject<HTMLIFrameElement>,
  markup: string | null,
  pageId: PageId,
  setPageId: (pageId: PageId) => void,
) {
  const iFrameRect = useResizeRect(iFrameRef);
  const scrolling = useRef(false);
  const page = useRef(0);
  const scrollIntoView = () => {
    const iFrameDocument = iFrameRef.current.contentDocument;
    const pageEl = iFrameDocument?.body.querySelector(
      `#${pageId}`,
    ) as HTMLDivElement;
    pageEl?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    const pages = Array.from(
      iFrameDocument?.body.querySelectorAll('page') ?? [],
    ).filter(el => (el as HTMLDivElement)?.style.display !== 'none');
    page.current = pages.findIndex(el => el.id === pageId);
  };
  const scrollIntoViewDebounced = useDebouncedHandler(100, scrollIntoView);
  const smoothScrollDebounced = useDebouncedHandler(200, smoothScroll);

  useLayoutEffect(() => {
    if (markup == null) {
      return;
    }
    const iFrameDocument = iFrameRef.current.contentDocument;
    if (iFrameDocument == null) {
      return;
    }
    iFrameDocument.documentElement.innerHTML = markup;
    const htmlEl = iFrameDocument.querySelector('html');
    if (htmlEl == null) {
      return;
    }
    htmlEl.style.overflow = 'hidden';

    // for some reason it doesn't inherit in this iframe by default
    const styleEl = document.createElement('style');
    iFrameDocument.head.appendChild(styleEl);
    styleEl.sheet?.insertRule('* { font-size: inherit; }', 0);

    scrollIntoViewDebounced();

    const handler = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.deltaY > 30 || event.deltaY < -10) {
        if (scrolling.current === true) {
          return;
        }
        // block scroll
        scrolling.current = true;
        // on wheel event scroll up or down depends on the delta
        // once scroll is done, unlock it and fire page change event
        setTimeout(() => {
          const pages = Array.from(
            iFrameDocument.body.querySelectorAll('page'),
          ).filter(el => (el as HTMLDivElement).style.display !== 'none');
          if (event.deltaY < 1) {
            const prevPage = pages[page.current - 1];
            if (prevPage != null) {
              smoothScrollDebounced(prevPage, {}, () => {
                scrolling.current = false;
                page.current -= 1;
                setPageId(prevPage.id as PageId);
              });
            } else {
              scrolling.current = false;
            }
          } else {
            const nextPage = pages[page.current + 1];
            if (nextPage != null) {
              smoothScrollDebounced(nextPage, {}, () => {
                scrolling.current = false;
                page.current += 1;
                setPageId(nextPage.id as PageId);
              });
              scrolling.current = false;
            } else {
              scrolling.current = false;
            }
          }
        }, 0);
      }
    };

    iFrameDocument.addEventListener('wheel', handler, {
      passive: false,
    });

    return () => {
      iFrameDocument.removeEventListener('wheel', handler);
    };
  }, [
    markup,
    pageId,
    iFrameRect,
    iFrameRef,
    scrollIntoViewDebounced,
    smoothScrollDebounced,
    scrolling,
    page,
    setPageId,
  ]);

  useLayoutEffect(() => {
    const iFrameDocument = iFrameRef.current.contentDocument;
    const pages = Array.from(
      iFrameDocument?.body.querySelectorAll('page') ?? [],
    ).filter(el => (el as HTMLDivElement).style.display !== 'none');
    const iFrameBody = iFrameDocument?.body;
    if (iFrameBody == null) {
      return;
    }

    if (iFrameRect != null && markup != null) {
      const [iFramePage] = pages;
      if (iFramePage != null) {
        // center our pages with required scaling
        const { width: containerWidth, height: containerHeight } = iFrameRect;
        const { width: pageWidth, height: pageHeight } =
          iFramePage.getBoundingClientRect();
        const { marginLeft, marginRight, marginTop, marginBottom } =
          getComputedStyle(iFramePage);
        const marginX =
          Math.abs(Number.parseFloat(marginLeft)) +
          Math.abs(Number.parseFloat(marginRight));
        const marginY =
          Math.abs(Number.parseFloat(marginTop)) +
          Math.abs(Number.parseFloat(marginBottom));
        const fullPageWidth = Math.ceil(pageWidth + marginX);
        const fullPageHeight = Math.ceil(pageHeight + marginY);
        const scaleX = Math.min(containerWidth / fullPageWidth, 1);
        const scaleY = Math.min(containerHeight / fullPageHeight, 1);

        let scale = scaleX;
        // set scale Y only if we have a single page
        scale = Math.min(scaleY, scaleX);

        iFrameBody.style.transform = `scale(${scale - 0.01})`;
        iFrameBody.style.transformOrigin = '25% 0';
      }
    }
  }, [iFrameRect, iFrameRef, markup, pageId, setPageId]);
}

export const SelectableCard = ({
  selected,
  onChange,
  children,
}: {
  selected: boolean;
  onChange: (value: boolean) => void;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const bg = selected ? theme.palette.primary.main : theme.palette.grey[400];
  return (
    <Card
      css={[
        {
          position: 'relative',
          cursor: 'pointer',
          border: `4px transparent solid`,
          margin: -4,
          transition: 'all .15s ease',
          height: 200,
        },
        selected
          ? {
              borderColor: bg,
            }
          : {
              '&:hover': {
                borderColor: bg,
              },
            },
      ]}
      elevation={0}
      onClick={() => onChange(!selected)}
    >
      {selected && (
        <Box
          position="absolute"
          zIndex={1}
          top={0}
          left={0}
          height={60}
          width={60}
          sx={{
            transform: 'rotate(45deg) translateX(-70%)',
            background: bg,
          }}
        />
      )}
      <CardActionArea sx={{ height: '100%' }}>
        <Box
          position="relative"
          overflow="hidden"
          height="100%"
          sx={{
            borderRadius: '4px',
            bordeTopLeftRadius: selected ? 0 : '4px',
            backgroundColor: bg,
          }}
        >
          {children}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export interface CMAReportComponentProps {
  step: number;
  setStep: (step: number) => void;
  cmaReportId: string;
  data: {
    id: string;
  };
  ActionsComponent: React.FC<{ children: React.ReactNode }>;
  iFrameRef: React.MutableRefObject<HTMLIFrameElement>;
}

type FooterMetaProps<FooterProps> = {
  updating: boolean;
  hideBackButton?: boolean;
  resetProps?: {
    onReset: () => void;
  };
} & (
  | {
      downloadButton: true;
      onSubmit?: never;
    }
  | {
      downloadButton?: never;
      onSubmit: (formData: FooterProps) => Promise<void>;
    }
);

export type FooterActionsRef<FooterProps extends {}> = {
  setValue: UseFormSetValue<FooterProps>;
};

const FooterActionsWithRef = <FooterProps extends {} = {}>(
  props: CMAReportComponentProps & FooterMetaProps<FooterProps>,
  ref: React.Ref<FooterActionsRef<FooterProps>>,
) => {
  const {
    step,
    setStep,
    updating,
    onSubmit,
    resetProps,
    downloadButton,
    hideBackButton,
  } = props;
  const { t } = useLocale();
  const { setTemplateOpen } = useCMATemplateViewerDialog();
  const { handleSubmit, setValue } = useFormContext<FooterProps>();

  useImperativeHandle(ref, () => ({ setValue }));

  const onSubmitHandler = async (formData: FooterProps) => {
    await onSubmit?.(formData);
    setStep(step + 1);
  };

  return (
    <props.ActionsComponent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        p={1}
      >
        {hideBackButton !== true ? (
          <Button startIcon={<ChevronLeft />} onClick={() => setStep(step - 1)}>
            {t('back')}
          </Button>
        ) : (
          <Box />
        )}
        <SaveIndicator loading={updating} />
        <Box css={{ gap: 8, display: 'flex' }}>
          {resetProps != null && (
            <Button
              variant="text"
              onClick={() => resetProps.onReset()}
              disabled={updating}
            >
              {t('reset')}
            </Button>
          )}
          {downloadButton !== true ? (
            <Button
              endIcon={<ChevronRight />}
              variant="contained"
              onClick={handleSubmit(onSubmitHandler)}
            >
              {t('next')}
            </Button>
          ) : (
            <Button
              startIcon={<FilePdf />}
              variant="contained"
              onClick={() => setTemplateOpen(true)}
              size="large"
            >
              {t('viewAndDownload')}
            </Button>
          )}
        </Box>
      </Box>
    </props.ActionsComponent>
  );
};

export const FooterActions = forwardRef(FooterActionsWithRef) as <
  FooterProps extends {},
>(
  props: CMAReportComponentProps &
    FooterMetaProps<FooterProps> & {
      ref?: React.ForwardedRef<FooterActionsRef<FooterProps>>;
    },
) => React.ReactElement;
