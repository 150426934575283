import * as React from 'react';
import { Stack, Select, MenuItem, TextField } from '@mui/material';
import type { ConditionOperator } from '../utils/parseWhereClause';

export type StringOperator = Extract<
  ConditionOperator,
  '_eq' | '_neq' | '_ilike' | '_nilike' | '_iregex' | '_niregex'
>;

type FilterStringFormProps = {
  value: string;
  operator: StringOperator | null;
  onChange: (params: { value: string; operator: StringOperator }) => void;
};

const transformValue = (value: string, operator: StringOperator) => {
  if (operator === '_ilike' || operator === '_nilike') {
    return `%${value}%`;
  }
  return value;
};

const reverseTransformValue = (value: string, operator: StringOperator) => {
  if (operator === '_ilike' || operator === '_nilike') {
    return value.replace(/%/g, '');
  }
  return value;
};

export const FilterStringForm: React.FC<FilterStringFormProps> = ({
  value: initialValue,
  operator: initialOperator,
  onChange,
}) => {
  const [value, setValue] = React.useState(
    reverseTransformValue(initialValue, initialOperator ?? '_ilike'),
  );
  const [operator, setOperator] = React.useState(initialOperator);

  return (
    <Stack direction="column" alignItems="center" spacing={1} paddingY={2}>
      <Select
        size="small"
        variant="filled"
        fullWidth
        value={operator}
        //@ts-ignore
        hiddenLabel
        onChange={event => {
          const selectedOperator = event.target.value as StringOperator;
          if (
            [
              '_eq',
              '_neq',
              '_ilike',
              '_nilike',
              '_iregex',
              '_niregex',
            ].includes(selectedOperator)
          ) {
            setOperator(selectedOperator);
            onChange({
              value: transformValue(value, selectedOperator),
              operator: selectedOperator,
            });
          }
        }}
      >
        {[
          { value: '_ilike', label: 'contains' },
          {
            value: '_nilike',
            label: "doesn't contain",
          },
          { value: '_eq', label: 'is exactly' },
          { value: '_neq', label: 'is not exactly' },
          { value: '_iregex', label: 'matches regex' },
          { value: '_niregex', label: "doesn't match regex" },
        ].map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        size="small"
        fullWidth
        value={value}
        hiddenLabel
        onChange={event => {
          if (operator != null) {
            setValue(event.target.value);
            onChange({
              value: transformValue(event.target.value, operator),
              operator,
            });
          }
        }}
      />
    </Stack>
  );
};
