import { Button, Grid } from '@mui/material';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { Node } from './Node';

interface AdvancedFiltersProps {
  where: any;
  baseTypeName: string;
  setWhere: (where: any) => void;
}

export const AdvancedFilters = ({
  baseTypeName,
  where,
  setWhere,
}: AdvancedFiltersProps) => {
  const [, setFiltersSearch] = useFiltersSearchParams();

  return (
    <Grid container spacing={1} padding={1}>
      <Grid item xs={12} md={6}>
        <Node
          name="where"
          node={where}
          path={[baseTypeName]}
          setWhere={setWhere}
          where={where}
        />
        <Button
          onClick={() => setFiltersSearch(where)}
          sx={{ marginTop: 1 }}
          size="small"
        >
          Apply Filters
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <pre style={{ backgroundColor: '#f5f5f5', margin: 0 }}>
          <code>{JSON.stringify(where, null, 2)}</code>
        </pre>
      </Grid>
    </Grid>
  );
};
