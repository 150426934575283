import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Paper, MenuList } from '@material-ui/core';
import {
  computePosition,
  autoUpdate,
  size,
  shift,
  flip,
} from '@floating-ui/dom';
import { useClickOutsideObserver } from '@realadvisor/observe';
import { useTheme } from '../hooks/theme';

export const focusWrappedInputRef = (ref: { current: any | null }) => {
  const element = ref.current;
  if (element) {
    const input = element.querySelector('input') || element;
    // the function is called when click on clear button in inputs
    // if input already was focused blur event is fired after click
    // here we schedule focus after blur to fix events order
    setTimeout(() => input.focus());
  }
};

export const Portal = ({ children }: { children: React.ReactNode }) => {
  if (typeof document !== 'undefined' && document.body) {
    return ReactDOM.createPortal(children, document.body);
  } else {
    return null;
  }
};

export const InputPopup = ({
  referenceRef,
  open,
  children,
}: {
  referenceRef: { current: HTMLElement | null };
  open: boolean;
  children: React.ReactNode;
}) => {
  const { depth } = useTheme();
  const popupRef = React.useRef<null | HTMLElement>(null);
  React.useEffect(() => {
    const reference = referenceRef.current;
    const popup = popupRef.current;
    if (open && reference != null && popup != null) {
      const update = () => {
        computePosition(reference, popup, {
          middleware: [
            flip(),
            size({
              apply: ({ availableHeight, elements }) => {
                const { reference } = elements;
                const rect = reference.getBoundingClientRect();
                popup.style.width = `${rect.width}px`;
                popup.style.maxHeight = `${availableHeight}px`;
              },
            }),
          ],
        }).then(({ strategy, x, y }) => {
          popup.style.position = strategy;
          popup.style.left = `${x}px`;
          popup.style.top = `${y}px`;
        });
      };
      update();
      const cleanup = autoUpdate(reference, popup, update);
      return cleanup;
    }
  }, [open, referenceRef]);
  return (
    <Portal>
      <Paper
        ref={popupRef}
        css={{
          zIndex: depth.inputPopup,
          display: open ? 'flex' : 'none',
          // add vertical scroll to child list to enable scrollIntoView
          '> *': {
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
          },
        }}
        aria-hidden={open === false}
        // fix the problem when input popup is click outside for filter
        // TODO remove when flare InteractOutside is out
        data-invisible-for-click-outside={true}
      >
        {children}
      </Paper>
    </Portal>
  );
};

const useStableCallback = (callback: () => void): (() => void) => {
  const ref = React.useRef(callback);
  React.useLayoutEffect(() => {
    ref.current = callback;
  });
  return React.useCallback(() => ref.current(), []);
};

export const Menu = ({
  referenceRef,
  open,
  onClose,
  children,
}: {
  referenceRef: { current: HTMLElement | null };
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const { depth } = useTheme();
  const popupRef = React.useRef<null | HTMLElement>(null);
  const menuRef = React.useRef<null | HTMLUListElement>(null);
  React.useLayoutEffect(() => {
    const reference = referenceRef.current;
    const popup = popupRef.current;
    if (open && reference != null && popup != null) {
      const update = () => {
        computePosition(reference, popup, {
          middleware: [shift()],
        }).then(({ strategy, x, y }) => {
          popup.style.position = strategy;
          popup.style.left = `${x}px`;
          popup.style.top = `${y}px`;
        });
      };
      update();
      // focus menu when position is set
      requestAnimationFrame(() => {
        menuRef.current?.focus();
      });
      const cleanup = autoUpdate(reference, popup, update);
      return cleanup;
    }
  }, [open, referenceRef]);
  const onEscape = useStableCallback(onClose);
  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          // restore focus to reference after closing menu
          referenceRef.current?.focus();
          onEscape();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [referenceRef, open, onEscape]);
  useClickOutsideObserver([referenceRef, popupRef], onClose);
  if (open === false) {
    return null;
  }
  return (
    <Portal>
      <Paper
        ref={popupRef}
        elevation={3}
        css={{ width: 'max-content', zIndex: depth.tooltip }}
        onClick={onClose}
      >
        {/* menu appearance already means focus; outline does not fit design */}
        <MenuList ref={menuRef} css={{ outline: 0 }}>
          {children}
        </MenuList>
      </Paper>
    </Portal>
  );
};
