// @flow

import * as React from 'react';
import {
  FilledInput,
  List,
  ListItem,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { useCombobox } from 'downshift';
import {
  type AutocompletePrediction,
  useGoogleAutocomplete,
  useGoogleGeocoder,
} from '@realadvisor/hooks';
import { Cancel } from '../icons/cancel';
import { GOOGLE_MAPS_TOKEN } from '../config';
import {
  type ParsedGeocoderResult,
  parseGeocoderResult,
} from '../utils/geocoder-result';
import { useTheme } from '../hooks/theme';
import { InputPopup } from '../controls/popup';

type LatLng = {|
  lat: number,
  lng: number,
|};

type LatLngBoundsLiteral = {|
  east: number,
  north: number,
  south: number,
  west: number,
|};

type Props = {|
  Input?: React.AbstractComponent<any>,
  css?: any,
  className?: string,
  value: string,
  location?: LatLng,
  bounds?: LatLngBoundsLiteral,
  country?: string,
  types?: Array<string>,
  onSelect: (
    parsedResult: ParsedGeocoderResult | null,
    geometry: ?any,
    description: ?string,
  ) => void,
  onChange: string => void,
  onBlur?: () => void,
  disabled?: boolean,
  autoFocus?: boolean,
  clearable?: boolean,
|};

const GENEVA_LAT_LNG = {
  lat: 46.2044,
  lng: 6.1432,
};

const renderHighlight = (value, matches) => {
  const chunks = [];
  let last = null;
  matches.forEach(({ offset, length }, index) => {
    if (last == null) {
      chunks.push(value.slice(0, offset));
    } else {
      chunks.push(value.slice(last.end, offset));
    }
    const end = offset + length;
    chunks.push(<strong key={index}>{value.slice(offset, end)}</strong>);
    last = { offset, end };
  });
  if (last && last.end !== value.length - 1) {
    chunks.push(value.slice(last.end));
  }
  return chunks;
};

const itemToString = node => node?.terms[0].value ?? node?.description ?? '';

export const AddressInput = (props: Props): React.Node => {
  const {
    Input = FilledInput,
    location = GENEVA_LAT_LNG,
    bounds,
    country,
    types = ['address'],
    clearable = false,
  } = props;
  const [items, setItems] = React.useState<
    $ReadOnlyArray<AutocompletePrediction>,
  >([]);
  const inputRef = React.useRef(null);
  const { text } = useTheme();

  const [autocomplete, autocompleting] =
    useGoogleAutocomplete(GOOGLE_MAPS_TOKEN);
  const [geocode, geocoding] = useGoogleGeocoder(GOOGLE_MAPS_TOKEN);
  const requesting = autocompleting || geocoding;

  const refetch = input => {
    if (input.length !== 0) {
      const options = {
        input,
        // prefer bounds over location
        location: location != null && bounds == null ? location : undefined,
        bounds,
        componentRestrictions: {
          country,
        },
        radius: 100000,
        types,
      };
      autocomplete(options, results => setItems(results));
    } else {
      setItems([]);
    }
  };

  const combobox = useCombobox({
    items,
    inputValue: props.value,
    itemToString,
    onInputValueChange: ({ inputValue }) => {
      refetch(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        geocode({ placeId: selectedItem.place_id }, results => {
          if (results.length !== 0) {
            const parsed = parseGeocoderResult(results[0]);
            // refetch data after select to prevent flash on focus
            if (parsed.route != null) {
              refetch(parsed.route);
            }
            props.onSelect(
              parsed,
              results[0].geometry,
              selectedItem.description,
            );
          } else {
            props.onSelect(null);
          }
        });
      } else {
        props.onSelect(null);
      }
    },
  });

  const { inputValue, isOpen, highlightedIndex, openMenu, closeMenu } =
    combobox;
  const { getComboboxProps } = combobox;
  const { getInputProps, getMenuProps, getItemProps } = combobox;
  return (
    <div {...getComboboxProps()}>
      <Input
        {...getInputProps({
          autoComplete: 'invalid',
          ref: inputRef,
          className: props.className,
          startAdornment: requesting && (
            <CircularProgress size={24} disableShrink={true} />
          ),
          endAdornment: clearable && props.value && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  props.onChange('');
                  closeMenu();
                }}
              >
                <Cancel />
              </IconButton>
            </InputAdornment>
          ),
          disabled: props.disabled,
          onChange: event => {
            props.onChange(event.target.value);
          },
          onFocus: () => {
            if (isOpen === false) {
              refetch(inputValue ?? '');
              openMenu();
            }
          },
          onBlur: props.onBlur,
          autoFocus: props.autoFocus,
        })}
      />
      <InputPopup referenceRef={inputRef} open={isOpen && items.length !== 0}>
        <List {...getMenuProps()}>
          {isOpen &&
            items.map((item, index) => (
              <ListItem
                {...getItemProps({ index, item })}
                key={index}
                button={true}
                selected={highlightedIndex === index}
              >
                <span css={text.truncate(1)}>
                  {renderHighlight(item.description, item.matched_substrings)}
                </span>
              </ListItem>
            ))}
        </List>
      </InputPopup>
    </div>
  );
};
