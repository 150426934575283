import { useMemo } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Alert, LinearProgress } from '@mui/material';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import type {
  TransactionFormQueryQuery,
  TransactionFormUpdateMutation,
  TransactionFormUpdateMutationVariables,
  GetUserDefaultTeamIdQuery,
} from '../../__generated__/graphql';
import {
  TRANSACTION_FORM_QUERY,
  TRANSACTION_FORM_UPDATE,
  GET_USER_DEFAULT_TEAM_ID,
} from './transactionQueries';

const transactionFormDefinition: FormDefinitionType = ({ t }) => [
  {
    name: 'terms',
    label: t('Terms'),
    type: 'category-title',
  },
  {
    name: 'purchase_price',
    label: t('Purchase price'),
    type: 'number',
  },
  {
    name: 'dates',
    label: t('Dates'),
    type: 'category-title',
  },
  {
    name: 'estimated_signature_date',
    label: t('Estimated signing date'),
    type: 'date',
  },
  {
    name: 'signature_date',
    label: t('Signing date'),
    type: 'date',
  },
  {
    name: 'payment_date',
    label: t('Payment date'),
    type: 'date',
  },
  {
    name: 'stakeholders',
    label: t('Stakeholders'),
    type: 'category-title',
  },
  {
    name: 'seller_broker_id',
    label: t('Seller agent'),
    type: 'user',
  },
  {
    name: 'seller_broker_team_id',
    label: t('Seller agent team'),
    type: 'team',
  },
  {
    name: 'buyer_broker_id',
    label: t('Buyer agent'),
    type: 'user',
  },
  {
    name: 'buyer_broker_team_id',
    label: t('Buyer agent team'),
    type: 'team',
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    gridProps: { xs: 12 },
    checkboxes: [
      {
        name: 'is_published',
        label: t('Publish'),
        type: 'checkbox',
      },
    ],
  },
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    disabled: () => true,
  },
];

export const TransactionForm = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const { data, loading, error } = useQuery<TransactionFormQueryQuery>(
    TRANSACTION_FORM_QUERY,
    {
      variables: { id: transactionId },
    },
  );

  const [getTeam] = useLazyQuery<GetUserDefaultTeamIdQuery>(
    GET_USER_DEFAULT_TEAM_ID,
  );

  const defaultValues = useMemo(
    () => data?.property_transactions_by_pk ?? undefined,
    [data?.property_transactions_by_pk],
  );

  const [updateTransaction] = useMutation<
    TransactionFormUpdateMutation,
    TransactionFormUpdateMutationVariables
  >(TRANSACTION_FORM_UPDATE);

  const onSubmit = (formData: any) =>
    updateTransaction({
      variables: {
        id: transactionId,
        transaction: {
          ...formData,
          id: undefined,
        },
      },
    });

  if (loading) {
    return (
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2000,
        }}
      />
    );
  }

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      <RaForm
        formDefinition={transactionFormDefinition}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onChange={(data, name, setValue) => {
          // set seller_broker_team_id if seller_broker_id is changed
          if (
            name === 'seller_broker_id' &&
            data?.seller_broker_team_id == null &&
            data?.seller_broker_id != null
          ) {
            getTeam({ variables: { id: data.seller_broker_id } }).then(
              response =>
                setValue(
                  'seller_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
          // set buyer_broker_team_id if buyer_broker_id is changed
          if (
            name === 'buyer_broker_id' &&
            data?.buyer_broker_team_id == null &&
            data?.buyer_broker_id != null
          ) {
            getTeam({ variables: { id: data.buyer_broker_id } }).then(
              response =>
                setValue(
                  'buyer_broker_team_id',
                  response?.data?.users_by_pk?.default_team_id,
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                  },
                ),
            );
          }
        }}
      />
    </>
  );
};
