import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Alert, Box, Chip, CircularProgress, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useLocale } from '../../../src/hooks/locale';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import type {
  Dictionaries,
  GetUserSettingsQuery,
} from '../../__generated__/graphql';
import { getUUID } from '../../../shared/global-id';
import { GET_USER_SETTINGS, UPDATE_USER_SETTINGS } from './userQueries';

const UserFormDefinition: FormDefinitionType<
  Record<string, any>,
  {
    services: Pick<Dictionaries, 'label' | 'name'>[];
    languages: Pick<Dictionaries, 'label' | 'name'>[];
  }
> = ({ t, context }) => [
  {
    name: 'user-details',
    label: t('User details'),
    type: 'category-title',
  },
  {
    name: 'first_name',
    label: t('First name'),
    type: 'text',
  },
  {
    name: 'last_name',
    label: t('Last name'),
    type: 'text',
  },
  {
    name: 'social_links',
    label: t('Social links'),
    type: 'category-title',
  },
  {
    name: 'facebook_profile',
    label: 'Facebook',
    type: 'text',
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'linked_in_profile',
    label: 'LinkedIn',
    type: 'text',
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'agent-settings',
    label: t('Agent settings'),
    type: 'category-title',
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'agent_slug',
    label: t('Slug (agency pages)'),
    type: 'text',
    gridProps: { xs: 12, md: 6 },
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'description',
    label: t('Description'),
    type: 'text',
    required: false,
    multiline: true,
    render: ({ is_broker }) => is_broker,
    gridProps: { xs: 12 },
  },
  {
    name: 'services',
    label: t('services'),
    type: 'select',
    multiple: true,
    options: () =>
      context?.services.map(s => ({
        label: s.label ?? s.name,
        value: s.name,
      })) ?? [],
    renderValue: (selected: string[]) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map(value => {
          const label = context?.services.find(s => s.name === value)?.label;
          return <Chip key={value} label={label} />;
        })}
      </Box>
    ),
    flex: 1,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'languages',
    label: t('languages'),
    type: 'select',
    multiple: true,
    options: () =>
      context?.languages.map(l => ({
        label: l.label ?? l.name,
        value: l.name,
      })) ?? [],
    renderValue: (selected: string[]) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map(value => {
          const label = context?.languages.find(l => l.name === value)?.label;
          return <Chip key={value} label={label} />;
        })}
      </Box>
    ),
    flex: 1,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    checkboxes: [
      {
        name: 'show_in_agency_pages',
        label: t('Show in agency pages'),
        type: 'checkbox',
      },
      {
        name: 'has_free_crm_access',
        label: t('Free CRM access'),
        type: 'checkbox',
      },
    ],
    required: false,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    disabled: () => true,
  },
];

export const UserSettings = () => {
  const { t } = useLocale();
  const { userId } = useParams() as { userId: string };
  const id = getUUID(userId);

  const { data, loading, error } = useQuery<GetUserSettingsQuery>(
    GET_USER_SETTINGS,
    {
      variables: { id },
    },
  );

  const user = data?.users_by_pk;
  const services = data?.services ?? [];
  const languages = data?.languages ?? [];

  const defaultValues = useMemo(() => user ?? undefined, [user]);

  const [updateUser] = useMutation(UPDATE_USER_SETTINGS);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <CircularProgress disableShrink />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!user) {
    return <Alert severity="error">{t('User not found')}</Alert>;
  }

  const onSubmit = (formData: any) =>
    updateUser({
      variables: {
        id,
        user: {
          ...formData,
          services: Object.values(formData.services ?? {}),
          languages: Object.values(formData.languages ?? {}),
        },
      },
    });

  return (
    <RaForm
      formDefinition={UserFormDefinition}
      defaultValues={defaultValues}
      context={{ services, languages }}
      onSubmit={onSubmit}
    />
  );
};
