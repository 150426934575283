import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { type ButtonBaseActions, ButtonBase, Typography } from '@mui/material';
import { useDropZone, useFileDialog } from '@realadvisor/hooks';
import { CloudUploadOutlined } from '@mui/icons-material';
import { useTheme } from '../src/hooks/theme';
import { useLocale } from '../src/hooks/locale';

type DropZoneProps = {
  onDrop: (files: File[]) => any;
  accept?: string;
  acceptLabel?: string;
};

const Portal = ({ children }: { children: JSX.Element }) => {
  if (document.body == null) {
    return null;
  } else {
    return ReactDOM.createPortal(children, document.body);
  }
};

export const FullScreenDropZone = (
  props: DropZoneProps & {
    disabled?: boolean;
  },
) => {
  const { t } = useLocale();
  const { colors } = useTheme();
  const [allAccepted, setAllaccepted] = React.useState(false);
  const targetRef = React.useRef(document.body);
  const dragOver = useDropZone({
    targetRef,
    accept: props.accept ?? 'image/*',
    onDragEnter: (_acceptedItems, rejectedItems) =>
      setAllaccepted(rejectedItems.length === 0),
    onDrop: props.disabled ? () => {} : props.onDrop,
  });

  const notAllowedMessage =
    props.accept == null
      ? t('Only images are allowed')
      : t('Only {{accept}} are allowed', {
          accept: props.acceptLabel ?? props.accept,
        });

  return (
    <Portal>
      <div
        css={{
          position: 'fixed',
          zIndex: 10000,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          pointerEvents: 'none',
          border: `8px solid ${allAccepted ? colors.success : colors.warning}`,
          backgroundColor: 'rgba(0, 0, 0, 0.54)',
          color: colors.white,
          opacity: dragOver && !props.disabled ? 1 : 0,
          transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        <CloudUploadOutlined sx={{ fontSize: 48 }} />
        <Typography variant="h6">
          {allAccepted ? t('Drop to upload') : notAllowedMessage}
        </Typography>
      </div>
    </Portal>
  );
};

export const DropZoneButton = ({ onDrop, accept = '*' }: DropZoneProps) => {
  const { t } = useLocale();
  const { colors } = useTheme();
  const targetRef = React.useRef<HTMLButtonElement | null>(null);
  const actionsRef = React.useRef<ButtonBaseActions | null>(null);
  const dragOver = useDropZone({
    targetRef,
    onDragEnter: () => {
      if (actionsRef.current) {
        actionsRef.current.focusVisible();
      }
    },
    onDragLeave: () => {
      if (targetRef.current) {
        targetRef.current.blur();
      }
    },
    onDrop,
  });

  const openFileDialog = useFileDialog({
    accept,
    onChange: onDrop,
  });

  return (
    <ButtonBase
      ref={targetRef}
      focusRipple={true}
      // run pulse ripple animation when drag is active
      action={actions => {
        actionsRef.current = actions;
      }}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 130,
        width: '100%',
        padding: 16,
        color: colors.primaryMain,
      }}
      onClick={openFileDialog}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: 4,
          flex: 1,
          border: '1px dashed currentColor',
        }}
      >
        <CloudUploadOutlined sx={{ m: '4px auto', fontSize: 48 }} />
        <Typography variant="h6" color={colors.disabledText}>
          {dragOver ? t('Drop to upload') : t('Drag or click to upload')}
        </Typography>
      </div>
    </ButtonBase>
  );
};
