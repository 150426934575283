import { Controller, type Control } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { PhoneInput } from '../phone-input';
import { useLocale } from '../../../src/hooks/locale';

export type RaPhoneNumberProps = {
  control: Control<any>;
  name: string;
  label: string;
  required?: boolean;
  errors?: any;
  disabled?: boolean;
};

export const RaPhoneNumber: React.FC<RaPhoneNumberProps> = ({
  control,
  name,
  label,
  required = false,
}) => {
  const { t } = useLocale();
  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <PhoneInput
            {...field}
            size="small"
            variant="outlined"
            InputProps={{
              sx: { background: 'white' },
            }}
            fullWidth
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
